import styled from 'styled-components';

export const Footer = styled.div`
	padding-top: 0.6rem;
	justify-content: flex-end;
	display: flex;
	gap: 0.6rem;
`;

export const StyledInputNumber = styled.div`
	div {
		width: 100%;
	}
`;
