import { Button, DatePickerProps } from 'antd';
import { forwardRef, useEffect, useState } from 'react';
import { FiCalendar } from 'react-icons/fi';
import { DatePickerContainer, DatePickerFull, DatePickerInput } from './styles';
import { normalizeDate } from 'helpers/normalizers';
import dayjs, { Dayjs } from 'dayjs';
import { getHolidays } from 'helpers/dates';

const DatePicker = forwardRef<
	any,
	DatePickerProps & { disableHolidays?: boolean; disablePastDays?: boolean }
>((props, ref) => {
	const [value, setValue] = useState('');
	const [isOpen, setIsOpen] = useState(false);

	const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		if (props.onChange) {
			const parsedDate = dayjs(value, 'DD/MM/YYYY');
			if (parsedDate.isValid()) {
				props.onChange(parsedDate, parsedDate.toISOString());
			} else {
				setValue('');
			}
		}
		props.onBlur && props.onBlur(e, {});
	};

	useEffect(() => {
		if (props.value?.isValid()) {
			setValue(props.value.format('DD/MM/YYYY'));
		} else {
			setValue('');
		}
	}, [props.value]);

	return (
		<DatePickerContainer>
			<DatePickerInput
				disabled={props.disabled}
				name={props.name}
				onBlur={handleBlur}
				placeholder={props.placeholder}
				value={value}
				onChange={e => setValue(normalizeDate(e.currentTarget.value))}
				suffix={
					<Button
						type="text"
						tabIndex={-1}
						style={{ padding: 0, opacity: 0.5 }}
						icon={<FiCalendar />}
						disabled={props.disabled}
						onClick={() => setIsOpen(true)}
					/>
				}
			/>
			<DatePickerFull
				open={isOpen}
				value={
					dayjs(value, 'DD/MM/YYYY').isValid()
						? dayjs(value, 'DD/MM/YYYY')
						: undefined
				}
				onOpenChange={open => setIsOpen(open)}
				disabledDate={current => {
					if (
						props.disablePastDays &&
						current.isBefore(dayjs(), 'day')
					)
						return true;
					if (!props.disableHolidays) return false;
					if ([0, 6].includes(current.day())) return true;
					const holidays = getHolidays(current.year());
					return holidays
						.map(holiday => holiday.d)
						.includes(current.format('DD/MM/YYYY'));
				}}
				onChange={(value, dateString) => {
					const parsedValue = value as Dayjs;

					if (value) {
						setValue(parsedValue.format('DD/MM/YYYY'));
						props.onChange &&
							props.onChange(parsedValue, dateString);
					}
				}}
				style={{ visibility: 'hidden' }}
				ref={ref}
			/>
		</DatePickerContainer>
	);
});

export default DatePicker;
