import { useQuery } from '@tanstack/react-query';
import { Col, Row, Spin } from 'antd';
import { Modal } from 'components/Modal';
import { TextM } from 'components/Text';
import { PostingsService } from 'modules/escrow/services';
import { useEffect, useState } from 'react';
import { PostingStatus } from 'types/Posting';

export interface AutoApprovePostingModalProps {
	isOpen: boolean;
	postingId?: string;
	onClose: () => void;
	onFinish?: (success?: boolean, message?: string) => void;
}

const AutoApprovePostingModal = ({
	isOpen,
	postingId,
	onFinish,
	onClose,
}: AutoApprovePostingModalProps) => {
	const [isFetchPostingActive, setIsFetchPostingActive] = useState(false);

	const handleClose = () => {
		onClose();
	};

	const { data } = useQuery({
		queryKey: ['postingDetail', postingId],
		enabled: isFetchPostingActive,
		refetchInterval: 2000,
		queryFn: () => PostingsService.getPostingDetails(postingId ?? ''),
	});

	const handleFinish = (success?: boolean, message?: string) => {
		onFinish?.(success, message);
		handleClose();
	};

	useEffect(() => {
		let timeout: NodeJS.Timeout;
		let interval: NodeJS.Timeout;

		if (isOpen && isFetchPostingActive) {
			const clearTimers = () => {
				clearInterval(interval);
				clearTimeout(timeout);
			};

			interval = setInterval(() => {
				if (data?.status === PostingStatus.SUCCESS) {
					clearTimers();
					handleFinish(true);
					return;
				} else if (data?.status === PostingStatus.ERROR) {
					clearTimers();
					handleFinish(false, data.error);
					return;
				}
			}, 2000);

			timeout = setTimeout(() => {
				setIsFetchPostingActive(false);
				clearTimers();
				handleFinish?.(undefined); // undefined means timeout
			}, 12000); // 12 seconds
		}

		if (!isOpen) {
			setIsFetchPostingActive(false);
		}

		return () => {
			clearInterval(interval);
			clearTimeout(timeout);
		};
	}, [
		isOpen,
		isFetchPostingActive,
		data,
		setIsFetchPostingActive,
		onFinish,
		onClose,
	]);

	useEffect(() => {
		if (isOpen) {
			setIsFetchPostingActive(true);
		}

		return () => {
			setIsFetchPostingActive(false);
		};
	}, [isOpen]);

	if (!postingId || !isOpen) {
		return null;
	}

	return (
		<>
			<Modal
				title="Verificando pagamento..."
				isOpen={isOpen}
				onClose={handleClose}
			>
				<Col>
					<Row>
						<Col span={2}>
							<Spin
								style={{
									marginTop: '1rem',
								}}
							/>
						</Col>
						<Col span={20}>
							<Row>
								<TextM>
									Realizando aprovação e verificação de
									pagamento...
								</TextM>
								<TextM>Por favor, aguarde</TextM>
							</Row>
						</Col>
					</Row>
				</Col>
			</Modal>
		</>
	);
};

export default AutoApprovePostingModal;
