import { Button, Col, Flex, Row, notification } from 'antd';
import { Account, AccountUpdateDto } from 'types/Account';
import { DescriptionsVertical } from 'components/DescriptionsVertical';
import { Documents } from 'components/Documents';
import { AccountDocumentsList } from 'constants/account';
import { AccountDestination } from 'components/AccountDestination';
import { useState } from 'react';
import { normalizeBankAccount, normalizeCpfCnpj } from 'helpers/normalizers';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ApiError } from 'types/ApiError';
import { AccountsService } from 'modules/escrow/services';
import { InformationModal } from 'components/InformationModal';
import { CheckmarkIcon } from 'components/Icons/Checkmark';
import { queryClient } from 'network/query';
import { AccessType } from 'types/Access';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import dayjs from 'dayjs';
import FeeSettingsModalComission from 'components/FeeSettingsModalComission/FeeSettingsModalCommission';
import { FeeDescriptions } from 'components/FeeDescriptions';
import { TransferRuleList } from 'modules/management/pages/TransferRulePage/TransferRuleList';
import { ManagementService } from 'modules/management/services/management';
import { Heading2 } from 'components/Heading2';
import { CreateTransferRuleModal } from 'modules/management/pages/TransferRulePage/components/CreateTransferRuleModal';

interface IEscrowDetailsConfigurationProps {
	account: Account;
}
const EscrowDetailsConfiguration: React.FC<
	IEscrowDetailsConfigurationProps
> = ({ account }) => {
	const { type } = useCurrentProfile();
	const [api, contextHolder] = notification.useNotification();
	const [isFeeSettingsModalOpen, setIsFeeSettingsModalOpen] = useState(false);
	const [isOpenTransferRuleModal, setIsOpenTransferRuleModal] =
		useState(false);
	const [isUpdateAccountInfoModalOpen, setIsUpdateAccountInfoModalOpen] =
		useState(false);

	const { mutate, isPending } = useMutation<void, ApiError, AccountUpdateDto>(
		{
			mutationFn: fees => {
				return AccountsService.updateAccount(account.id, fees);
			},
			onSuccess: () => {
				setIsFeeSettingsModalOpen(false);
				setIsUpdateAccountInfoModalOpen(true);
				queryClient.refetchQueries({
					queryKey: ['accountDetails', account.id],
				});
			},
			onError: e => {
				setIsFeeSettingsModalOpen(false);
				api.error({
					description: e.data.message,
					message: 'Ocorreu um problema.',
				});
			},
		},
	);

	const { data: transferRulesListData, isLoading: transferRulesListLoading } =
		useQuery({
			queryKey: ['transferRulesList'],
			enabled: !!account,
			queryFn: () =>
				ManagementService.getTransferRules({
					account_id: account?.id,
				}),
		});

	return (
		<>
			{contextHolder}
			<Row gutter={[16, 16]}>
				<Col span={24}>
					<FeeDescriptions
						title="Tarifas"
						fees={account.fees!}
						handleOnEdit={
							type === AccessType.FINANCIAL_INSTITUTION
								? () => setIsFeeSettingsModalOpen(true)
								: undefined
						}
					/>
				</Col>
			</Row>
			<Row gutter={[16, 16]} style={{ marginTop: '2rem' }}>
				<Col span={24}>
					<Documents
						title="Contratos"
						isDisabled={true}
						documentsObject={account.documents}
						documentsList={AccountDocumentsList}
					/>
				</Col>
			</Row>
			<Row
				gutter={[16, 16]}
				style={{
					marginTop: '2rem',
				}}
			>
				<Col span={24}>
					<DescriptionsVertical
						title="Informações"
						hideActions={true}
						columns={1}
						items={[
							{
								key: '1',
								label: 'Titular',
								children: account.person.name,
							},
							{
								key: '2',
								label: 'Instituição',
								children:
									'509 - Celcoin Instituição de Pagamentos S.A',
							},
							{
								key: '3',
								label: 'Documento',
								children: account.person.taxpayer_id
									? normalizeCpfCnpj(
											account.person.taxpayer_id,
										)
									: '-',
							},
							{
								key: '4',
								label: 'Agência',
								children: account.branch ?? '-',
							},
							{
								key: '5',
								label: 'Conta',
								children: account.account
									? normalizeBankAccount(account.account)
									: '-',
							},
							{
								key: '6',
								label: 'Data de criação',
								children: account.fees?.maintenance?.amount
									? `${dayjs(account.created_at).format(
											'DD/MM/YYYY',
										)} às ${dayjs(account.created_at).format('HH:mm:ss')}`
									: '-',
							},
							{
								key: '7',
								label: 'Data de ativação',
								children: account.activated_at
									? `${dayjs(account.activated_at).format(
											'DD/MM/YYYY',
										)} às ${dayjs(account.activated_at).format('HH:mm:ss')}`
									: '-',
							},
						]}
					/>
				</Col>
			</Row>
			<Row gutter={[16, 16]} style={{ marginTop: '2rem' }}>
				<Col span={24}>
					<AccountDestination
						accountId={account.id}
						destinations={account.destinations}
					/>
				</Col>
			</Row>
			<Flex
				justify="space-between"
				align="center"
				style={{ marginTop: '2.5rem' }}
			>
				<Heading2>Regras de transferências</Heading2>
				{type === AccessType.FINANCIAL_INSTITUTION && (
					<Button
						type="primary"
						onClick={() => setIsOpenTransferRuleModal(true)}
					>
						Adicionar
					</Button>
				)}
			</Flex>
			<TransferRuleList
				account={account}
				transferRules={transferRulesListData}
				onlyView={type !== AccessType.FINANCIAL_INSTITUTION}
			/>

			{/* Modals */}
			<CreateTransferRuleModal
				accountData={account}
				isOpen={isOpenTransferRuleModal}
				onClose={() => {
					setIsOpenTransferRuleModal(false);
				}}
			/>
			<FeeSettingsModalComission
				accountId={account.id}
				isOpen={isFeeSettingsModalOpen}
				onClose={() => setIsFeeSettingsModalOpen(false)}
				feesSettings={account.fees}
				loading={isPending}
				handleConfirm={values => {
					mutate({
						fees: values,
					});
				}}
			/>
			<InformationModal
				buttonText="Ok, obrigado"
				isOpen={isUpdateAccountInfoModalOpen}
				message="As tarifas foram atualizadas com sucesso!"
				title="Tarifas"
				icon={<CheckmarkIcon />}
				onClose={() => setIsUpdateAccountInfoModalOpen(false)}
			/>
		</>
	);
};

export default EscrowDetailsConfiguration;
