import { PageWrapper } from 'components/PageWrapper';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { SplashScreen } from 'components/SplashScreen';
import { AccountsService } from 'modules/escrow/services';
import { getManagementBreadcrumb } from 'modules/management/constants/routes';
import { useBreadcrumb } from 'modules/core/context/AppContext';
import { useEffect, useMemo, useState } from 'react';
import { EmptyFiltersResult } from 'components/EmptyFiltersResult';
import { CreateTransferRuleModal } from './components/CreateTransferRuleModal';
import { Tabs } from 'components/Tabs';
import TransferRuleList from './TransferRuleList/TransferRuleList';
import { TabElement } from 'components/Tabs/Tabs';
import { ManagementService } from 'modules/management/services/management';
import { PageHeaderWithButton } from 'components/PageHeader/PageHeader';

const TransferRulePage = () => {
	const { id } = useParams();
	const { setBreadcrumb } = useBreadcrumb();
	const [isModalOpen, setIsModalOpen] = useState(false);

	const { data, isLoading } = useQuery({
		queryKey: ['accountDetails', id],
		queryFn: () => AccountsService.getAccount(id!),
		refetchOnWindowFocus: false,
		enabled: typeof id === 'string',
	});

	const { data: transferRulesListData, isLoading: transferRulesListLoading } =
		useQuery({
			queryKey: ['transferRulesList'],
			enabled: !!data,
			queryFn: () =>
				ManagementService.getTransferRules({
					account_id: data?.id,
				}),
		});

	useEffect(() => {
		setBreadcrumb(
			getManagementBreadcrumb(
				'Regras de Transferência',
				data?.account,
				data?.id,
			),
		);
	}, [setBreadcrumb, data?.account]);

	const handleAddTransferRule = () => {
		setIsModalOpen(true);
	};

	const tabElements = useMemo<TabElement[]>(() => {
		const tabs = [
			{
				title: 'Regras de transferência',
				content: (
					<TransferRuleList
						account={data}
						transferRules={transferRulesListData}
					/>
				),
				path: 'transfer-rule',
			},
		];

		return tabs;
	}, [data, transferRulesListData]);

	if (isLoading) {
		return <SplashScreen />;
	}

	return (
		<>
			<PageHeaderWithButton
				isFluid
				hasDivider
				title="Regras de transferência"
				onClickButton={handleAddTransferRule}
				textButton={
					transferRulesListData &&
					transferRulesListData?.length > 0 &&
					!transferRulesListLoading
						? 'Criar regra'
						: undefined
				}
			/>
			<PageWrapper isFluid={true}>
				{transferRulesListData &&
				transferRulesListData?.length &&
				!transferRulesListLoading ? (
					<Tabs elements={tabElements} />
				) : (
					<EmptyFiltersResult
						description="Ops, esta conta ainda não possui nenhuma regra de transferência."
						buttonText="Adicionar regra de transferência"
						onClickButton={handleAddTransferRule}
					/>
				)}
			</PageWrapper>
			<CreateTransferRuleModal
				isOpen={isModalOpen}
				onClose={() => {
					setIsModalOpen(false);
				}}
				accountData={data}
			/>
		</>
	);
};

export default TransferRulePage;
