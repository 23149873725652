import React from 'react';
import theme from 'theme/theme';

export const ArrowRightIcon: React.FC<{
	color?: string;
	size?: string | number;
	isDisabled?: boolean;
}> = ({ color, size, isDisabled }) => (
	<svg
		width={size ? size : '24'}
		height={size ? size : '24'}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"
			fill={color ?? theme.primary}
			style={{
				opacity: isDisabled ? '0.5' : undefined,
			}}
		/>
	</svg>
);
