import { useEffect, useMemo, useState } from 'react';
import DebtorAddressForm from './DebtorAddressForm';
import DebtorAddressSuccess from './DebtorAddressSuccess';
import { notification } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { ApiError } from 'types/ApiError';
import { Modal } from 'components/Modal';
import { WalletCharge } from 'types/Wallet';
import { WalletsService } from 'modules/debitCollections/services';
import { queryClient } from 'network/query';
import { DebtorAddressType } from './types';
import { serializeOnlyNumbers } from 'helpers/serializers';

enum DebtorAddressSteps {
	FORM = 'FORM',
	SUCCESS = 'SUCCESS',
}

interface IDebtorAddressProps {
	isOpen: boolean;
	walletId: string;
	charge?: WalletCharge | WalletCharge[];
	onClose: () => void;
}
const DebtorAddress = ({
	isOpen,
	walletId,
	charge: editCharge,
	onClose,
}: IDebtorAddressProps) => {
	const [currentStep, setCurrentStep] = useState<DebtorAddressSteps>(
		DebtorAddressSteps.FORM,
	);
	const [api, contextHolder] = notification.useNotification();
	const isMultiples = Array.isArray(editCharge);

	const { mutate, isPending } = useMutation<
		{ id: string | string[] },
		ApiError,
		DebtorAddressType
	>({
		mutationFn: address => {
			if (isMultiples) {
				return Promise.all(
					editCharge!.map(charge => {
						return WalletsService.updateCharge(
							walletId,
							charge.id,
							{
								charge_debtor: {
									city: address.city,
									neighborhood: address.district,
									number: address.number,
									postal_code: serializeOnlyNumbers(
										address.postal_code,
									),
									public_area: address.street,
									state: address.state,
								},
							},
						);
					}),
				).then(() => ({ id: editCharge!.map(charge => charge.id) }));
			}
			return WalletsService.updateCharge(walletId, editCharge!.id, {
				charge_debtor: {
					city: address.city,
					neighborhood: address.district,
					number: address.number,
					postal_code: serializeOnlyNumbers(address.postal_code),
					public_area: address.street,
					state: address.state,
				},
			});
		},
		onSuccess: () => {
			setCurrentStep(DebtorAddressSteps.SUCCESS);
			queryClient.refetchQueries({
				queryKey: ['chargesList'],
			});
		},
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema ao alterar a cobrança.',
			});
		},
	});

	useEffect(() => {
		if (isOpen) {
			setCurrentStep(DebtorAddressSteps.FORM);
		}
	}, [isOpen]);

	const title = useMemo(() => {
		if (currentStep === DebtorAddressSteps.SUCCESS)
			return isMultiples
				? 'Endereço dos pagadores foi alterado!'
				: 'Endereço do pagador alterado!';
		return isMultiples ? 'Endereço dos pagadores' : 'Endereço do pagador';
	}, [currentStep, isMultiples]);

	return (
		<Modal title={title} width={480} isOpen={isOpen} onClose={onClose}>
			{contextHolder}
			{currentStep === DebtorAddressSteps.FORM &&
				isOpen &&
				editCharge && (
					<DebtorAddressForm
						isLoading={isPending}
						charge={editCharge}
						onClose={onClose}
						onSave={address => {
							mutate(address);
						}}
					/>
				)}
			{currentStep === DebtorAddressSteps.SUCCESS && isOpen && (
				<DebtorAddressSuccess
					isMultiples={isMultiples}
					onClose={onClose}
				/>
			)}
		</Modal>
	);
};

export default DebtorAddress;
