import { useCallback, useState } from 'react';
import { PersonsService } from '../services';
import { CompanyFund } from 'types/Company';
import { ApiError } from 'types/ApiError';

export const useCompanyFund = (options?: {
	onError?: (error: ApiError) => void;
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const getCompanyFund = useCallback(
		async (companyFundId: string): Promise<CompanyFund> => {
			try {
				setIsLoading(true);
				const companyFund =
					await PersonsService.getCompanyFund(companyFundId);
				return companyFund;
			} catch (error) {
				if (typeof options?.onError === 'function') {
					options.onError(error as ApiError);
				}
				return {
					administrator: null,
					manager: null,
					securitizer: null,
				} as CompanyFund;
			} finally {
				setIsLoading(false);
			}
		},
		[options],
	);

	return {
		getCompanyFund,
		loading: isLoading,
	};
};
