import React, { useEffect, useState } from 'react';
import {
	PasswordCheckIcon,
	PasswordRequirement,
	PasswordRequirements,
	PasswordStrengthContainer,
	PasswordStrengthLevel,
	PasswordStrengthLevels,
	PasswordStrengthLevelText,
} from './styles';
import { FaRegCircleCheck } from 'react-icons/fa6';

interface PasswordStrengthProps {
	password: string;
	onChangePasswordStrength: (strength: number) => void;
}

const PasswordStrength = ({
	password,
	onChangePasswordStrength,
}: PasswordStrengthProps) => {
	const [strength, setStrength] = useState<number>(-1);
	const [hasMinLength, setHasMinLength] = useState<boolean>(false);
	const [hasLowerUppercase, setHasLowerUppercase] = useState<boolean>(false);
	const [hasSymbols, setHasSymbols] = useState<boolean>(false);
	const [hasNumber, setHasNumber] = useState<boolean>(false);
	const [strengthText, setStrengthText] = useState<
		null | 'weak' | 'medium' | 'high' | 'strong'
	>('weak');

	useEffect(() => {
		if (strength >= 3) {
			setStrengthText('strong');
		} else if (strength >= 2) {
			setStrengthText('high');
		} else if (strength >= 1) {
			setStrengthText('medium');
		} else if (strength >= 0) {
			setStrengthText('weak');
		} else {
			setStrengthText(null);
		}
		onChangePasswordStrength(strength);
	}, [onChangePasswordStrength, strength]);

	useEffect(() => {
		if (password.length >= 8) {
			setHasMinLength(true);
			if (!hasMinLength) setStrength(state => state + 1);
		} else if (hasMinLength) {
			setHasMinLength(false);
			setStrength(state => state - 1);
		}

		if (/\d/.test(password)) {
			setHasNumber(true);
			if (!hasNumber) setStrength(state => state + 1);
		} else if (hasNumber) {
			setHasNumber(false);
			setStrength(state => state - 1);
		}

		if (/[A-Z]/.test(password) && /[a-z]/.test(password)) {
			setHasLowerUppercase(true);
			if (!hasLowerUppercase) setStrength(state => state + 1);
		} else if (hasLowerUppercase) {
			setHasLowerUppercase(false);
			setStrength(state => state - 1);
		}

		if (/[!@#$%^&*_-]/.test(password)) {
			setHasSymbols(true);
			if (!hasSymbols) setStrength(state => state + 1);
		} else if (hasSymbols) {
			setHasSymbols(false);
			setStrength(state => state - 1);
		}
	}, [hasLowerUppercase, hasMinLength, hasNumber, hasSymbols, password]);

	return (
		<>
			<PasswordStrengthContainer>
				<PasswordRequirements>
					<PasswordRequirement>
						<PasswordCheckIcon isChecked={hasMinLength}>
							<FaRegCircleCheck />
						</PasswordCheckIcon>
						<span>8 ou mais caracteres</span>
					</PasswordRequirement>
					<PasswordRequirement>
						<PasswordCheckIcon isChecked={hasLowerUppercase}>
							<FaRegCircleCheck />
						</PasswordCheckIcon>
						<span>Letras maiúsculas e minúsculas</span>
					</PasswordRequirement>
					<PasswordRequirement>
						<PasswordCheckIcon isChecked={hasSymbols}>
							<FaRegCircleCheck />
						</PasswordCheckIcon>
						<span>No mínimo 1 caractere especial</span>
					</PasswordRequirement>
					<PasswordRequirement>
						<PasswordCheckIcon isChecked={hasNumber}>
							<FaRegCircleCheck />
						</PasswordCheckIcon>
						<span>Pelo menos um número</span>
					</PasswordRequirement>
				</PasswordRequirements>
			</PasswordStrengthContainer>
			<PasswordStrengthContainer>
				<PasswordStrengthLevels>
					<PasswordStrengthLevel
						strength={strength >= 0 ? strengthText : null}
					/>
					<PasswordStrengthLevel
						strength={strength >= 1 ? strengthText : null}
					/>
					<PasswordStrengthLevel
						strength={strength >= 2 ? strengthText : null}
					/>
					<PasswordStrengthLevel
						strength={strength >= 3 ? strengthText : null}
					/>
				</PasswordStrengthLevels>
				<PasswordStrengthLevelText
					strength={strength >= 0 ? strengthText : null}
				>
					<span>Nível de segurança:</span>
					{!strengthText && <>-</>}
					{strengthText === 'weak' && <span>Fraco</span>}
					{strengthText === 'medium' && <span>Médio</span>}
					{strengthText === 'high' && <span>Alto</span>}
					{strengthText === 'strong' && <span>Forte</span>}
				</PasswordStrengthLevelText>
			</PasswordStrengthContainer>
		</>
	);
};

export default PasswordStrength;
