import { Modal } from 'components/Modal';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import TransferRuleConfig from './TransferRuleConfig';
import TransferRuleDestination from './TransferRuleDestination';
import { Account } from 'types/Account';
import { formatCurrency, normalizeBankAccount } from 'helpers/normalizers';

import { useMutation } from '@tanstack/react-query';
import { ApiError } from 'types/ApiError';
import { ManagementService } from 'modules/management/services/management';
import { notification } from 'antd';
import {
	DailyTransferFrequency,
	SelectType,
	TransferFrequency,
	TransferMethod,
	TransferRuleDto,
	AccountDestination,
	TransferRuleCreated,
	TransferRuleStatus,
} from 'types/Management';
import { queryClient } from 'network/query';

dayjs.extend(customParseFormat);

export interface CreateTransferRuleModalProps {
	accountData: Account | undefined;
	transferRule?: TransferRuleCreated | undefined;
	isOpen: boolean;
	onClose: () => void;
}

export interface CreateTransferRuleModalFormValues {
	id?: string;
	amount_type: SelectType;
	amount: string;
	transfer_method: TransferMethod;
	min_value_in_account_after_transfer: number;
	frequency: TransferFrequency;
	selected_weekdays: string[];
	daily_transfer_time?: string;
	weekly_transfer_time?: string;
	transfer_window_start?: string;
	transfer_window_end?: string;
	transfer_interval?: number;
	destinations: AccountDestination[];
	daily_transfer_frequency: DailyTransferFrequency;
}

const CreateTransferRuleModal = ({
	accountData,
	transferRule,
	isOpen,
	onClose,
}: CreateTransferRuleModalProps) => {
	const [api, contextHolder] = notification.useNotification();
	const [step, setStep] = useState(1);
	const [formValues, setFormValues] =
		useState<CreateTransferRuleModalFormValues>(
			{} as CreateTransferRuleModalFormValues,
		);

	const { mutate, isPending } = useMutation<
		void,
		ApiError,
		{
			transferRule: TransferRuleDto;
		}
	>({
		mutationFn: ({ transferRule }) => {
			return ManagementService.createAccountTransferRule(transferRule);
		},
		onSuccess: () => {
			setStep(1);
			setFormValues({} as CreateTransferRuleModalFormValues);
			onClose();
			api.success({
				message: 'Regra de transferência criada com sucesso',
			});
			queryClient.invalidateQueries({
				queryKey: ['transferRulesList'],
			});
		},
		onError: e => {
			api.error({
				description: e.data.message,
				message:
					'Ocorreu um erro ao tentar criar a regra de transferência',
			});
		},
	});

	const { mutate: updateTransferRuleMutate } = useMutation<
		void,
		ApiError,
		{
			transferRule: TransferRuleDto;
		}
	>({
		mutationFn: ({ transferRule }) => {
			return ManagementService.updateAccountTransferRule(
				{
					destinations: transferRule.destinations,
					status: TransferRuleStatus.ACTIVE,
				},
				transferRule.id ?? '',
			);
		},
		onSuccess: () => {
			setStep(1);
			setFormValues({} as CreateTransferRuleModalFormValues);
			onClose();
			api.success({
				message: 'Regra de transferência alterada com sucesso',
			});
		},
		onError: e => {
			api.error({
				description: e.data.message,
				message:
					'Ocorreu um erro ao tentar alterar a regra de transferência',
			});
		},
	});

	const handleNextStep = () => {
		setStep(step + 1);
	};

	const handlePreviousStep = () => {
		setStep(step - 1);
	};

	const updateForm = (values: CreateTransferRuleModalFormValues) => {
		setFormValues({
			...formValues,
			...values,
		});
	};

	const handleClose = () => {
		setStep(1);
		setFormValues({} as CreateTransferRuleModalFormValues);
		onClose();
	};

	const getFormattedTime = (time?: string) => {
		if (!time) return { hour: 0, minute: 0 };
		return {
			hour: parseInt(time.split(':')[0]),
			minute: parseInt(time.split(':')[1]),
		};
	};

	const handleCreateTransferRule = () => {
		let value: TransferRuleDto = {
			id: transferRule?.transfer_rule?.id,
			account_id: accountData?.id ?? '',
			amount_type: formValues.amount_type,
			amount: parseFloat(formValues.amount),
			transfer_method: formValues.transfer_method,
			frequency: formValues.frequency,
			min_value_in_account_after_transfer:
				formValues.min_value_in_account_after_transfer,
			days_of_week: formValues.selected_weekdays,
			destinations: formValues.destinations,
		};

		if (
			formValues.daily_transfer_frequency ===
			DailyTransferFrequency.MULTIPLE
		) {
			value = {
				...value,
				frequency: TransferFrequency.INTERVAL,
				transfer_window_start: getFormattedTime(
					formValues.transfer_window_start,
				),
				transfer_window_end: getFormattedTime(
					formValues.transfer_window_end,
				),
				transfer_interval: formValues.transfer_interval,
			};
		}

		if (
			formValues.daily_transfer_frequency === DailyTransferFrequency.ONCE
		) {
			value = {
				...value,
				fixed_transfer_time: getFormattedTime(
					formValues.daily_transfer_time,
				),
			};
		}

		if (formValues.frequency === TransferFrequency.WEEKLY) {
			value = {
				...value,
				fixed_transfer_time: getFormattedTime(
					formValues.weekly_transfer_time,
				),
			};
		}

		if (transferRule) {
			updateTransferRuleMutate({
				transferRule: value,
			});
			return;
		}

		mutate({
			transferRule: value,
		});
	};

	useEffect(() => {
		if (isOpen && transferRule) {
			setFormValues({
				amount_type: transferRule.transfer_rule?.amount_type,
				amount:
					formatCurrency(
						transferRule?.transfer_rule?.amount || 0,
						false,
					)?.toString() ?? '',
				transfer_method: transferRule?.transfer_rule?.transfer_method,
				min_value_in_account_after_transfer:
					transferRule?.transfer_rule
						?.min_value_in_account_after_transfer,
				frequency:
					transferRule?.transfer_rule?.frequency ===
					TransferFrequency.INTERVAL
						? TransferFrequency.DAILY
						: transferRule?.transfer_rule?.frequency,
				selected_weekdays: transferRule?.transfer_rule?.days_of_week,
				daily_transfer_time: transferRule?.transfer_rule
					?.fixed_transfer_time
					? dayjs()
							.hour(
								Number(
									`${transferRule?.transfer_rule?.fixed_transfer_time?.split(':')[0]}`,
								),
							)
							.minute(
								Number(
									`${transferRule?.transfer_rule?.fixed_transfer_time?.split(':')[1]}`,
								),
							)
							.format('HH:mm')
					: undefined,
				weekly_transfer_time: transferRule?.transfer_rule
					?.fixed_transfer_time
					? dayjs()
							.hour(
								Number(
									`${transferRule?.transfer_rule?.fixed_transfer_time.split(':')[0]}`,
								),
							)
							.minute(
								Number(
									`${transferRule?.transfer_rule?.fixed_transfer_time.split(':')[1]}`,
								),
							)
							.format('HH:mm')
					: undefined,
				transfer_window_start: transferRule?.transfer_rule
					?.transfer_window_start
					? dayjs()
							.hour(
								Number(
									`${transferRule?.transfer_rule?.transfer_window_start.split(':')[0]}`,
								),
							)
							.minute(
								Number(
									`${transferRule?.transfer_rule?.transfer_window_start.split(':')[1]}`,
								),
							)
							.format('HH:mm')
					: undefined,
				transfer_window_end: transferRule?.transfer_rule
					?.transfer_window_end
					? dayjs()
							.hour(
								Number(
									`${transferRule?.transfer_rule?.transfer_window_end.split(':')[0]}`,
								),
							)
							.minute(
								Number(
									`${transferRule?.transfer_rule?.transfer_window_end.split(':')[1]}`,
								),
							)
							.format('HH:mm')
					: undefined,
				transfer_interval:
					transferRule?.transfer_rule?.transfer_interval,
				destinations: transferRule.destinations,
				daily_transfer_frequency:
					transferRule?.transfer_rule?.frequency ===
					TransferFrequency.INTERVAL
						? DailyTransferFrequency.MULTIPLE
						: DailyTransferFrequency.ONCE,
			});
		}
	}, [isOpen, transferRule]);

	return (
		<>
			{contextHolder}
			<Modal
				isOpen={isOpen}
				onClose={handleClose}
				title={`Criar regra de transferência - Conta ${normalizeBankAccount(
					accountData?.account ?? '',
				)}`}
				width={800}
			>
				{step === 1 && (
					<TransferRuleConfig
						formValues={formValues!}
						onNext={handleNextStep}
						onPrevious={onClose}
						updateForm={updateForm}
						isEdit={!!transferRule}
					/>
				)}
				{step === 2 && (
					<TransferRuleDestination
						formValues={formValues!}
						updateForm={updateForm}
						onPrevious={handlePreviousStep}
						accountData={accountData}
						createTransferRule={handleCreateTransferRule}
						isLoading={isPending}
					/>
				)}
			</Modal>
		</>
	);
};

export default CreateTransferRuleModal;
