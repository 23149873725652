import React from 'react';

export const PersonIcon: React.FC<{
	color?: string;
	size?: string | number;
}> = ({ color, size }) => (
	<svg
		width={size ?? '24'}
		height={size ?? '24'}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M4 22C4 19.8783 4.84285 17.8434 6.34315 16.3431C7.84344 14.8429 9.87827 14 12 14C14.1217 14 16.1566 14.8429 17.6569 16.3431C19.1571 17.8434 20 19.8783 20 22H4ZM13 16.083V20H17.659C17.3015 18.9914 16.6812 18.0966 15.8621 17.408C15.0431 16.7193 14.055 16.2619 13 16.083ZM11 20V16.083C9.945 16.2619 8.95691 16.7193 8.13785 17.408C7.31879 18.0966 6.69847 18.9914 6.341 20H11ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11Z"
			fill={color ?? '#7664FA'}
		/>
	</svg>
);

export const PersonSecondaryIcon: React.FC<{
	color?: string;
	size?: string | number;
}> = ({ color, size }) => (
	<svg
		width={size ?? '24'}
		height={size ?? '24'}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M4 22C4 17.5817 7.58172 14 12 14C16.4183 14 20 17.5817 20 22H18C18 18.6863 15.3137 16 12 16C8.68629 16 6 18.6863 6 22H4ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11Z"
			fill={color ?? '#7664FA'}
		/>
	</svg>
);
