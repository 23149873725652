import { UserListAccess, UserListContent } from 'types/User';
import { Modal } from 'components/Modal';
import { Col, Row, TableColumnsType } from 'antd';
import { TextM, TextS } from 'components/Text';
import { normalizeCpfCnpj } from 'helpers/normalizers';
import { useTheme } from 'styled-components';
import { AccessType } from 'types/Access';
import { Table } from 'components/Table';
import { Key, useEffect, useState } from 'react';
import {
	CancelButton,
	UserBlockingButton,
	UserBlockingContainer,
} from './styles';

interface IUserBlockingFormProps {
	isOpen: boolean;
	user: UserListContent | false;
	onClose: () => void;
	onConfirm: (access: UserListAccess[]) => void;
}
const UserBlockingForm = ({
	isOpen,
	user,
	onClose,
	onConfirm,
}: IUserBlockingFormProps) => {
	const theme = useTheme();
	const [selectedAccess, setSelectAccess] = useState<UserListAccess[]>([]);

	useEffect(() => {
		if (isOpen) {
			setSelectAccess([]);
		}
	}, [isOpen]);

	const columns: TableColumnsType<UserListAccess> = [
		{
			title: 'Empresa',
			render: (access: UserListAccess) => {
				if (access.type === AccessType.PERSON && access.person) {
					return (
						<div>
							<TextM weight="bold">{`${access.person?.name}`}</TextM>
							<TextS weight="normal" color={theme.textSecondary}>
								{normalizeCpfCnpj(access.person.taxpayer_id)}
							</TextS>
						</div>
					);
				}
				return (
					<TextM weight="bold">{`${access.financial_institution.name}`}</TextM>
				);
			},
		},
	];

	return (
		<Modal
			title="Bloquear usuário"
			isOpen={isOpen}
			onClose={onClose}
			width={520}
			style={{
				padding: '24px',
			}}
		>
			{user !== false && (
				<UserBlockingContainer>
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<TextM weight="normal">
								{`Escolha as empresas para desativar o usuário ${user.full_name}`}
							</TextM>
						</Col>
					</Row>
					<Row gutter={[16, 16]} style={{ marginTop: '1rem' }}>
						<Col span={24}>
							<Table
								rowSelection={{
									type: 'checkbox',
									onChange: (
										selectedRowKeys: Key[],
										selectedRows: UserListAccess[],
									) => {
										setSelectAccess(selectedRows);
									},
									getCheckboxProps: (
										record: UserListAccess,
									) => ({
										name:
											record.person?.name ||
											record.financial_institution.name,
									}),
								}}
								rowKey={record => record.id}
								columns={columns}
								dataSource={user.access}
								pagination={false}
							/>
						</Col>
					</Row>
					<Row gutter={[16, 16]} style={{ marginTop: 'auto' }}>
						<Col span={24}>
							<UserBlockingButton
								type="primary"
								onClick={() => onConfirm(selectedAccess)}
								danger
								disabled={selectedAccess.length === 0}
							>
								<TextM>Bloquear acessos</TextM>
							</UserBlockingButton>
						</Col>
					</Row>
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<CancelButton type="default" onClick={onClose}>
								<TextM>Cancelar</TextM>
							</CancelButton>
						</Col>
					</Row>
				</UserBlockingContainer>
			)}
		</Modal>
	);
};

export default UserBlockingForm;
