import styled from 'styled-components';

export const Heading1 = styled.h1`
	color: ${p => p.theme.text};
	font-family: 'Urbanist', sans-serif;
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 32px;
`;
