import { useMemo } from 'react';
import { Col, Row } from 'antd';

// Components
import { Collapse, CollapseHeader } from 'components/Collapse';
import { TextM } from 'components/Text';
import { CompanyOutlinedIcon } from 'components/Icons/Company';
import { DescriptionsVertical } from 'components/DescriptionsVertical';
import { SignerIcon } from 'components/Icons/Signer';

// Hooks and services
import { useEscrowAccountsContext } from 'modules/escrow/context/EscrowAccounts.context';

// Types and helpers
import { normalizeCpfCnpj } from 'helpers/normalizers';
import { CELCOIN_CNPJ, CELCOIN_NAME } from 'constants/company';

// Styles
import { SignerCollapseHeader } from './Signers.styles';
import theme from 'theme/theme';

type SignerType = {
	companyName?: string;
	companyTaxpayerId: string;
	signerName: string;
	signerTaxpayerId: string;
};

interface IEscrowSignersProps {
	hideActions?: boolean;
}
const EscrowSigners = ({ hideActions }: IEscrowSignersProps) => {
	const { company, relatedParts } = useEscrowAccountsContext();

	const companySigner = useMemo(() => {
		const companySigner = company?.representatives?.find(representative => {
			if (representative.sign_contract) return true;
			if (
				representative.representative &&
				representative.representative.sign_contract
			)
				return true;
			return false;
		});
		if (companySigner?.sign_contract) {
			return {
				companyName: company?.name || '-',
				companyTaxpayerId: normalizeCpfCnpj(company?.taxpayer_id),
				signerName: companySigner?.full_name,
				signerTaxpayerId: companySigner?.taxpayer_id
					? normalizeCpfCnpj(companySigner.taxpayer_id)
					: '-',
			};
		}

		return {
			companyName: company?.name || '-',
			companyTaxpayerId: normalizeCpfCnpj(company?.taxpayer_id),
			signerName: company?.mother_name
				? company?.name || '-'
				: companySigner?.representative?.full_name || '-',
			signerTaxpayerId: company?.mother_name
				? normalizeCpfCnpj(company.taxpayer_id)
				: normalizeCpfCnpj(companySigner?.representative?.taxpayer_id),
		};
	}, [company]);

	const signers = useMemo(() => {
		return relatedParts
			.filter(part => part.sign_contract)
			.reduce<SignerType[] | undefined>(
				(allSigners, part) => {
					const representatives =
						part?.person?.representatives?.filter(
							representative =>
								representative?.sign_contract ||
								representative.representative?.sign_contract,
						);

					return representatives
						? allSigners?.concat(
								representatives?.map(representative => {
									if (representative?.sign_contract) {
										return {
											companyName:
												part.person?.name || '-',
											companyTaxpayerId: part.person
												?.taxpayer_id
												? normalizeCpfCnpj(
														part.person.taxpayer_id,
													)
												: '-',
											signerName:
												representative?.full_name ||
												'-',
											signerTaxpayerId:
												representative?.taxpayer_id
													? normalizeCpfCnpj(
															representative.taxpayer_id,
														)
													: '-',
										};
									}
									return {
										companyName: part.person?.name || '-',
										companyTaxpayerId: part.person
											?.taxpayer_id
											? normalizeCpfCnpj(
													part.person.taxpayer_id,
												)
											: '-',
										signerName:
											representative?.representative
												?.full_name || '-',
										signerTaxpayerId: representative
											?.representative?.taxpayer_id
											? normalizeCpfCnpj(
													representative
														.representative
														.taxpayer_id,
												)
											: '-',
									};
								}),
							)
						: undefined;
				},
				[companySigner],
			);
	}, [relatedParts, companySigner]);

	return (
		<Row gutter={[16, 16]}>
			<Col span={24}>
				<Collapse
					items={[
						{
							label: (
								<SignerCollapseHeader>
									<CollapseHeader>
										<SignerIcon />
										<TextM>{CELCOIN_NAME}</TextM>
									</CollapseHeader>
								</SignerCollapseHeader>
							),
							children: (
								<Row gutter={[16, 16]}>
									<Col span={12}>
										<DescriptionsVertical
											borderless={true}
											title="Dados da empresa"
											hideActions={hideActions}
											items={[
												{
													key: '1',
													label: 'Nome da empresa',
													children: CELCOIN_NAME,
												},
												{
													key: '2',
													label: 'CNPJ',
													children: CELCOIN_CNPJ,
												},
											]}
										/>
									</Col>
									<Col span={12}>
										<DescriptionsVertical
											borderless={true}
											title="Dados do assinante"
											hideActions={hideActions}
											items={[
												{
													key: '1',
													label: 'Nome do assinante',
													children: CELCOIN_NAME,
												},
												{
													key: '2',
													label: 'CNPJ',
													children: CELCOIN_CNPJ,
												},
											]}
										/>
									</Col>
								</Row>
							),
						},
					]}
				/>
			</Col>
			<Col span={24}>
				<Collapse
					items={signers?.map((signer, i) => {
						return {
							key: `${i + 1}`,
							label: (
								<SignerCollapseHeader>
									<CollapseHeader>
										<SignerIcon />
										<TextM>{signer.signerName}</TextM>
									</CollapseHeader>
									<CollapseHeader>
										<CompanyOutlinedIcon
											color={theme.primary}
										/>
										<TextM>{signer.companyName}</TextM>
									</CollapseHeader>
								</SignerCollapseHeader>
							),
							children: (
								<Row gutter={[16, 16]}>
									<Col span={12}>
										<DescriptionsVertical
											borderless
											title="Dados da empresa"
											hideActions={hideActions}
											items={[
												{
													key: '1',
													label: 'Nome da empresa',
													children:
														signer.companyName,
												},
												{
													key: '2',
													label: 'CPF / CNPJ',
													children:
														signer.companyTaxpayerId,
												},
											]}
										/>
									</Col>
									<Col span={12}>
										<DescriptionsVertical
											borderless
											title="Dados do assinante"
											hideActions={hideActions}
											items={[
												{
													key: '1',
													label: 'Nome do assinante',
													children:
														signer?.signerName ||
														signer.companyName,
												},
												{
													key: '2',
													label: 'CPF',
													children:
														signer?.signerTaxpayerId ||
														signer.companyTaxpayerId,
												},
											]}
										/>
									</Col>
								</Row>
							),
						};
					})}
				/>
			</Col>
		</Row>
	);
};

export default EscrowSigners;
