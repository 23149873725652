import styled, { css } from 'styled-components';
import { StyleConstants } from 'theme/StyleConstants';

export const HeaderContent = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	width: 100%;
`;

export const StyledProgressBar = styled.div`
	height: 17px;

	.ant-progress {
		.ant-progress-outer {
			.ant-progress-inner {
				height: 5px;

				div {
					background-color: ${({ theme }) => theme.primary};
					height: 5px !important;
				}
			}
		}
		border-radius: none !important;
	}
`;

export const Header = styled.header<{
	clean?: boolean;
	isFluid?: boolean;
	isSticky?: boolean;
}>`
	background-color: ${p => p.theme.white};

	${p =>
		p.isSticky &&
		css`
			position: sticky;
			top: 0;
			z-index: 100;
		`}

	${p =>
		p.clean &&
		css`
			${HeaderContent} {
				max-width: ${p.isFluid
					? `calc(calc(100vw - ${StyleConstants.SIDE_BAR_WIDTH}) - 2.5rem)`
					: '1260px'};

				padding: 0;
				height: unset;
				background-color: transparent;
			}
		`}
`;
