import { NetworkApi } from 'network';
import { List } from 'types/List';
import {
	WalletCharge,
	WalletChargeDto,
	WalletChargeFilters,
	WalletChargeUpdateDto,
	WalletContent,
	WalletDto,
	WalletFilters,
} from 'types/Wallet';
import {
	IReturnedCnab,
	ICnabFilters,
	ICnabExport,
} from '../pages/CnabList/types';
import {
	ICnabDownloadResponse,
	ICnabImportedFilters,
	IImportedCnab,
} from '../pages/ImportedCnabsList/types';
import { ChargeReview, ImportCNABFile } from '../pages/Charges/types';

export class WalletsService extends NetworkApi {
	public static createWallet(wallet: WalletDto) {
		return this.post<{ id: string }, WalletDto>(`/wallets`, wallet);
	}

	public static getWallets(params?: WalletFilters) {
		return this.get<List<WalletContent>, WalletFilters>('/wallets', params);
	}

	public static getWallet(walletId: string) {
		return this.get<WalletContent>(`/wallets/${walletId}`);
	}

	public static updateWallet(walletId: string, wallet: WalletDto) {
		return this.patch<void, WalletDto>(`/wallets/${walletId}`, wallet).then(
			() => ({ id: walletId }),
		);
	}

	public static archiveWallet(walletId: string) {
		return this.put<{ id: string }, {}>(`/wallets/${walletId}/archive`, {})
			.then(() => ({ id: walletId }))
			.catch(() => ({ id: walletId }));
	}

	public static getCharges(walletId: string, params?: WalletChargeFilters) {
		return this.get<List<WalletCharge>, WalletChargeFilters>(
			`/wallets/${walletId}/charges`,
			params,
		);
	}

	public static createCharge(walletId: string, charge: WalletChargeDto) {
		return this.post<{ id: string }, WalletChargeDto>(
			`/wallets/${walletId}/charges`,
			charge,
		);
	}

	public static updateCharge(
		walletId: string,
		chargeId: string,
		charge: Partial<WalletChargeUpdateDto>,
	) {
		return this.patch<void, Partial<WalletChargeUpdateDto>>(
			`/wallets/${walletId}/charges/${chargeId}`,
			charge,
		).then(() => ({ id: chargeId }));
	}

	public static getChargePdf(walletId: string, chargeId: string) {
		return this.get<{ charge_pdf_url: string }>(
			`/wallets/${walletId}/charges/${chargeId}/pdf`,
		);
	}

	public static cancelCharge(
		walletId: string,
		chargeId: string,
		reason: string,
	) {
		return this.delete(`/wallets/${walletId}/charges/${chargeId}`, {
			reason,
		});
	}

	public static getCnabReturnedList(params?: ICnabFilters) {
		return this.get<List<IReturnedCnab>, ICnabFilters>(
			`/wallets-cnab-exports`,
			params,
		);
	}

	public static getCnabReturnedDownloadUrl(
		cnabId: string,
		params: ICnabExport,
	) {
		return this.get<{ url: string }>(
			`/wallets-cnab-exports/${cnabId}`,
			params,
		);
	}

	public static getCnabCSVDownloadUrl(cnabId: string) {
		return this.get<ICnabDownloadResponse, void>(
			`/wallets-cnab-exports/${cnabId}`,
		);
	}

	public static getCnabImportedList(
		walletId: string,
		params?: ICnabImportedFilters,
	) {
		return this.get<List<IImportedCnab>, ICnabImportedFilters>(
			`/wallets/${walletId}/cnab-imports`,
			params,
		);
	}

	public static getCnabImportedDownloadUrl(walletId: string, cnabId: string) {
		return this.get<ICnabDownloadResponse, void>(
			`/wallets/${walletId}/cnab-imports/download/${cnabId}`,
		);
	}

	public static importReviewedChargesBatch(
		walletId: string,
		fileKey: string,
		charges: ChargeReview[],
	) {
		return this.post<void, { fileKey: string; charges: ChargeReview[] }>(
			`/wallets/${walletId}/charges/batch`,
			{ fileKey, charges },
		);
	}

	public static getCNABImportTemplateUrl(walletId: string) {
		return this.get<{ wallet_cnab_import_template: string }>(
			`/wallets/${walletId}/cnab-imports/template`,
		);
	}

	public static importCNABFileKey(walletId: string, fileKey: string) {
		return this.post<ImportCNABFile, { fileKey: string }>(
			`/wallets/${walletId}/cnab-imports/create`,
			{ fileKey },
		);
	}
}
