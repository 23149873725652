import { Divider } from 'antd';
import styled, { css } from 'styled-components';
import { DestinationCardInfoType } from './DestinationCardInfo';

export interface CardContainerProps {
	type?: DestinationCardInfoType;
}

interface ThumbnailIconProps extends CardContainerProps {}

export const CardContainer = styled.div<CardContainerProps>`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-top: 1rem;
	margin-bottom: 20px;
	padding: 14px 16px;
	border-radius: 16px;
	background-color: ${({ theme }) => theme.primaryOpacity};

	${({ type }) =>
		type === 'button' &&
		css`
			background-color: ${({ theme }) => theme.white};
			border: 1px solid ${({ theme }) => theme.border};
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
			cursor: pointer;
			&:hover {
				border: ${({ theme }) => `1px solid ${theme.primary}`};

				#arrow-right {
					animation: bounceRight 0.8s forwards;
				}

				#thumbnail-icon {
					animation: changeBackgroundColor 0.8s forwards;

					p {
						color: ${({ theme }) => theme.white};
						transition: color 0.8s;
					}
				}
			}

			@keyframes bounceRight {
				0% {
					transform: translateX(0);
				}
				50% {
					transform: translateX(17px);
				}
				100% {
					transform: translateX(15px);
				}
			}

			@keyframes changeBackgroundColor {
				0% {
					background-color: ${({ theme }) => theme.white};
				}
				100% {
					background-color: ${({ theme }) => theme.primary};
				}
			}
		`}
`;

export const ThumbnailIcon = styled.div<ThumbnailIconProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: ${({ theme }) => theme.primary};
	color: ${({ theme }) => theme.white};
	font-size: 16px;
	font-weight: 600;
	margin-right: 16px;

	p {
		color: ${({ theme }) => theme.white};
	}

	${({ type }) =>
		type === 'button' &&
		css`
			background-color: ${({ theme }) => theme.primaryOpacity};
			color: ${({ theme }) => theme.primary};

			p {
				color: ${({ theme }) => theme.primary};
			}
		`}
`;

export const CustomDivider = styled(Divider)`
	background-color: ${({ theme }) => theme.textGray};
	height: 70%;
	width: 2px;
	margin: 0 8px;
	opacity: 0.5;
`;
