import { Col, DescriptionsProps, Row } from 'antd';
import dayjs from 'dayjs';
import {
	normalizeCep,
	normalizeCpf,
	normalizeCpfCnpj,
} from 'helpers/normalizers';
import { naturalPersonDocumentsList } from 'constants/company';
import { DescriptionsVertical } from 'components/DescriptionsVertical';
import { StatesListDescription } from 'constants/states';
import { formatPhoneFromObject } from 'helpers/formaters';
import { Documents } from 'components/Documents';
import { TextM, TextS } from 'components/Text';
import { PersonIcon } from 'components/Icons/Person';
import { Representative } from 'types/Representative';
import {
	RepresentativeDocumentsList,
	RepresentativeRoleDescription,
} from 'constants/representatives';
import { Heading2 } from 'components/Heading2';
import { Collapse, CollapseHeader } from 'components/Collapse';
import { Account } from 'types/Account';
import theme from 'theme/theme';

interface IEscrowDetailsInformationProps {
	account: Account;
	hideActions?: boolean;
	viewPage?: boolean;
}
const EscrowPFDetailsInformation: React.FC<IEscrowDetailsInformationProps> = ({
	account: { person },
	hideActions,
	viewPage,
}) => {
	const createRepresentativeBasicData = (
		representative: Representative,
	): DescriptionsProps['items'] => {
		return [
			{
				key: '1',
				label: 'Nome',
				children: representative?.full_name || '*****',
			},
			{
				key: '2',
				label: 'CPF',
				children:
					normalizeCpfCnpj(representative.taxpayer_id) || '*****',
			},
			{
				key: '3',
				label: 'Data de nascimento',
				children:
					typeof representative.birth_date === 'string'
						? representative?.birth_date?.includes('*')
							? dayjs(
									representative?.birth_date,
									'YYYY-MM-DD',
								).format('DD/MM/YYYY')
							: '*****'
						: dayjs(
								representative?.birth_date,
								'YYYY-MM-DD',
							).format('DD/MM/YYYY'),
			},
			{
				key: '4',
				label: 'Nome da mãe',
				children: representative.mother_name || '*****',
			},
			{
				key: '5',
				label: 'E-mail',
				children: representative.email_address || '*****',
			},
			{
				key: '6',
				label: 'Telefone',
				children:
					typeof representative.phone === 'string'
						? representative.phone
						: formatPhoneFromObject(representative.phone),
			},
			{
				key: '5',
				label: 'Cargo',
				children:
					RepresentativeRoleDescription[representative.role] ||
					'*****',
			},
			{
				key: '6',
				label: 'Assina contrato',
				children: representative.sign_contract ? 'Sim' : 'Não',
			},
			{
				key: '7',
				label: 'Pessoa politicamente exposta',
				children: representative.pep ? 'Sim' : 'Não',
			},
		];
	};

	const createRepresentativeAddressData = (
		representative: Representative,
	): DescriptionsProps['items'] => {
		return [
			{
				key: '1',
				label: 'CEP',
				children:
					normalizeCep(representative.address.postal_code) || '*****',
			},
			{
				key: '2',
				label: 'Estado',
				children: representative.address.state_code
					? StatesListDescription[representative.address.state_code]
					: '*****',
			},
			{
				key: '3',
				label: 'Cidade',
				children: representative.address.city,
			},
			{
				key: '4',
				label: 'Bairro',
				children: representative.address.district,
			},
			{
				key: '4',
				label: 'Rua/Avenida',
				children: representative.address.street_name,
			},
			{
				key: '5',
				label: 'Número',
				children: representative.address.street_number,
			},
			{
				key: '6',
				label: 'Complemento',
				children: representative.address.extra_info,
			},
		];
	};

	const colapseItems = person.representatives.map((representative, i) => {
		return {
			key: `${i + 1}`,
			label: (
				<CollapseHeader>
					<PersonIcon />
					<TextM>{representative.full_name}</TextM>
				</CollapseHeader>
			),
			children: (
				<>
					<Row gutter={[16, 16]}>
						<Col span={12}>
							<DescriptionsVertical
								hideActions={hideActions}
								borderless={true}
								title="Dados básicos"
								items={createRepresentativeBasicData(
									representative,
								)}
							/>
						</Col>
						<Col span={12}>
							<DescriptionsVertical
								hideActions={hideActions}
								borderless={true}
								title="Endereço"
								items={createRepresentativeAddressData(
									representative,
								)}
							/>
						</Col>
					</Row>
					<Row
						gutter={[16, 16]}
						style={{
							marginTop: '8px',
						}}
					>
						<Col span={24}>
							<Documents
								viewPage={viewPage}
								borderless
								isDisabled
								documentsObject={representative.documents}
								documentsList={RepresentativeDocumentsList}
							/>
						</Col>
					</Row>
					{representative?.representative && (
						<Row
							gutter={[16, 16]}
							style={{
								marginTop: '1rem',
							}}
						>
							<Col span={24}>
								<Heading2
									style={{
										padding: '0 16px',
									}}
								>
									Procurador
								</Heading2>
								<Row gutter={[16, 16]}>
									<Col span={12}>
										<DescriptionsVertical
											borderless={true}
											title={null}
											items={createRepresentativeBasicData(
												representative.representative,
											)}
										/>
									</Col>
									<Col span={12}>
										<DescriptionsVertical
											borderless={true}
											title={null}
											items={createRepresentativeAddressData(
												representative.representative,
											)}
										/>
									</Col>
								</Row>
							</Col>
						</Row>
					)}
				</>
			),
		};
	});

	return (
		<>
			<Row gutter={[16, 16]}>
				<Col span={12}>
					<DescriptionsVertical
						title="Dados básicos"
						hideActions={hideActions}
						items={[
							{
								key: '1',
								label: 'Nome',
								children: person.name,
							},
							{
								key: '2',
								label: 'CPF',
								children: normalizeCpf(person.taxpayer_id),
							},
							{
								key: '4',
								label: 'Data de Nascimento',
								children: dayjs(
									person.birth_date,
									'YYYY-MM-DD',
								).format('DD/MM/YYYY'),
							},
							{
								key: '5',
								label: 'E-mail',
								children: person.email_address,
							},
							{
								key: '6',
								label: 'Telefone',
								children:
									typeof person.phone === 'string'
										? person.phone
										: formatPhoneFromObject(person.phone),
							},
						]}
					/>
				</Col>
				<Col span={12}>
					<DescriptionsVertical
						title="Endereço"
						hideActions={hideActions}
						items={[
							{
								key: '1',
								label: 'CEP',
								children: normalizeCep(
									person.address.postal_code,
								),
							},
							{
								key: '2',
								label: 'Estado',
								children: person?.address?.state_code
									? StatesListDescription[
											person?.address?.state_code
										]
									: '-',
							},
							{
								key: '3',
								label: 'Cidade',
								children: person.address.city,
							},
							{
								key: '4',
								label: 'Bairro',
								children: person.address.district,
							},
							{
								key: '4',
								label: 'Rua/Avenida',
								children: person.address.street_name,
							},
							{
								key: '5',
								label: 'Número',
								children: person.address.street_number,
							},
							{
								key: '6',
								label: 'Complemento',
								children: person.address.extra_info || '-',
							},
						]}
					/>
				</Col>
			</Row>
			<Row gutter={[16, 16]} style={{ marginTop: '2rem' }}>
				<Col span={24}>
					<Documents
						isDisabled={true}
						documentsObject={person.documents}
						documentsList={naturalPersonDocumentsList}
					/>
				</Col>
			</Row>
			<Row
				style={{
					marginTop: '2rem',
					border: `1px solid ${theme.border}`,
					padding: '16px 20px',
					borderRadius: '6px',
				}}
			>
				<Col span={24}>
					<Heading2>Procuradores</Heading2>
					<Row style={{ marginTop: '1rem' }}>
						<Col span={24}>
							{!!colapseItems.length ? (
								<Collapse items={colapseItems} />
							) : (
								<TextS color={theme.textGray}>
									Não há procuradores para esta conta
								</TextS>
							)}
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
};

export default EscrowPFDetailsInformation;
