import { Phone } from 'types/Phone';

export const serializePhoneObject = (phone?: string): Phone => {
	if (!phone) {
		return {} as Phone;
	}
	const phoneNumber = phone.replace(/[^\d]/g, '');
	return {
		country_code: '+55',
		area_code: phoneNumber.substring(0, 2),
		number: phoneNumber.substring(2),
	};
};

export const serializeOnlyNumbers = (value?: string): string => {
	if (!value) {
		return '';
	}

	const valueNumber = value.replace(/[^\d]/g, '');
	return valueNumber;
};
