import { Button, Dropdown, type TableProps } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { Table } from 'components/Table';
import { ESortingPage, PaginationInfo } from 'types/List';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { AccessType } from 'types/Access';
import { TextM, TextS } from 'components/Text';
import { useTheme } from 'styled-components';
import { normalizeCpf } from 'helpers/normalizers';
import { FiltersActions, FiltersContainer } from './styles';
import { FilterButton } from 'components/FilterButton';
import { LuSearch } from 'react-icons/lu';
import { AiOutlineStop } from 'react-icons/ai';
import { UsersListFiltersType } from './types';
import UsersListFilters from './UsersListFilters';
import { MenuProps } from 'antd/lib';
import { MdBlock } from 'react-icons/md';
import { UsersService } from 'modules/users/services';
import { UserListContent } from 'types/User';
import { BsEye } from 'react-icons/bs';
import { sortTableHandler } from 'helpers/tables';

interface IUsersListTableProps {
	companyId?: string;
	actions?: ReactNode;
	loading?: string;
	onView?: (user: UserListContent) => void;
	onBlock?: (user: UserListContent) => void;
}

const UsersListTable: React.FC<IUsersListTableProps> = ({
	companyId,
	actions,
	loading,
	onView,
	onBlock,
}) => {
	const theme = useTheme();
	const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);
	const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>({
		currentPage: 1,
		pageSize: 10,
	});
	const [sorting, setSorting] = useState<{
		order: ESortingPage;
		sort: string;
	}>({ order: ESortingPage.DESC, sort: 'created_at' });
	const [filters, setFilters] = useState<UsersListFiltersType>(
		{} as UsersListFiltersType,
	);

	const createActionItems = useCallback(
		(user: UserListContent): MenuProps['items'] => {
			const defaultItems: MenuProps['items'] = [];

			if (typeof onView === 'function') {
				defaultItems.push({
					label: 'Ver usuário',
					icon: <BsEye size={18} />,
					key: '1',
					onClick: () => onView(user),
				});
			}

			if (typeof onBlock === 'function' && user.access.length > 0)
				defaultItems.push({
					label: 'Bloquear usuário',
					key: '3',
					icon: <MdBlock size={18} />,
					onClick: () => onBlock(user),
				});

			return defaultItems;
		},
		[onView, onBlock],
	);

	const columns: ColumnsType<UserListContent> = useMemo(() => {
		const items: ColumnsType<UserListContent> = [
			{
				title: 'Nome',
				key: 'fullName',
				sorter: true,
				width: '15%',
				render: (user: UserListContent) => (
					<TextM weight="bold">{user.full_name}</TextM>
				),
			},
			{
				title: 'Empresa',
				width: '20%',
				render: (user: UserListContent) => {
					if (user.access.length > 0) {
						return user.access
							.map(access =>
								access.type === AccessType.FINANCIAL_INSTITUTION
									? access.financial_institution.name
									: access.person?.name,
							)
							.join(', ');
					}
					return '-';
				},
			},
			{
				title: 'CPF',
				width: '15%',
				render: (user: UserListContent) =>
					normalizeCpf(user.taxpayer_id),
			},
			{
				title: 'E-mail',
				width: '20%',
				render: (user: UserListContent) => user.email_address,
			},
			{
				title: 'Status',
				width: '10%',
				render: (user: UserListContent) => {
					if (user.access.length > 0) return 'Ativo';
					return 'Inativo';
				},
			},
			{
				key: 'action',
				width: '10%',
				align: 'center',
				render: (user: UserListContent) => (
					<Dropdown
						disabled={user.id === loading}
						menu={{ items: createActionItems(user) }}
						trigger={['click']}
					>
						<Button
							type="link"
							style={{
								color: theme.text,
								fontWeight: 'bold',
								fontSize: '1.3rem',
								lineHeight: '1rem',
								letterSpacing: '0.09rem',
							}}
							loading={loading === user.id}
						>
							...
						</Button>
					</Dropdown>
				),
			},
		];

		return items;
	}, [createActionItems, theme, loading]);

	const { data, isLoading } = useQuery({
		queryKey: ['userList', companyId, paginationInfo, sorting, filters],
		queryFn: () =>
			UsersService.getUsers({
				page: paginationInfo.currentPage,
				size: paginationInfo.pageSize,
				order: sorting.order,
				sort: sorting.sort,
				full_name: filters.full_name,
				taxpayer_id: filters.taxpayer_id,
				person_id: filters.person_id,
				email_address: filters.email_address,
			}),
	});

	const handleClearFilters = useCallback(() => {
		setFilters({} as UsersListFiltersType);
	}, []);

	return (
		<>
			<FiltersContainer>
				<FilterButton
					icon={<LuSearch size={18} />}
					onClick={() => setIsFiltersOpen(true)}
				>
					<TextS style={{ color: theme.white }}>Busca avançada</TextS>
				</FilterButton>
				<FilterButton
					icon={<AiOutlineStop size={18} color={theme.white} />}
					variation="secondary"
					onClick={handleClearFilters}
					disabled={Object.keys(filters).length === 0}
				>
					<TextS style={{ color: theme.white }}>Limpar filtros</TextS>
				</FilterButton>
				{actions && <FiltersActions>{actions}</FiltersActions>}
			</FiltersContainer>
			<Table
				columns={columns}
				dataSource={data?.content || []}
				loading={isLoading}
				onChange={(_, __, sorter) =>
					sortTableHandler(sorter, setSorting)
				}
				pagination={{
					total: data?.total_elements || 0,
					showTotal(total) {
						const currentSize =
							paginationInfo.currentPage *
							paginationInfo.pageSize;
						return `${currentSize > total ? total : currentSize} de ${total}`;
					},
					pageSizeOptions: ['10', '30', '60', '90'],
					showSizeChanger: true,
					pageSize: paginationInfo.pageSize,
					current: paginationInfo.currentPage,
					onChange(page, pageSize) {
						setPaginationInfo({
							currentPage: page,
							pageSize,
						});
					},
				}}
			/>
			<UsersListFilters
				isOpen={isFiltersOpen}
				onApply={filters => {
					setFilters(filters);
					setIsFiltersOpen(false);
				}}
				onClose={() => setIsFiltersOpen(false)}
				filters={filters}
			/>
		</>
	);
};

export default UsersListTable;
