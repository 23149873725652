import styled from 'styled-components';

type TStyledSvg = {
	disabled: boolean;
};

export const StyledSVG = styled.div<TStyledSvg>`
	display: flex;
	align-items: center;
	justify-content: center;
	svg {
		opacity: ${({ disabled }) => (disabled ? '0.5' : undefined)};
		color: ${({ disabled, theme }) =>
			disabled ? theme.primary : theme.white};
	}
`;
