import styled from 'styled-components';

export const StepContainer = styled.div`
	width: 100%;
	padding-bottom: 2rem;
`;

export const StepHeader = styled.div`
	padding-bottom: 1rem;
`;
