import { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Col, Divider, Row, notification } from 'antd';
import { PageWrapper } from 'components/PageWrapper';
import { PageHeader } from 'components/PageHeader';
import { Heading1 } from 'components/Heading1';
import { useBreadcrumb } from 'modules/core/context/AppContext';
import {
	ProfileContext,
	useCurrentProfile,
	useCurrentUser,
} from 'modules/core/context/ProfileContext';
import { AccessContent, AccessType } from 'types/Access';
import { DescriptionsVertical } from 'components/DescriptionsVertical';
import { normalizeCnpj, normalizeCpf } from 'helpers/normalizers';
import { Heading2 } from 'components/Heading2';
import { DescriptionsVerticalMainWrapper } from './styles';
import { KeyIcon } from 'components/Icons/Key';
import { UserSettingsMfa } from './UserSettingsMfa';
import { UserSettingsPassword } from './UserSettingsPassword';
import { InformationModal } from 'components/InformationModal';
import { CheckmarkIcon } from 'components/Icons/Checkmark';
import { Table } from 'components/Table';

interface IUserSettingsProps {}
const UserSettings: React.FC<IUserSettingsProps> = () => {
	const { setBreadcrumb } = useBreadcrumb();

	const [api, contextHolder] = notification.useNotification();
	const [isUserSettingsMfaOpen, setIsUserSettingsMfaOpen] = useState(false);
	const [isUserSettingsPasswordOpen, setIsUserSettingsPasswordOpen] =
		useState(false);
	const [
		isUserSettingsPasswordSuccessOpen,
		setIsUserSettingsPasswordSuccessOpen,
	] = useState(false);
	const { userAccess } = useContext(ProfileContext);
	const { type } = useCurrentProfile();
	const currentUser = useCurrentUser();

	const companyAccess = useMemo(
		() => userAccess.filter(access => access.type === AccessType.PERSON),
		[userAccess],
	);

	useEffect(() => {
		setBreadcrumb(undefined);
	}, [setBreadcrumb]);

	return (
		<>
			{contextHolder}
			<PageHeader>
				<Heading1>Meu perfil</Heading1>
			</PageHeader>
			<PageWrapper>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<DescriptionsVerticalMainWrapper>
							<DescriptionsVertical
								title="Meus dados"
								borderless={true}
								items={[
									{
										key: '1',
										label: 'Nome',
										children: currentUser.full_name,
									},
									{
										key: '2',
										label: 'CPF',
										children: normalizeCpf(
											currentUser.taxpayer_id,
										),
									},
									{
										key: '3',
										label: 'E-mail',
										children: currentUser.email_address,
									},
									{
										key: '4',
										label: 'Tipo de acesso',
										children:
											type === AccessType.PERSON
												? 'Empresa'
												: 'Instituição financeira',
									},
								]}
							/>
						</DescriptionsVerticalMainWrapper>
					</Col>
				</Row>
				<Divider />
				{companyAccess && companyAccess.length > 0 && (
					<Row
						style={{
							marginTop: '1rem',
						}}
					>
						<Col span={24}>
							<Heading2
								style={{ fontSize: '24px', paddingLeft: '8px' }}
							>
								Acessos
							</Heading2>
							<Row style={{ marginTop: '1rem' }}>
								<Col span={24}>
									<Table
										columns={[
											{
												title: 'Razão Social',
												key: 'legalName',
												render: (
													record: AccessContent,
												) =>
													record.person!
														.corporate_name,
											},
											{
												title: 'Nome Fantasia',
												key: 'name',
												render: (
													record: AccessContent,
												) => record.person!.name,
											},
											{
												title: 'CNPJ',
												key: 'taxpayer_id',
												render: (
													record: AccessContent,
												) =>
													normalizeCnpj(
														record.person!
															.taxpayer_id,
													),
											},
										]}
										dataSource={companyAccess}
										pagination={false}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
				)}
				<Divider style={{ marginTop: '2rem' }} />
				<Row
					style={{
						margin: '1.8rem 0',
					}}
					align="middle"
				>
					<Col span={12}>
						<Heading2
							style={{ fontSize: '24px', paddingLeft: '8px' }}
						>
							Autenticação de dois fatores
						</Heading2>
					</Col>
					<Col span={12}>
						<Button
							type="primary"
							size="large"
							style={{
								display: 'flex',
								alignItems: 'center',
								marginLeft: 'auto',
							}}
							onClick={() => setIsUserSettingsMfaOpen(true)}
						>
							Cadastrar autenticação novamente
							<div
								style={{
									marginLeft: '8px',
									marginBottom: '4px',
								}}
							>
								<KeyIcon />
							</div>
						</Button>
					</Col>
				</Row>
				<Divider />
				<Row
					style={{
						margin: '1.8rem 0',
					}}
					align="middle"
				>
					<Col span={12}>
						<Heading2
							style={{ fontSize: '24px', paddingLeft: '8px' }}
						>
							Senha
						</Heading2>
					</Col>
					<Col span={12}>
						<Button
							type="primary"
							size="large"
							style={{
								display: 'flex',
								alignItems: 'center',
								marginLeft: 'auto',
							}}
							onClick={() => setIsUserSettingsPasswordOpen(true)}
						>
							Alterar minha senha
							<div
								style={{
									marginLeft: '8px',
									marginBottom: '4px',
								}}
							>
								<KeyIcon />
							</div>
						</Button>
					</Col>
				</Row>
			</PageWrapper>
			<UserSettingsMfa
				isOpen={isUserSettingsMfaOpen}
				onClose={() => setIsUserSettingsMfaOpen(false)}
			/>
			<UserSettingsPassword
				isOpen={isUserSettingsPasswordOpen}
				onClose={() => setIsUserSettingsPasswordOpen(false)}
				onSuccess={() => {
					setIsUserSettingsPasswordOpen(false);
					setIsUserSettingsPasswordSuccessOpen(true);
				}}
			/>
			<InformationModal
				buttonText="Ok, obrigado"
				isOpen={isUserSettingsPasswordSuccessOpen}
				title="Senha alterada"
				icon={<CheckmarkIcon />}
				message="Sua senha foi alterada com sucesso!"
				onClose={() => setIsUserSettingsPasswordSuccessOpen(false)}
			/>
		</>
	);
};

export default UserSettings;
