import { useContext, useEffect, useState } from 'react';
import { Button, notification } from 'antd';
import { PageWrapper } from 'components/PageWrapper';
import { PageHeader } from 'components/PageHeader';
import { Heading1 } from 'components/Heading1';
import { useBreadcrumb } from 'modules/core/context/AppContext';
import { BreadcrumbContainer } from 'components/Breadcrumb';
import { UsersIcon } from 'modules/users/constants/routes';
import { UserCreation } from 'components/UserCreation';
import { PageActions } from 'components/PageActions';
import { AccessListTable } from 'components/AccessListTable';
import {
	ProfileContext,
	useCurrentProfile,
} from 'modules/core/context/ProfileContext';
import { AccessListContent, AccessPermission, AccessType } from 'types/Access';
import { Navigate, useNavigate } from 'react-router-dom';
import { LuPlusCircle } from 'react-icons/lu';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { TextL, TextM } from 'components/Text';
import { useAccess } from 'modules/users/hooks/useAccess';
import cognito from 'modules/core/services/CognitoService';
import { queryClient } from 'network/query';
import { InformationModal } from 'components/InformationModal';
import { CheckmarkIcon } from 'components/Icons/Checkmark';

const UsersList = () => {
	const navigate = useNavigate();
	const { setBreadcrumb } = useBreadcrumb();
	const { person: company, type, permissions } = useCurrentProfile();
	const { userProfile, userAccess } = useContext(ProfileContext);
	const [api, contextHolder] = notification.useNotification();

	const [isUserCreationSuccessOpen, setIsUserCreationSuccessOpen] =
		useState(false);
	const [isUserCreationOpen, setUserCreationOpen] = useState(false);
	const [userAccessToDelete, setUserAccessToDelete] = useState<
		AccessListContent | undefined
	>();

	const { deleteAccess, isLoading } = useAccess({
		onError: error => {
			api.error({
				description: error.data.message,
				message: 'Erro ao excluir acesso.',
			});
		},
		onSuccess: () => {
			api.success({
				message: 'Acesso excluído com sucesso.',
			});
			if (
				userAccessToDelete?.user.email_address ===
				userProfile?.email_address
			) {
				setUserAccessToDelete(undefined);
				if (userAccess && userAccess.length === 1) {
					cognito.signOut();
				}

				window.location.reload();
			}

			queryClient.refetchQueries({
				queryKey: ['userList'],
			});
		},
	});

	useEffect(() => {
		setBreadcrumb([
			{
				href: '/company',
				title: (
					<BreadcrumbContainer>
						<UsersIcon />
						<span>Acessos</span>
					</BreadcrumbContainer>
				),
			},
		]);
	}, [setBreadcrumb]);

	if (type === AccessType.FINANCIAL_INSTITUTION) {
		return <Navigate to="/users/admin" />;
	}
	if (!permissions.includes(AccessPermission.MANAGE_USER)) {
		return <Navigate to="/" />;
	}

	return (
		<>
			{contextHolder}
			<PageHeader>
				<Heading1>Acessos</Heading1>
				<PageActions>
					<Button
						type="primary"
						onClick={() => setUserCreationOpen(true)}
					>
						Criar Usuário
						<LuPlusCircle
							style={{ marginLeft: '0.6rem' }}
							size={18}
						/>
					</Button>
				</PageActions>
			</PageHeader>
			<PageWrapper>
				<AccessListTable
					onView={access => navigate(`/users/${access.user.id}`)}
					onDelete={access => setUserAccessToDelete(access)}
				/>
			</PageWrapper>
			<UserCreation
				isOpen={isUserCreationOpen}
				companyId={company?.id}
				onClose={() => {
					setUserCreationOpen(false);
				}}
				onSuccess={() => {
					setUserCreationOpen(false);
					setIsUserCreationSuccessOpen(true);
				}}
			/>
			<InformationModal
				buttonText="Ok, obrigado"
				isOpen={isUserCreationSuccessOpen}
				title="Acesso criado com sucesso!"
				icon={<CheckmarkIcon />}
				message="Acesso criado com sucesso!"
				onClose={() => setIsUserCreationSuccessOpen(false)}
			/>
			<ConfirmationModal
				isOpen={!!userAccessToDelete}
				danger={true}
				title="Remover usuário"
				confirmText="Remover usuário"
				cancelText="Cancelar"
				isLoading={isLoading}
				onConfirm={() => {
					deleteAccess(
						userAccessToDelete!.user.id,
						userAccessToDelete!.id,
					);
				}}
				onCancel={() => setUserAccessToDelete(undefined)}
				onClose={() => setUserAccessToDelete(undefined)}
			>
				<TextL>Você tem certeza que quer remover esse usuário?</TextL>
				<TextM weight="normal">
					Essa ação não poderá ser desfeita.
				</TextM>
			</ConfirmationModal>
		</>
	);
};

export default UsersList;
