import { Button, Col, Dropdown, Flex, Tooltip, notification } from 'antd';
import { LuEye } from 'react-icons/lu';
import type { MenuProps } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import EscrowAccountsFilters from './EscrowAccountsFilters';
import { LuSearch } from 'react-icons/lu';
import { TextL, TextM, TextS } from 'components/Text';
import { Link, useNavigate } from 'react-router-dom';
import { AccountContent, AccountFee, AccountStatus } from 'types/Account';
import { AccountsService } from 'modules/escrow/services';
import dayjs from 'dayjs';
import { AccountStatusDescription } from 'constants/account';
import { OpeningDocuments } from 'modules/escrow/components/OpeningDocuments';
import { useCallback, useEffect, useState } from 'react';
import { useAccountActions } from 'modules/escrow/hooks';
import { queryClient } from 'network/query';
import { Table } from 'components/Table';
import { DropdownIcon, EscrowBalance, FiltersContainer } from './styles';
import { FilterButton } from 'components/FilterButton';
import { AiOutlineStop } from 'react-icons/ai';
import { useTheme } from 'styled-components';
import { EscrowAccountsFiltersType } from './types';
import { PaperIcon } from 'components/Icons/Paper';
import { MdRefresh, MdBlock, MdOutlineCancel } from 'react-icons/md';
import { CgUnblock } from 'react-icons/cg';
import { GrDocumentUpload } from 'react-icons/gr';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import {
	formatCurrency,
	normalizeBankAccount,
	normalizeCpfCnpj,
} from 'helpers/normalizers';
import { ESortingPage, PaginationInfo } from 'types/List';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { InformationModal } from 'components/InformationModal';
import { CheckmarkIcon } from 'components/Icons/Checkmark';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { useAccount } from 'modules/escrow/hooks/useAccount';
import { ApiError } from 'types/ApiError';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import { AccessType } from 'types/Access';
import { IoWarningOutline } from 'react-icons/io5';
import { ETenantType, PersonType } from 'types/Company';
import { StarIcon } from 'components/Icons/Star';
import { useEscrowAccountsContext } from 'modules/escrow/context/EscrowAccounts.context';
import { FileWarningIcon } from 'components/Icons/FileWarning';
import { sortTableHandler } from 'helpers/tables';
import { contaLivre } from 'modules/escrow/constants/routes';
import {
	getAccountRouteType,
	useIsEscrowAccountRoute,
} from 'modules/escrow/utils/adminRoute';
import FeeSettingsModalComission from 'components/FeeSettingsModalComission/FeeSettingsModalCommission';

type AccountActivationProps = {
	fees: AccountFee;
};

const EscrowAccounts = () => {
	const theme = useTheme();
	const { type } = useCurrentProfile();
	const navigate = useNavigate();
	const [api, contextHolder] = notification.useNotification();
	const { setIsEditingAccount, resetEscrowContext } =
		useEscrowAccountsContext();

	const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>({
		currentPage: 1,
		pageSize: 10,
	});
	const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false);
	const [visibleBalances, setVisibleBalances] = useState<{
		[key: string]: number;
	}>({});
	const [sorting, setSorting] = useState<{
		order: ESortingPage;
		sort: string;
	}>({ order: ESortingPage.DESC, sort: 'created_at' });
	const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);
	const [filters, setFilters] = useState<EscrowAccountsFiltersType>(
		{} as EscrowAccountsFiltersType,
	);
	const [isOpeningDocumentsOpen, setIsOpeningDocuments] = useState<
		string | undefined
	>(undefined);
	const [isCancelAccountModalOpen, setIsCancelAccountModalOpen] = useState<
		false | AccountContent
	>(false);
	const [isBlockAccountModalOpen, setIsBlockAccountModalOpen] = useState<
		false | AccountContent
	>(false);
	const [isUnblockAccountModalOpen, setIsUnblockAccountModalOpen] = useState<
		false | AccountContent
	>(false);
	const [isCancelAccountInfoModalOpen, setIsCancelAccountInfoModalOpen] =
		useState(false);
	const [isCancelAccountErrorModalOpen, setIsCancelAccountErrorModalOpen] =
		useState(false);
	const [isBlockAccountInfoModalOpen, setIsBlockAccountInfoModalOpen] =
		useState(false);
	const [isUnblockAccountInfoModalOpen, setIsUnblockAccountInfoModalOpen] =
		useState(false);
	const [isFeeSettingsModalOpen, setIsFeeSettingsModalOpen] = useState(false);
	const [agreementKey, setAgreementKey] = useState<string>('');
	const [
		accountCreationConfirmationModalOpen,
		setAccountCreationConfirmationModalOpen,
	] = useState(false);

	const handleClearFilters = useCallback(() => {
		setFilters({} as EscrowAccountsFiltersType);
	}, []);

	const { loading, processingAccountId, block, cancel, retry, unblock } =
		useAccountActions({
			onError: e => {
				api.error({
					description: e.data.message,
					message: 'Ocorreu um problema.',
				});
			},
			onBlockSuccess: () => {
				queryClient.refetchQueries({
					queryKey: ['accountsList', paginationInfo],
				});
				setIsBlockAccountInfoModalOpen(true);
			},
			onUnblockSuccess: () => {
				queryClient.refetchQueries({
					queryKey: ['accountsList', paginationInfo],
				});
				setIsUnblockAccountInfoModalOpen(true);
			},
			onCancelSuccess: () => {
				queryClient.refetchQueries({
					queryKey: ['accountsList', paginationInfo],
				});
				setIsCancelAccountModalOpen(false);
				setIsCancelAccountInfoModalOpen(true);
			},
			onCancelBalanceError: () => {
				setIsCancelAccountModalOpen(false);
				setIsCancelAccountErrorModalOpen(true);
			},
			onRetrySuccess: account => {
				queryClient.refetchQueries({
					queryKey: ['accountsList', paginationInfo],
				});
				api.success({
					description: `A conta ${
						(account as AccountContent).person.name
					} foi recriada.`,
					message: 'Conta recriada.',
				});
			},
		});

	const { mutate, isPending } = useMutation<
		void,
		ApiError,
		AccountActivationProps
	>({
		mutationFn: ({ fees }) => {
			return AccountsService.updateOpeningDocuments({
				accountId: isOpeningDocumentsOpen || '',
				agreementKey: agreementKey,
				fees,
			});
		},
		onSuccess: () => {
			queryClient.refetchQueries({
				queryKey: ['accountsList', paginationInfo],
			});
			setIsOpeningDocuments(undefined);
			setIsFeeSettingsModalOpen(false);
			setAccountCreationConfirmationModalOpen(true);
		},
		onError: e => {
			api.error({
				description: e.data.message,
				message:
					'Ocorreu um erro ao enviar o contrato de abertura de conta.',
			});
		},
	});

	const { isBalanceLoading, getAccountBalance } = useAccount({
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema.',
			});
		},
		onBalanceSuccess: (account, amount) => {
			setVisibleBalances(prev => ({
				...prev,
				[account.id]: amount,
			}));
		},
	});

	const handleConfirmAccountCreation = useCallback(
		(values: AccountFee) => {
			mutate({
				fees: values,
			});
			setIsFeeSettingsModalOpen(false);
		},
		[agreementKey, mutate],
	);

	const accountType = getAccountRouteType();
	const { data, isLoading } = useQuery({
		queryKey: ['accountsList', paginationInfo, filters, sorting],
		queryFn: () =>
			AccountsService.getAccounts({
				account_type: accountType,
				page: paginationInfo.currentPage,
				size: paginationInfo.pageSize,
				order: sorting.order,
				sort: sorting.sort,
				status: filters.status,
				search: filters.search,
				taxpayer_id: filters.taxpayer_id,
				account: filters.account,
				creator_name: filters.creator_name,
			}),
	});

	const createActionItems = (item: AccountContent): MenuProps['items'] => {
		const defaultItems: MenuProps['items'] = [];
		const accountRoute = !useIsEscrowAccountRoute() ? contaLivre : 'escrow';

		if (
			[AccountStatus.BLOCKED, AccountStatus.ACTIVE].includes(item.status)
		) {
			defaultItems.push({
				label: (
					<Link to={`/${accountRoute}/${item.id}/statement`}>
						Acessar Extrato
					</Link>
				),
				key: '0',
				icon: <LuEye size={18} />,
			});
		}

		defaultItems.push({
			label: 'Detalhes da Conta',
			onClick: () => {
				navigate(
					`/${accountRoute}/${item.id}/${
						item.person.person_type === PersonType.LEGAL
							? 'details'
							: 'natural-person/details'
					}/info`,
				);
			},
			icon: (
				<DropdownIcon>
					<PaperIcon color={theme.textSecondary} />
				</DropdownIcon>
			),
			key: '1',
		});

		if (
			item.status === AccountStatus.WAITING_UPDATES &&
			type === AccessType.PERSON
		) {
			defaultItems.push({
				label: 'Documentação pendente',
				onClick: () => {
					navigate(`/${accountRoute}/edit/${item.id}`);
				},
				icon: (
					<DropdownIcon>
						<FileWarningIcon color={theme.textSecondary} />
					</DropdownIcon>
				),
				key: '7',
			});
		}

		if (type === AccessType.FINANCIAL_INSTITUTION) {
			if (item.status === AccountStatus.PENDING_ACTIVATION) {
				defaultItems.push({
					label: 'Enviar contrato de abertura de conta',
					key: '2',
					icon: <GrDocumentUpload size={16} />,
					onClick: () => {
						setIsOpeningDocuments(item.id);
					},
				});
			}

			if (item.status === AccountStatus.ACTIVE) {
				defaultItems.push(
					{
						label: 'Bloquear conta',
						key: '3',
						icon: <MdBlock size={18} />,
						onClick: () => setIsBlockAccountModalOpen(item),
					},
					{
						label: 'Encerrar conta',
						key: '5',
						icon: <MdOutlineCancel size={18} />,
						onClick: () => setIsCancelAccountModalOpen(item),
					},
				);
			}

			if (item.status === AccountStatus.BLOCKED) {
				defaultItems.push({
					label: 'Desbloquear conta',
					icon: <CgUnblock size={18} />,
					key: '4',
					onClick: () => setIsUnblockAccountModalOpen(item),
				});
			}

			if (item.status === AccountStatus.ERROR_CREATING) {
				defaultItems.push({
					label: 'Recriar conta',
					key: '6',
					icon: <MdRefresh size={18} />,
					onClick: () => retry(item),
				});
			}
		}

		setIsEditingAccount(false);
		return defaultItems;
	};

	const columns: ColumnsType<AccountContent> = [
		{
			title: 'Titular',
			sorter: true,
			key: 'company.name',
			render: (account: AccountContent) => (
				<div>
					<TextM weight="bold">{`${account.person.name}`}</TextM>
					<TextS weight="normal" color={theme.textSecondary}>
						{normalizeCpfCnpj(account.person.taxpayer_id)}
					</TextS>
				</div>
			),
			width: '20%',
		},
		{
			title: 'Pessoa',
			sorter: true,
			key: 'company.person_type',
			render: (account: AccountContent) => (
				<div>
					<TextM weight="normal">{`${account.person.person_type === PersonType.LEGAL ? 'PJ' : 'PF'}`}</TextM>
				</div>
			),
			width: '7%',
		},
		{
			title: 'Data de abertura',
			width: '10%',
			sorter: true,
			key: 'created_at',
			render: (account: AccountContent) => (
				<div>
					<TextM weight="bold">{`${dayjs(account.created_at).format(
						'DD/MM/YYYY',
					)}`}</TextM>
					<TextS color={theme.textSecondary} weight="normal">
						{`às ${dayjs(account.created_at).format('HH:mm:ss')}`}
					</TextS>
				</div>
			),
		},

		{
			title: 'Data de ativação',
			sorter: true,
			key: 'activated_at',
			render: (account: AccountContent) => (
				<div>
					{account.activated_at ? (
						<>
							<TextM weight="bold">{`${dayjs(
								account.activated_at,
							).format('DD/MM/YYYY')}`}</TextM>
							<TextS color={theme.textSecondary} weight="normal">
								{`às ${dayjs(account.activated_at).format('HH:mm:ss')}`}
							</TextS>
						</>
					) : (
						'-'
					)}
				</div>
			),
		},
		{
			title: 'Criador da Conta',
			width: '15%',
			sorter: true,
			key: 'creator.fullName',
			render: (account: AccountContent) => (
				<div>
					<TextM weight="normal">{`${account.creator.full_name}`}</TextM>
				</div>
			),
		},
		{
			title: 'Nº da conta',
			width: '18%',
			sorter: true,
			key: 'account',
			render: (account: AccountContent) => (
				<Flex align="center">
					<div style={{ marginRight: '5px', width: '1.3rem' }}>
						{account.tenant_type === ETenantType.PRIME && (
							<StarIcon size={20} />
						)}
					</div>
					<TextM weight="normal">
						{account.account
							? normalizeBankAccount(account.account)
							: '-'}
					</TextM>
				</Flex>
			),
		},
		{
			title: 'Status',
			width: '14%',
			render: (account: AccountContent) => {
				if (
					typeof account.error === 'string' &&
					[
						AccountStatus.ERROR_CREATING,
						AccountStatus.ERROR_BLOCKING,
						AccountStatus.ERROR_CANCELING,
						AccountStatus.ERROR_UNBLOCKING,
					].includes(account.status)
				)
					return (
						<Tooltip title={account.error}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									gap: '8px',
								}}
							>
								<TextM weight="normal">
									{AccountStatusDescription[account.status]}
								</TextM>
								<IoMdInformationCircleOutline size={16} />
							</div>
						</Tooltip>
					);
				return (
					<TextM weight="normal">
						{AccountStatusDescription[account.status]}
					</TextM>
				);
			},
		},
		{
			title: 'Saldo',
			width: '14%',
			render: (account: AccountContent) => {
				if (
					[AccountStatus.BLOCKED, AccountStatus.ACTIVE].includes(
						account.status,
					)
				) {
					const isVisible =
						typeof visibleBalances[account.id] === 'number';
					return (
						<EscrowBalance isVisible={isVisible}>
							<TextM weight="normal">
								{isVisible
									? formatCurrency(
											visibleBalances[account.id],
										)
									: '*******'}
							</TextM>
							<Button
								type="link"
								size="large"
								icon={isVisible ? <BsEyeSlash /> : <BsEye />}
								loading={isBalanceLoading === account.id}
								onClick={() => {
									if (!isVisible) {
										getAccountBalance(account);
										return;
									}
									setVisibleBalances(prev => {
										delete prev[account.id];
										return { ...prev };
									});
								}}
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							/>
						</EscrowBalance>
					);
				}
				return '-';
			},
		},
		{
			key: 'action',
			width: '5%',
			align: 'center',
			render: (account: AccountContent) => (
				<Dropdown
					disabled={loading && account.id === processingAccountId}
					menu={{ items: createActionItems(account) }}
					trigger={['click']}
				>
					<Button
						type="link"
						style={{
							color: theme.text,
							fontWeight: 'bold',
							fontSize: '1.3rem',
							lineHeight: '1rem',
							letterSpacing: '0.09rem',
						}}
						loading={loading && account.id === processingAccountId}
					>
						...
					</Button>
				</Dropdown>
			),
		},
	];

	useEffect(() => {
		resetEscrowContext();
	}, []);

	return (
		<>
			{contextHolder}
			<FiltersContainer>
				<FilterButton
					icon={<LuSearch size={18} />}
					onClick={() => setIsFiltersOpen(true)}
				>
					<TextS color={theme.white}>Busca avançada</TextS>
				</FilterButton>
				<FilterButton
					icon={<AiOutlineStop size={18} color={theme.white} />}
					variation="secondary"
					onClick={handleClearFilters}
					disabled={!Object.keys(filters).length}
				>
					<TextS style={{ color: theme.white }}>Limpar filtros</TextS>
				</FilterButton>
			</FiltersContainer>
			<Table
				scroll={{ x: 1000 }}
				columns={columns}
				rowKey={record => record.id}
				dataSource={data?.content}
				loading={isLoading}
				onChange={(_, __, sorter) =>
					sortTableHandler(sorter, setSorting)
				}
				pagination={{
					total: data?.total_elements || 0,
					showTotal(total) {
						const currentSize =
							paginationInfo.currentPage *
							paginationInfo.pageSize;
						return `${currentSize > total ? total : currentSize} de ${total}`;
					},
					pageSizeOptions: ['10', '30', '60', '90'],
					showSizeChanger: true,
					pageSize: paginationInfo.pageSize,
					current: paginationInfo.currentPage,
					onChange(page, pageSize) {
						setPaginationInfo({
							currentPage: page,
							pageSize,
						});
					},
				}}
			/>
			<OpeningDocuments
				isOpen={!!isOpeningDocumentsOpen}
				isPending={isPending}
				onClose={() => {
					setIsOpeningDocuments(undefined);
					setAgreementKey('');
					queryClient.refetchQueries({
						queryKey: ['accountsList', paginationInfo],
					});
				}}
				handleConfirm={(uploadKey: string) => {
					setAgreementKey(uploadKey);
					setIsFeeSettingsModalOpen(true);
					queryClient.refetchQueries({
						queryKey: ['accountsList', paginationInfo],
					});
				}}
			/>
			<EscrowAccountsFilters
				isOpen={isFiltersOpen}
				onApply={filters => {
					setFilters(filters);
					setIsFiltersOpen(false);
				}}
				onClose={() => setIsFiltersOpen(false)}
				filters={filters}
			/>
			<ConfirmationModal
				isOpen={isCancelAccountModalOpen !== false}
				danger={true}
				title="Encerrar conta"
				confirmText="Encerrar conta"
				cancelText="Cancelar"
				isLoading={
					isCancelAccountModalOpen !== false &&
					processingAccountId === isCancelAccountModalOpen.id
				}
				onConfirm={() => {
					cancel(isCancelAccountModalOpen as AccountContent);
				}}
				onCancel={() => setIsCancelAccountModalOpen(false)}
				onClose={() => setIsCancelAccountModalOpen(false)}
			>
				<TextL>Você tem certeza que deseja encerrar a conta?</TextL>
				<TextM weight="normal">
					Essa ação não poderá ser desfeita.
				</TextM>
			</ConfirmationModal>
			<InformationModal
				buttonText="Ok, obrigado"
				isOpen={isCancelAccountInfoModalOpen}
				message="A conta foi encerrada com sucesso!"
				title="Conta encerrada"
				icon={<CheckmarkIcon />}
				onClose={() => setIsCancelAccountInfoModalOpen(false)}
			/>
			<InformationModal
				buttonText="Ok, obrigado"
				isOpen={isCancelAccountErrorModalOpen}
				message="A conta não pode ser encerrada pois possui saldo."
				title="Encerrar conta"
				icon={<IoWarningOutline size={24} color={theme.attention} />}
				onClose={() => setIsCancelAccountErrorModalOpen(false)}
			/>
			<ConfirmationModal
				isOpen={isBlockAccountModalOpen !== false}
				danger={true}
				title="Bloquear conta"
				confirmText="Bloquear conta"
				cancelText="Cancelar"
				isLoading={loading}
				onConfirm={() => {
					block(isBlockAccountModalOpen as AccountContent);
					setIsBlockAccountModalOpen(false);
				}}
				onCancel={() => setIsBlockAccountModalOpen(false)}
				onClose={() => setIsBlockAccountModalOpen(false)}
			>
				<TextL>Você tem certeza que deseja bloquear a conta?</TextL>
			</ConfirmationModal>
			<InformationModal
				buttonText="Ok, obrigado"
				isOpen={isBlockAccountInfoModalOpen}
				message="A conta foi bloqueada com sucesso!"
				title="Conta bloqueada"
				icon={<CheckmarkIcon />}
				onClose={() => setIsBlockAccountInfoModalOpen(false)}
			/>
			<ConfirmationModal
				isOpen={isUnblockAccountModalOpen !== false}
				title="Desbloquear conta"
				confirmText="Desbloquear conta"
				cancelText="Cancelar"
				onConfirm={() => {
					unblock(isUnblockAccountModalOpen as AccountContent);
					setIsUnblockAccountModalOpen(false);
				}}
				isLoading={loading}
				onCancel={() => setIsUnblockAccountModalOpen(false)}
				onClose={() => setIsUnblockAccountModalOpen(false)}
			>
				<TextL>Você tem certeza que deseja desbloquear a conta?</TextL>
			</ConfirmationModal>
			<InformationModal
				buttonText="Ok, obrigado"
				isOpen={isUnblockAccountInfoModalOpen}
				message="A conta foi desbloqueada com sucesso!"
				title="Conta desbloqueada"
				icon={<CheckmarkIcon />}
				onClose={() => setIsUnblockAccountInfoModalOpen(false)}
			/>
			<FeeSettingsModalComission
				accountId={isOpeningDocumentsOpen}
				isOpen={isFeeSettingsModalOpen}
				onClose={() => setIsFeeSettingsModalOpen(false)}
				handleConfirm={values => {
					handleConfirmAccountCreation(values);
				}}
			/>
			<InformationModal
				buttonText="Ok, obrigado"
				isOpen={accountCreationConfirmationModalOpen}
				title="Conta aberta com sucesso!"
				icon={<CheckmarkIcon />}
				onClose={() => {
					setAccountCreationConfirmationModalOpen(false);
					setAgreementKey('');
				}}
			>
				<Col
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '0.3rem',
						marginLeft: '1rem',
					}}
				>
					<TextL weight="bold">Conta aberta com sucesso!</TextL>
					<TextL weight="normal">
						A conta está configurada e aberta e já pode realizar
						movimentações.
					</TextL>
				</Col>
			</InformationModal>

			<InformationModal
				buttonText="Ok, obrigado"
				isOpen={isOpenSuccessModal}
				message="A conta foi enviada para revisão de documentação."
				title="Pendência sinalizada com sucesso!"
				icon={<CheckmarkIcon />}
				onClose={() => setIsOpenSuccessModal(false)}
			/>
		</>
	);
};

export default EscrowAccounts;
