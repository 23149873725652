import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from 'network/query';
import { useTheme } from 'styled-components';

import { notification } from 'antd';
import { BreadcrumbContainer } from 'components/Breadcrumb';
import { CheckmarkIcon } from 'components/Icons/Checkmark';
import { DebitCollectionsIcon } from 'components/Icons/DebitCollections';
import ChargesSteps from 'modules/debitCollections/pages/ChargesNew/ChargesSteps/ChargesSteps';
import { Heading1 } from 'components/Heading1';
import { InformationModal } from 'components/InformationModal';
import { PageActions } from 'components/PageActions';
import { PageHeader } from 'components/PageHeader';
import { PageWrapper } from 'components/PageWrapper';
import { SplashScreen } from 'components/SplashScreen';
import { TextS } from 'components/Text';
import ChargesNewInfo from './ChargesNewInfo/ChargesNewInfo';
import ChargesNewPayer from './ChargesNewPayer/ChargesNewPayer';
import ChargesNewPreview from './ChargesPreview/ChargesNewPreview';
import { PageInfo, Wrapper } from './styles';

import { useBreadcrumb } from 'modules/core/context/AppContext';
import { useWallets } from 'modules/debitCollections/hooks/useWallets';
import { WalletsService } from 'modules/debitCollections/services';
import { normalizeBankAccount } from 'helpers/normalizers';
import { ApiError } from 'types/ApiError';
import { EChargesSteps, InfoType, PayerType } from './types';
import {
	ChargeReceiver,
	WalletContent,
	WalletDiscountModality,
} from 'types/Wallet';
import {
	serializeOnlyNumbers,
	serializePhoneObject,
} from 'helpers/serializers';
import ChargesBeneficiaries from './ChargesBeneficiaries/ChargesBeneficiaries';
import { AccountsService } from 'modules/escrow/services';
import { Steps } from 'modules/company/components/CompanyCreation/styles';

interface StepsStatus {
	payer: {
		completed: boolean;
		content: PayerType;
	};
	info: {
		completed: boolean;
		content: InfoType;
	};
	charge_receivers: {
		completed: boolean;
		content: ChargeReceiver[];
	};
}

enum ESteps {
	CHARGES_NEW_PAYER = 'CHARGES_NEW_PAYER',
	CHARGES_NEW_INFO = 'CHARGES_NEW_INFO',
	CHARGES_RECEIVERS = 'CHARGES_RECEIVERS',
	PREVIEW = 'PREVIEW',
}

const StepsId = {
	PAYER: 'payer',
	INFO: 'info',
	BENEFICIARIES: 'charge_receivers',
};

const ChargesNewPage = () => {
	const { id } = useParams();
	const theme = useTheme();
	const navigate = useNavigate();
	const { setBreadcrumb } = useBreadcrumb();
	const [api, contextHolder] = notification.useNotification();

	const [isSuccessOpen, setIsSuccessOpen] = useState<boolean>(false);
	const [wallet, setWallet] = useState<WalletContent>({} as WalletContent);
	const [currentStep, setCurrentStep] = useState<ESteps>(
		ESteps.CHARGES_NEW_PAYER,
	);
	const [stepsStatus, setStepsStatus] = useState<StepsStatus>({
		payer: {
			completed: false,
			content: {} as PayerType,
		},
		info: {
			completed: false,
			content: {} as InfoType,
		},
		charge_receivers: {
			completed: false,
			content: [] as ChargeReceiver[],
		},
	});

	useEffect(() => {
		setBreadcrumb([
			{
				href: '/company',
				title: (
					<BreadcrumbContainer>
						<DebitCollectionsIcon />
						<span>Cobranças</span>
					</BreadcrumbContainer>
				),
			},
			{
				href: `/debit-collections/wallet/${wallet?.id}/barcodes`,
				title: <span>{wallet?.name ?? ''}</span>,
			},
			{
				title: 'Emitir boleto',
			},
		]);

		setStepsStatus({
			...stepsStatus,
			info: {
				completed: false,
				content: {
					amount: undefined,
					discount_date: undefined,
					discount_modality: wallet.discount_modality,
					discount_value: wallet.discount_value,
					due_date_limit: undefined,
					due_date: undefined,
					fine: wallet.fine,
					interest: wallet.interest,
				},
			},
		});
	}, [setBreadcrumb, wallet]);

	const { getWallet, isWalletLoading } = useWallets({
		onError: () => {
			navigate(`/debit-collections/wallet/${id}/barcodes`);
		},
		onWalletSuccess: w => {
			setWallet(w);
		},
	});

	useEffect(() => {
		if (typeof id === 'string') {
			getWallet(id);
		}
	}, [id]);

	const { data: accountDetail, isLoading } = useQuery({
		queryKey: ['accountDetails', wallet.account?.id],
		queryFn: () => AccountsService.getAccount(wallet.account?.id!),
		refetchOnWindowFocus: false,
		enabled: typeof wallet.account?.id === 'string',
	});

	const { mutate: createCharge, isPending } = useMutation<
		{ id: string },
		ApiError,
		{
			payer: PayerType;
			info: InfoType;
			charge_receiver: ChargeReceiver[];
		}
	>({
		mutationFn: async ({ payer, info }) => {
			return WalletsService.createCharge(id!, {
				amount: info?.amount || 0,
				due_date: info?.due_date?.format('YYYY-MM-DD') || '',
				discount_limit_date:
					info?.discount_date?.format('YYYY-MM-DD') || '',
				days_to_expire_after_payment:
					info?.due_date_limit
						?.startOf('day')
						?.diff(info?.due_date?.startOf('day'), 'day') || 0,
				fine: info.fine,
				discount_modality:
					info.discount_modality === WalletDiscountModality.NONE
						? WalletDiscountModality.FIXED
						: info.discount_modality,
				discount_value: info.discount_value,
				interest: info.interest,
				charge_debtor: {
					city: payer.city,
					document: serializeOnlyNumbers(payer.taxpayer_id),
					email: payer.email_address,
					name: payer.name,
					neighborhood: payer.district,
					number: payer.number,
					phone: serializePhoneObject(payer.phone),
					postal_code: serializeOnlyNumbers(payer.postal_code),
					public_area: payer.street,
					state: payer.state,
				},
				charge_receivers: stepsStatus.charge_receivers.content || [],
			});
		},
		onSuccess: () => {
			queryClient.refetchQueries({
				queryKey: ['chargesList'],
			});
			setIsSuccessOpen(true);
		},
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema ao criar a cobrança.',
			});
		},
	});

	const newChargeSteps = {
		[ESteps.CHARGES_NEW_PAYER]: (
			<ChargesNewPayer
				payer={stepsStatus.payer.content}
				onNext={payer => {
					setStepsStatus({
						...stepsStatus,
						payer: {
							completed: true,
							content: payer,
						},
					});
					setCurrentStep(ESteps.CHARGES_NEW_INFO);
				}}
			/>
		),
		[ESteps.CHARGES_NEW_INFO]: (
			<ChargesNewInfo
				info={stepsStatus.info.content}
				onBack={info => {
					setStepsStatus({
						...stepsStatus,
						info: {
							...stepsStatus.info,
							content: info,
							completed: false,
						},
					});
					setCurrentStep(ESteps.CHARGES_NEW_PAYER);
				}}
				onNext={info => {
					setStepsStatus({
						...stepsStatus,
						info: {
							completed: true,
							content: info,
						},
					});

					if (stepsStatus.payer.completed) {
						setCurrentStep(ESteps.CHARGES_RECEIVERS);
					} else {
						api.warning({
							description:
								'Por favor, preencha os dados do pagador.',
							message: 'Pagador não preenchido',
						});
						setCurrentStep(ESteps.CHARGES_NEW_PAYER);
					}
				}}
			/>
		),
		[ESteps.CHARGES_RECEIVERS]: (
			<ChargesBeneficiaries
				info={stepsStatus.charge_receivers.content}
				data={accountDetail}
				loading={isLoading}
				updateChargeReceiver={(values: ChargeReceiver[]) => {
					setStepsStatus({
						...stepsStatus,
						charge_receivers: {
							...stepsStatus.charge_receivers,
							content: values,
						},
					});
				}}
				onBack={info => {
					setStepsStatus({
						...stepsStatus,
						charge_receivers: {
							...stepsStatus.charge_receivers,
							content: info,
							completed: false,
						},
					});
					setCurrentStep(ESteps.CHARGES_NEW_INFO);
				}}
				onNext={info => {
					setStepsStatus({
						...stepsStatus,
						charge_receivers: {
							completed: true,
							content: info,
						},
					});

					if (stepsStatus.info.completed) {
						setCurrentStep(ESteps.PREVIEW);
					} else {
						api.warning({
							description:
								'Por favor, preencha os dados do pagador.',
							message: 'Pagador não preenchido',
						});
						setCurrentStep(ESteps.CHARGES_NEW_PAYER);
					}
				}}
			/>
		),
		[ESteps.PREVIEW]: (
			<ChargesNewPreview
				info={stepsStatus.info.content}
				payer={stepsStatus.payer.content}
				isCreating={isPending}
				wallet={wallet}
				walletAccount={accountDetail}
				onBack={() => {
					setCurrentStep(ESteps.CHARGES_RECEIVERS);
				}}
				onNext={() => {
					createCharge({
						payer: stepsStatus.payer.content,
						info: stepsStatus.info.content,
						charge_receiver: stepsStatus.charge_receivers.content,
					});
				}}
				chargeReceivers={stepsStatus.charge_receivers.content}
			/>
		),
	};

	if (isWalletLoading) {
		return <SplashScreen />;
	}

	return (
		<>
			{contextHolder}
			<PageHeader isFluid={true} isSticky={true}>
				<Heading1>Emitir boleto</Heading1>
				<PageActions>
					<PageInfo>
						<TextS style={{ color: theme.text }}>
							Conta beneficiária:
						</TextS>
						<TextS style={{ color: theme.textGray }}>{`Ag.: ${
							wallet.account?.branch
						} | Cc.: ${normalizeBankAccount(
							wallet.account?.account ?? '',
						)}`}</TextS>
					</PageInfo>
					<Steps>{`Etapa ${EChargesSteps[currentStep]}/4`}</Steps>
				</PageActions>
			</PageHeader>
			<Wrapper>
				<ChargesSteps
					selectedStep={EChargesSteps[currentStep] as number}
					steps={[
						{
							action: () => {
								setCurrentStep(ESteps.CHARGES_NEW_PAYER);
							},
							title: 'Pagador',
							completed:
								stepsStatus[StepsId.PAYER as keyof StepsStatus]
									.completed,
						},
						{
							action: () => {
								setCurrentStep(ESteps.CHARGES_NEW_INFO);
							},
							title: 'Dados da Cobrança',
							completed:
								stepsStatus[StepsId.INFO as keyof StepsStatus]
									.completed,
						},
						{
							action: () => {
								setCurrentStep(ESteps.CHARGES_RECEIVERS);
							},
							title: 'Beneficiários',
							completed:
								stepsStatus[
									StepsId.BENEFICIARIES as keyof StepsStatus
								].completed,
						},
						{
							action: () => {
								if (
									stepsStatus.payer.completed &&
									stepsStatus.info.completed &&
									stepsStatus.charge_receivers.completed
								) {
									setCurrentStep(ESteps.PREVIEW);
									return;
								}

								api.warning({
									description:
										'Por favor, preencha todos as etapas para prosseguir.',
									message: 'Etapa não preenchida',
								});
							},
							title: 'Resumo',
							completed: false,
						},
					]}
				/>
				<PageWrapper>
					{newChargeSteps[currentStep]}

					<InformationModal
						buttonText="Ok, obrigado"
						isOpen={isSuccessOpen}
						title="Boleto registrado com sucesso!"
						icon={<CheckmarkIcon />}
						message="O seu boleto foi registrado com sucesso!"
						onClose={() => {
							navigate(
								`/debit-collections/wallet/${id}/barcodes`,
							);
							setIsSuccessOpen(false);
						}}
					/>
				</PageWrapper>
			</Wrapper>
		</>
	);
};

export default ChargesNewPage;
