import { SignerCollapseHeader } from './styles';
import { Account } from 'types/Account';
import { normalizeCpfCnpj } from 'helpers/normalizers';
import { useMemo } from 'react';
import { Col, Row } from 'antd';
import { Collapse, CollapseHeader } from 'components/Collapse';
import { TextM } from 'components/Text';
import { CompanyOutlinedIcon } from 'components/Icons/Company';
import { DescriptionsVertical } from 'components/DescriptionsVertical';
import { SignerIcon } from 'components/Icons/Signer';
import theme from 'theme/theme';

type SignerType = {
	companyName: string;
	companyTaxpayerId: string;
	signerName: string;
	signerTaxpayerId: string;
};

interface IEscrowDetailsSignersProps {
	account: Account;
	hideActions?: boolean;
}
const EscrowDetailsSigners = ({
	account,
	hideActions,
}: IEscrowDetailsSignersProps) => {
	const { person: company } = account;

	const companySigner = useMemo(() => {
		const companySigner = company.representatives.find(representative => {
			if (representative.sign_contract) return true;
			if (
				representative.representative &&
				representative.representative.sign_contract
			)
				return true;
			return false;
		});
		if (companySigner?.sign_contract) {
			return {
				companyName: company.name,
				companyTaxpayerId: normalizeCpfCnpj(company.taxpayer_id),
				signerName: companySigner?.full_name || '-',
				signerTaxpayerId: companySigner?.taxpayer_id
					? normalizeCpfCnpj(companySigner.taxpayer_id)
					: '-',
			};
		}
		return {
			companyName: company.name,
			companyTaxpayerId: normalizeCpfCnpj(company.taxpayer_id),
			signerName: companySigner?.representative?.full_name || '-',
			signerTaxpayerId: companySigner?.representative?.taxpayer_id
				? normalizeCpfCnpj(companySigner.representative.taxpayer_id)
				: '-',
		};
	}, [company]);

	const signers = useMemo(() => {
		return account.parties
			.filter(part => part.sign_contract)
			.reduce<SignerType[]>(
				(allSigners, part) => {
					const representatives = part.person.representatives.filter(
						representative =>
							representative.sign_contract ||
							representative.representative?.sign_contract,
					);

					return allSigners.concat(
						representatives.map(representative => {
							if (representative.sign_contract) {
								return {
									companyName: part.person?.name || '-',
									companyTaxpayerId: part.person?.taxpayer_id
										? normalizeCpfCnpj(
												part.person.taxpayer_id,
											)
										: '-',
									signerName:
										representative?.full_name || '-',
									signerTaxpayerId:
										representative?.taxpayer_id
											? normalizeCpfCnpj(
													representative.taxpayer_id,
												)
											: '-',
								};
							}
							return {
								companyName: part.person?.name || '-',
								companyTaxpayerId: part.person?.taxpayer_id
									? normalizeCpfCnpj(part.person.taxpayer_id)
									: '-',
								signerName:
									representative?.representative?.full_name ||
									'-',
								signerTaxpayerId: representative?.representative
									?.taxpayer_id
									? normalizeCpfCnpj(
											representative.representative
												.taxpayer_id,
										)
									: '-',
							};
						}),
					);
				},
				[companySigner],
			);
	}, [account, companySigner]);

	return (
		<Row gutter={[16, 16]}>
			<Col span={24}>
				<Collapse
					items={signers?.map((signer, i) => {
						return {
							key: `${i + 1}`,
							label: (
								<SignerCollapseHeader>
									<CollapseHeader>
										<SignerIcon />
										<TextM>{signer.signerName}</TextM>
									</CollapseHeader>
									<CollapseHeader>
										<CompanyOutlinedIcon
											color={theme.primary}
										/>
										<TextM>{signer.companyName}</TextM>
									</CollapseHeader>
								</SignerCollapseHeader>
							),
							children: (
								<Row gutter={[16, 16]}>
									<Col span={12}>
										<DescriptionsVertical
											borderless={true}
											title="Dados da empresa"
											hideActions={hideActions}
											items={[
												{
													key: '1',
													label: 'Nome da empresa',
													children:
														signer.companyName,
												},
												{
													key: '2',
													label: 'CNPJ',
													children:
														signer.companyTaxpayerId,
												},
											]}
										/>
									</Col>
									<Col span={12}>
										<DescriptionsVertical
											borderless={true}
											title="Dados do assinante"
											hideActions={hideActions}
											items={[
												{
													key: '1',
													label: 'Nome do assinante',
													children: signer.signerName,
												},
												{
													key: '2',
													label: 'CPF',
													children:
														signer.signerTaxpayerId,
												},
											]}
										/>
									</Col>
								</Row>
							),
						};
					})}
				/>
			</Col>
		</Row>
	);
};

export default EscrowDetailsSigners;
