import { ApiError } from 'types/ApiError';
import { WalletsService } from '../services';
import { useCallback, useState } from 'react';
import { WalletContent } from 'types/Wallet';

export const useWallets = (options?: {
	onError?: (error: ApiError) => void;
	onWalletSuccess?: (wallet: WalletContent) => void;
}) => {
	const [isWalletLoading, setIsWalletLoading] = useState<boolean>(false);

	const getWallet = useCallback(
		async (walletId: string): Promise<WalletContent | null> => {
			try {
				setIsWalletLoading(true);
				const wallet = await WalletsService.getWallet(walletId);
				if (typeof options?.onWalletSuccess === 'function') {
					options.onWalletSuccess(wallet);
				}
				return wallet;
			} catch (e) {
				if (typeof options?.onError === 'function') {
					options.onError(e as ApiError);
				}
				return null;
			} finally {
				setIsWalletLoading(false);
			}
		},
		[options],
	);

	return {
		isWalletLoading,
		getWallet,
	};
};
