import { AccountPermission } from 'types/Account';

export const paymentAccountPermissions = [
	AccountPermission.ARCHIVE_WALLET,
	AccountPermission.CANCEL_CHARGE,
	AccountPermission.CREATE_CHARGE,
	AccountPermission.CREATE_WALLET,
	AccountPermission.POSTING_CREATE,
	AccountPermission.POSTING_REVIEW,
	AccountPermission.POSTING_VIEW,
	AccountPermission.UPDATE_CHARGE,
	AccountPermission.UPDATE_WALLET,
	AccountPermission.VIEW_ACCOUNT,
	AccountPermission.VIEW_CHARGE,
	AccountPermission.VIEW_WALLET,
];
