import { useCallback, useState } from 'react';
import { UsersService } from '../services';
import { User } from 'types/User';
import { ApiError } from 'types/ApiError';

export const useUser = (options?: {
	onError?: (error: ApiError) => void;
	onSuccess?: (user: User | null, hasPrevUser: boolean) => void;
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [lastUser, setLastUser] = useState<User | null>(null);

	const getUserByEmailAddress = useCallback(
		async (emailAddress: string): Promise<User | null> => {
			try {
				setIsLoading(true);
				const user = await UsersService.getUsers({
					email_address: emailAddress,
				});
				const userResponse =
					user.content.length > 0 ? user.content[0] : null;
				const fullUser =
					userResponse &&
					(await UsersService.getUser(userResponse.id));

				if (typeof options?.onSuccess === 'function') {
					options.onSuccess(fullUser, lastUser !== null);
				}

				setLastUser(fullUser);

				return fullUser;
			} catch (error) {
				if (typeof options?.onError === 'function') {
					options.onError(error as ApiError);
				}
				return null;
			} finally {
				setIsLoading(false);
			}
		},
		[lastUser, options],
	);

	const getUserByTaxpayerId = useCallback(
		async (taxpayerId: string): Promise<User | null> => {
			try {
				setIsLoading(true);
				const user = await UsersService.getUsers({
					taxpayer_id: taxpayerId,
				});
				const userResponse =
					user.content.length > 0 ? user.content[0] : null;
				const fullUser =
					userResponse &&
					(await UsersService.getUser(userResponse.id));

				if (typeof options?.onSuccess === 'function') {
					options.onSuccess(fullUser, lastUser !== null);
				}

				setLastUser(fullUser);

				return fullUser;
			} catch (error) {
				if (typeof options?.onError === 'function') {
					options.onError(error as ApiError);
				}
				return null;
			} finally {
				setIsLoading(false);
			}
		},
		[lastUser, options],
	);

	const getUserById = useCallback(
		async (userId: string): Promise<User | null> => {
			try {
				setIsLoading(true);
				const user = await UsersService.getUser(userId);
				const fullUser = user ?? null;

				if (typeof options?.onSuccess === 'function') {
					options.onSuccess(fullUser, lastUser !== null);
				}

				setLastUser(fullUser);

				return fullUser;
			} catch (error) {
				if (typeof options?.onError === 'function') {
					options.onError(error as ApiError);
				}
				return null;
			} finally {
				setIsLoading(false);
			}
		},
		[options, lastUser],
	);

	return {
		getUserById,
		getUserByEmailAddress,
		getUserByTaxpayerId,
		loading: isLoading,
		user: lastUser,
	};
};
