import styled from 'styled-components';

export const ContainerHeader = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1rem;
`;

export const Container = styled.div`
	margin-bottom: 4rem;
`;
