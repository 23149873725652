import { Form } from 'antd';
import styled from 'styled-components';
import { ClientsListFiltersType } from './types';

export const FiltersTitle = styled.div`
	display: flex;
	p {
		padding-left: 0.3rem;
	}
`;

export const FiltersContainer = styled.div`
	display: flex;
	align-items: center;
	border-bottom: 1px solid ${({ theme }) => theme.border};
	padding: 0 0 1rem;

	svg {
		color: ${({ theme }) => theme.textSecondary};
	}
`;

export const FiltersForm = styled(Form<ClientsListFiltersType>)`
	display: flex;
	align-items: center;
	width: 100%;
	gap: 0.7rem;
	flex-wrap: wrap;
`;

export const StyledClientTypeContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

export const StyledTypeButtonSelector = styled.div`
	padding: 12px 32px;
	display: flex;
	align-items: center;
	gap: 16px;
	border-radius: 16px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
	cursor: pointer;
	border: 1px solid #ededed;

	&:hover {
		p {
			color: ${({ theme }) => theme.primary};
		}
	}
`;

export const StyledClientType = styled.div`
	margin: 1rem 2rem;
`;
