import { TextS } from 'components/Text';
import { EscrowCreationStepsContainer, Wrapper } from './Steps.styles';
import { Divider } from 'antd';
import { useTheme } from 'styled-components';
import { FaCheck } from 'react-icons/fa6';

export type StepsItem = {
	action?: () => void;
	checked?: boolean;
	completed?: boolean;
	title: string;
};

export interface IStepsProps {
	steps: StepsItem[];
}

export const Checkmark = ({ checked, title, completed, action }: StepsItem) => {
	const theme = useTheme();
	return (
		<Wrapper
			onClick={action}
			action={action}
			style={{
				display: 'flex',
				alignItems: 'center',
				gap: '0.5rem',
			}}
		>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					width: '16px',
					height: '16px',
					borderRadius: '50%',
					backgroundColor: `${completed ? theme.primary : theme.white}`,
					border: `1px solid ${checked ? theme.primary : 'transparent'}`,
				}}
			>
				{completed && (
					<FaCheck style={{ color: theme.white, width: '10px' }} />
				)}
			</div>

			<TextS style={{ color: checked ? theme.primary : theme.textGray }}>
				{title}
			</TextS>
		</Wrapper>
	);
};

const StepsComponent = ({ steps }: IStepsProps) => {
	return (
		<EscrowCreationStepsContainer>
			<div
				style={{
					padding: '0.5rem 0',
				}}
			>
				<TextS style={{ color: '#3F4254' }}>Etapas</TextS>
			</div>
			<Divider style={{ margin: 0 }} />
			<div
				style={{
					marginTop: '1rem',
					display: 'flex',
					flexDirection: 'column',
					gap: '2rem',
				}}
			>
				{steps.map((step, index) => (
					<Checkmark
						key={index}
						checked={step.checked}
						title={step.title}
						action={step.action}
						completed={step.completed}
					/>
				))}
			</div>
		</EscrowCreationStepsContainer>
	);
};

export default StepsComponent;
