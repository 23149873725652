import { useMemo } from 'react';
import { EscrowAccounts } from './pages/EscrowAccounts';
import { Tabs } from 'components/Tabs';
import { PageWrapper } from 'components/PageWrapper';
import { TabElement } from 'components/Tabs/Tabs';
import { PageHeaderWithButton } from 'components/PageHeader/PageHeader';
import { ClientsList } from './pages/ClientsList';

const ManagementPage = () => {
	const tabElements = useMemo<TabElement[]>(() => {
		const tabs = [
			{
				title: 'Contas',
				content: <EscrowAccounts />,
				path: 'accounts',
			},
			{
				title: 'Clientes',
				content: <ClientsList />,
				path: 'company',
			},
		];

		return tabs;
	}, []);

	return (
		<>
			<PageHeaderWithButton isFluid title="Gestão" />
			<PageWrapper isFluid={true}>
				<Tabs elements={tabElements} />
			</PageWrapper>
		</>
	);
};

export default ManagementPage;
