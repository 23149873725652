import styled from 'styled-components';

export const ChargesCreationStepsContainer = styled.div`
	background-color: #f7f7f7;
	display: flex;
	flex-direction: column;
	min-width: 14rem;
	padding: 0.8rem;
	height: 100% !important;
`;
