import { Button } from 'antd';
import { StepContainer, StepFooter } from '../styles';
import { Address } from 'types/Address';
import { Representative } from 'types/Representative';
import AddressContainer from 'components/Address/Address';
import { useState } from 'react';

interface IAddressStepProps {
	representative?: Representative;
	onNext: (representative: Representative) => void;
	onBack: (representative: Representative) => void;
}
const AddressStep = ({ representative, onNext, onBack }: IAddressStepProps) => {
	const [address, setAddress] = useState<Address>(
		representative?.address || ({} as Address),
	);

	const fieldsToCheck: (keyof Address)[] = [
		'street_name',
		'street_number',
		'postal_code',
		'district',
		'city',
		'state_code',
	];

	return (
		<StepContainer>
			<AddressContainer setAddress={setAddress} address={address} />

			<StepFooter>
				<Button
					type="link"
					onClick={() =>
						onBack({
							...(representative || ({} as Representative)),
							address: address,
						})
					}
				>
					Voltar
				</Button>
				<Button
					type="primary"
					disabled={
						!fieldsToCheck.every(field => address[field]?.trim())
					}
					onClick={() =>
						onNext({
							...(representative || ({} as Representative)),
							address: address,
						})
					}
				>
					Avançar
				</Button>
			</StepFooter>
		</StepContainer>
	);
};

export default AddressStep;
