import { Button } from 'antd';

// Components
import { TextS } from 'components/Text';
import { CheckIcon } from 'components/Icons/Check';
import { ArrowRightIcon } from 'components/Icons/ArrowRight';

// Styles
import { StyledButtonWrapper } from './StepsButton.styles';
import theme from 'theme/theme';

interface IStepsButtonComponentProps {
	nextStep: () => void;
	backStep: () => void;
	isDisabled?: boolean;
	isFirstStep: boolean;
	isLastStep?: boolean;
	lastStepButtonText?: string;
}

const StepsButtonComponent = ({
	nextStep,
	backStep,
	isDisabled,
	isFirstStep,
	isLastStep,
	lastStepButtonText,
}: IStepsButtonComponentProps) => {
	let buttonText = 'Avançar';

	if (isLastStep) {
		buttonText = lastStepButtonText
			? lastStepButtonText
			: 'Solicitar abertura de conta';
	}

	return (
		<StyledButtonWrapper>
			{isFirstStep && (
				<Button
					type="text"
					onClick={backStep}
					style={{ marginRight: '1rem' }}
				>
					<TextS color={theme.primary}>Voltar</TextS>
				</Button>
			)}
			<Button
				type="primary"
				onClick={nextStep}
				disabled={isDisabled}
				style={{
					opacity: isDisabled ? '0.5' : '1',
				}}
			>
				<TextS color={isDisabled ? theme.primary : theme.white}>
					{buttonText}
				</TextS>
				{isLastStep ? (
					<CheckIcon
						color={isDisabled ? theme.primary : theme.white}
					/>
				) : (
					<ArrowRightIcon
						size={21}
						color={isDisabled ? theme.primary : theme.white}
					/>
				)}
			</Button>
		</StyledButtonWrapper>
	);
};

export default StepsButtonComponent;
