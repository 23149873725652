import styled from 'styled-components';

export const Steps = styled.div`
	color: ${({ theme }) => theme.primary};
	font-size: 1rem;
	font-weight: bold;
`;

export const StepsContainer = styled.div`
	display: flex;
	padding: 0 1rem;
	height: 100%;
`;

export const StepContainer = styled.div`
	width: 100%;
	padding-bottom: 2rem;
`;

export const StepHeader = styled.div`
	padding-bottom: 1rem;
`;

export const StyledWrapperIcon = styled.div<{ isSelected: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme, isSelected }) =>
		isSelected ? theme.primary : 'none'};
	border-radius: 50%;
	width: 2rem;
	height: 2rem;
	border: 1px solid ${({ theme }) => theme.borderSecondary};
`;
