import { Steps } from 'antd';
import { ECreationSteps } from '../CompanyCreation';
import { TextM } from 'components/Text';
import theme from 'theme/theme';
import { StyledWrapperIcon } from '../styles';
import { AccountCircleLineIcon } from 'components/Icons/AccountCircleLine';
import { MapPinIcon } from 'components/Icons/MapPin';
import { FileSecondaryIcon } from 'components/Icons/File';
import { PersonIcon } from 'components/Icons/Person';
import { CheckIcon } from 'components/Icons/Check';

interface IStepsComponentProps {
	currentStep: ECreationSteps;
	isPF: boolean;
	isCompleted: {
		isInfoCompleted?: boolean;
		isAddressCompleted?: boolean;
		isDocumentsCompleted?: boolean;
	};
}

const StepsComponent = ({
	currentStep,
	isPF,
	isCompleted,
}: IStepsComponentProps) => {
	const stepsNumber = {
		[ECreationSteps.INFORMATION]: 1,
		[ECreationSteps.ADDRESS]: 2,
		[ECreationSteps.DOCUMENT]: 3,
		[ECreationSteps.REPRESENTATIVES]: 4,
	};

	return (
		<Steps
			current={stepsNumber[currentStep]}
			direction="vertical"
			style={{ height: '25rem', width: '17rem' }}
			items={[
				{
					title: (
						<TextM
							weight="bold"
							style={{ marginTop: '5px' }}
							color={
								currentStep === ECreationSteps.INFORMATION
									? theme.textSecondary
									: theme.textGray
							}
						>
							Informações
						</TextM>
					),
					icon: (
						<StyledWrapperIcon
							isSelected={
								currentStep === ECreationSteps.INFORMATION ||
								!!isCompleted.isInfoCompleted
							}
						>
							{isCompleted.isInfoCompleted ? (
								<CheckIcon size={18} color={theme.white} />
							) : (
								<AccountCircleLineIcon
									size={18}
									color={
										currentStep ===
										ECreationSteps.INFORMATION
											? theme.white
											: theme.borderSecondary
									}
								/>
							)}
						</StyledWrapperIcon>
					),
				},
				{
					title: (
						<TextM
							weight="bold"
							style={{ marginTop: '5px' }}
							color={
								currentStep === ECreationSteps.ADDRESS
									? theme.textSecondary
									: theme.textGray
							}
						>
							Endereço
						</TextM>
					),
					icon: (
						<StyledWrapperIcon
							isSelected={
								currentStep === ECreationSteps.ADDRESS ||
								!!isCompleted.isAddressCompleted
							}
						>
							{isCompleted.isAddressCompleted ? (
								<CheckIcon size={18} color={theme.white} />
							) : (
								<MapPinIcon
									size={18}
									color={
										currentStep === ECreationSteps.ADDRESS
											? theme.white
											: theme.borderSecondary
									}
								/>
							)}
						</StyledWrapperIcon>
					),
				},
				{
					title: (
						<TextM
							weight="bold"
							style={{ marginTop: '5px' }}
							color={
								currentStep === ECreationSteps.ADDRESS
									? theme.textSecondary
									: theme.textGray
							}
						>
							Documentos
						</TextM>
					),
					icon: (
						<StyledWrapperIcon
							isSelected={
								currentStep === ECreationSteps.DOCUMENT ||
								!!isCompleted.isDocumentsCompleted
							}
						>
							{isCompleted.isDocumentsCompleted ? (
								<CheckIcon size={18} color={theme.white} />
							) : (
								<FileSecondaryIcon
									size={18}
									color={
										currentStep === ECreationSteps.DOCUMENT
											? theme.white
											: theme.borderSecondary
									}
								/>
							)}
						</StyledWrapperIcon>
					),
				},
				{
					title: (
						<TextM
							weight="bold"
							style={{ marginTop: '5px' }}
							color={
								currentStep === ECreationSteps.ADDRESS
									? theme.textSecondary
									: theme.textGray
							}
						>
							{isPF ? 'Procurador' : 'Representante'}
						</TextM>
					),
					icon: (
						<StyledWrapperIcon
							isSelected={
								currentStep === ECreationSteps.REPRESENTATIVES
							}
						>
							<PersonIcon
								size={18}
								color={
									currentStep ===
									ECreationSteps.REPRESENTATIVES
										? theme.white
										: theme.borderSecondary
								}
							/>
						</StyledWrapperIcon>
					),
				},
			]}
		/>
	);
};

export default StepsComponent;
