import { CustomDrawer } from 'components/CustomDrawer';
import { FiltersForm } from './styles';
import { serializeOnlyNumbers } from 'helpers/serializers';
import { useCallback, useEffect } from 'react';
import { Button, Col, Input, Row, Select } from 'antd';
import { FiltersFormItem } from 'modules/escrow/pages/EscrowAccounts/styles';
import { normalizeCnpj, normalizeCpfCnpj } from 'helpers/normalizers';
import { LuSearch } from 'react-icons/lu';
import { ClientsListFiltersType } from './types';
import { cpfCnpjRule } from 'helpers/rules';
import { CompanyTypeDescription } from 'constants/company';
import { BusinessType, PersonType, PersonTypeDescription } from 'types/Company';

export interface ClientsListFiltersProps {
	isOpen: boolean;
	filters?: ClientsListFiltersType;
	onClose: () => void;
	onApply: (filters: ClientsListFiltersType) => void;
}

const ClientsListFilters = ({
	isOpen,
	filters,
	onClose,
	onApply,
}: ClientsListFiltersProps) => {
	const [form] = FiltersForm.useForm();

	const onFinish = useCallback(
		(values: ClientsListFiltersType) => {
			onApply({
				...values,
				taxpayer_id:
					values.taxpayer_id && values.taxpayer_id.length > 0
						? serializeOnlyNumbers(values.taxpayer_id)
						: undefined,
				search:
					values.search && values.search.length > 0
						? values.search
						: undefined,
				person_type:
					values.person_type && values.person_type.length > 0
						? values.person_type
						: undefined,
			} as ClientsListFiltersType);
		},
		[onApply],
	);

	useEffect(() => {
		if (isOpen) {
			form.setFieldsValue({
				taxpayer_id:
					filters?.taxpayer_id && normalizeCnpj(filters?.taxpayer_id),
				business_type: filters?.business_type,
				search: filters?.search,
			});
		}
	}, [isOpen]);

	return (
		<CustomDrawer
			open={isOpen}
			onClose={onClose}
			title="Busca avançada"
			footer={
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<Button
							style={{
								display: 'flex',
								alignItems: 'center',
								width: '100%',
								justifyContent: 'center',
								position: 'relative',
							}}
							size="large"
							type="primary"
							onClick={form.submit}
						>
							Aplicar filtros
							<LuSearch
								size={21}
								style={{ position: 'absolute', right: '15px' }}
							/>
						</Button>
					</Col>
				</Row>
			}
		>
			<FiltersForm
				initialValues={{
					taxpayer_id:
						filters?.taxpayer_id &&
						normalizeCnpj(filters?.taxpayer_id),
					business_type: filters?.business_type,
					search: filters?.search,
				}}
				layout="vertical"
				form={form}
				onFinish={onFinish}
			>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<FiltersFormItem name="search" label="Nome">
							<Input placeholder="Digite aqui" />
						</FiltersFormItem>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<FiltersFormItem
							name="taxpayer_id"
							label="CPF/CNPJ"
							normalize={value => normalizeCpfCnpj(value)}
							rules={[cpfCnpjRule]}
						>
							<Input placeholder="Digite aqui" />
						</FiltersFormItem>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<FiltersFormItem
							name="business_type"
							label="Tipo de Empresa"
						>
							<Select
								style={{ minWidth: '10rem' }}
								placeholder="Selecionar"
								allowClear={true}
								options={Object.keys(
									CompanyTypeDescription,
								).map(key => ({
									label: CompanyTypeDescription[
										key as BusinessType
									],
									value: key,
								}))}
							/>
						</FiltersFormItem>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<FiltersFormItem
							name="person_type"
							label="Tipo de Conta"
						>
							<Select
								style={{ minWidth: '10rem' }}
								placeholder="Selecionar"
								allowClear={true}
								options={Object.keys(PersonType).map(key => ({
									label: PersonTypeDescription[
										key as PersonType
									],
									value: key,
								}))}
							/>
						</FiltersFormItem>
					</Col>
				</Row>
			</FiltersForm>
		</CustomDrawer>
	);
};

export default ClientsListFilters;
