import { Button, Col, Input, Row, Select } from 'antd';
import { useCallback, useEffect } from 'react';
import { normalizeCep } from 'helpers/normalizers';
import { CustomDrawer } from 'components/CustomDrawer';
import { serializeOnlyNumbers } from 'helpers/serializers';
import { Company } from 'types/Company';
import { EditForm, EditFormItem } from './styles';
import { statesList } from 'constants/states';
import { CompanyForm } from 'modules/escrow/pages/EscrowEdition/CompanyInformation/types';

interface ICompanyAddressEditProps {
	isOpen: boolean;
	company: Company;
	onClose: () => void;
	onSave: (company: Company) => void;
}
const CompanyAddressEdit = ({
	isOpen,
	company,
	onSave,
	onClose,
}: ICompanyAddressEditProps) => {
	const [form] = EditForm.useForm();

	const onFinish = useCallback(
		(values: CompanyForm) => {
			onSave({
				...company,
				address: {
					...company.address,
					postal_code: serializeOnlyNumbers(values.postal_code),
					state_code: values.state_code,
					city: values.city,
					district: values.district,
					street_name: values.street_name,
					street_number: values.street_number,
					extra_info: values.extra_info,
					country_code: 'BRZ',
				},
			} as Company);
		},
		[onSave, company],
	);

	useEffect(() => {
		if (isOpen) {
			form.setFieldsValue({
				postal_code: normalizeCep(company?.address?.postal_code),
				state_code: company?.address?.state_code,
				city: company?.address?.city,
				district: company?.address?.district,
				street_name: company?.address?.street_name,
				street_number: company?.address?.street_number,
				extra_info: company?.address?.extra_info,
			});
		}
	}, [isOpen, form, company]);

	return (
		<CustomDrawer
			onClose={onClose}
			open={isOpen}
			title="Endereço"
			footer={
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<Button
							style={{
								display: 'flex',
								alignItems: 'center',
								width: '100%',
								justifyContent: 'center',
								position: 'relative',
							}}
							size="large"
							type="primary"
							onClick={form.submit}
						>
							Salvar
						</Button>
					</Col>
				</Row>
			}
		>
			<EditForm layout="vertical" form={form} onFinish={onFinish}>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<EditFormItem
							name="postal_code"
							label="CEP"
							normalize={e => normalizeCep(e)}
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input placeholder="00000-000" />
						</EditFormItem>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<EditFormItem
							name="state_code"
							label="Estado"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Select
								showSearch={true}
								filterOption={(input, option) =>
									option?.label
										?.toLowerCase()
										?.startsWith(input.toLowerCase()) ||
									String(option?.value)
										?.toLowerCase()
										?.startsWith(input.toLowerCase())
								}
								placeholder="Selecione o estado"
								options={statesList}
							/>
						</EditFormItem>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<EditFormItem
							name="city"
							label="Cidade"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input placeholder="Ex.: São Paulo" />
						</EditFormItem>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<EditFormItem
							name="district"
							label="Bairro"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input placeholder="Ex.: Planalto do Sol" />
						</EditFormItem>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<EditFormItem
							name="street_name"
							label="Rua/Avenida"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input placeholder="Ex.: Av Presidente Vargas" />
						</EditFormItem>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<EditFormItem
							name="street_number"
							label="Número"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input placeholder="Ex.: 300" />
						</EditFormItem>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ width: '100%' }} align="middle">
					<Col span={24}>
						<EditFormItem name="extra_info" label="Complemento">
							<Input placeholder="Ex.: Próximo a escola..." />
						</EditFormItem>
					</Col>
				</Row>
			</EditForm>
		</CustomDrawer>
	);
};

export default CompanyAddressEdit;
