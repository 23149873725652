import { Button, Dropdown, Table, notification } from 'antd';
import { DropdownIcon, FiltersContainer } from './styles';
import { FilterButton } from 'components/FilterButton';
import { LuSearch } from 'react-icons/lu';
import { useCallback, useState } from 'react';
import { TextS } from 'components/Text';
import { AiOutlineStop } from 'react-icons/ai';
import theme from 'theme/theme';
import CnabFilter from './Filter/Filter';
import { IReturnedCnab, ICnabFilters, ECnabExportFileType } from './types';
import { ESortingPage, PaginationInfo } from 'types/List';
import { ColumnsType } from 'antd/es/table';
import { DownloadIcon } from 'components/Icons/Download';
import { useQuery } from '@tanstack/react-query';
import { WalletsService } from 'modules/debitCollections/services';
import { sortTableHandler } from 'helpers/tables';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';

export default function CnabList() {
	const [api, contextHolder] = notification.useNotification();

	const [isOpenFilterModal, setIsOpenFilterModal] = useState<boolean>(false);
	const [filters, setFilters] = useState<ICnabFilters>({} as ICnabFilters);
	const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>({
		currentPage: 1,
		pageSize: 10,
	});
	const [sorting, setSorting] = useState<{
		order: ESortingPage;
		sort: string;
	}>({ order: ESortingPage.DESC, sort: 'created_at' });

	const handleClearFilters = useCallback(() => {
		setFilters({} as ICnabFilters);
	}, []);

	const { data, isLoading } = useQuery({
		queryKey: ['cnabList', paginationInfo, sorting, filters],
		queryFn: () =>
			WalletsService.getCnabReturnedList({
				created_at: filters.created_at,
				page: paginationInfo.currentPage,
				size: paginationInfo.pageSize,
				order: sorting.order,
				sort: sorting.sort,
			}),
	});

	const downloadExportCnab = useCallback(
		async (cnabId: string, type: ECnabExportFileType): Promise<null> => {
			try {
				const response =
					await WalletsService.getCnabReturnedDownloadUrl(cnabId, {
						file_type: type,
					});

				saveAs(response.url);

				return null;
			} catch (e) {
				console.error('Ocorreu um problema ao fazer o download.', e);
				return null;
			}
		},
		[],
	);

	const renderOptions = (item: IReturnedCnab) => {
		const menu = [
			{
				label: 'TXT',
				key: 0,
				onClick: () => {
					downloadExportCnab(item.id, ECnabExportFileType.TXT);
				},
				icon: (
					<DropdownIcon>
						<DownloadIcon />
					</DropdownIcon>
				),
			},
		];

		if (item.file_types.includes(ECnabExportFileType.CSV)) {
			menu.push({
				label: 'CSV',
				key: 1,
				onClick: () => {
					downloadExportCnab(item.id, ECnabExportFileType.CSV);
				},
				icon: (
					<DropdownIcon>
						<DownloadIcon />
					</DropdownIcon>
				),
			});
		}

		if (item.file_types.includes(ECnabExportFileType.PDF)) {
			menu.push({
				label: 'PDF',
				key: 2,
				onClick: () => {
					downloadExportCnab(item.id, ECnabExportFileType.PDF);
				},
				icon: (
					<DropdownIcon>
						<DownloadIcon />
					</DropdownIcon>
				),
			});
		}

		return (
			<Dropdown
				disabled={isLoading}
				trigger={['click']}
				menu={{
					items: menu,
				}}
			>
				<Button
					type="link"
					style={{
						color: theme.textSecondary,
						fontWeight: 'bold',
						fontSize: '1.3rem',
						lineHeight: '1rem',
						letterSpacing: '0.09rem',
					}}
					loading={isLoading}
				>
					...
				</Button>
			</Dropdown>
		);
	};

	const columns: ColumnsType<IReturnedCnab> = [
		{
			key: 'created_at',
			render: (item: IReturnedCnab) => (
				<TextS color={theme.textGray}>
					{item.created_at
						? `${dayjs(item.created_at).format(
								'DD/MM/YYYY',
							)} às ${dayjs(item.created_at).format('HH:mm:ss')}`
						: '-'}
				</TextS>
			),
			sorter: true,
			title: 'Processado em',
			width: '90%',
		},
		{
			align: 'center',
			render: (item: IReturnedCnab) => renderOptions(item),
			title: 'Ação',
		},
	];

	return (
		<div>
			{contextHolder}
			<FiltersContainer>
				<FilterButton
					icon={<LuSearch size={18} />}
					onClick={() => setIsOpenFilterModal(true)}
				>
					<TextS style={{ color: theme.white }}>Busca avançada</TextS>
				</FilterButton>
				<FilterButton
					icon={<AiOutlineStop size={18} color={theme.white} />}
					variation="secondary"
					onClick={handleClearFilters}
					disabled={!Object.keys(filters).length}
				>
					<TextS style={{ color: theme.white }}>Limpar filtros</TextS>
				</FilterButton>
			</FiltersContainer>

			<Table
				columns={columns}
				rowKey={item => item.id}
				dataSource={data?.content}
				loading={isLoading}
				onChange={(_, __, sorter) =>
					sortTableHandler(sorter, setSorting)
				}
				pagination={{
					total: data?.total_elements || 0,
					showTotal(total) {
						const currentSize =
							paginationInfo.currentPage *
							paginationInfo.pageSize;
						return `${currentSize > total ? total : currentSize} de ${total}`;
					},
					pageSizeOptions: ['10', '30', '60', '90'],
					showSizeChanger: true,
					pageSize: paginationInfo.pageSize,
					current: paginationInfo.currentPage,
					onChange(page, pageSize) {
						setPaginationInfo({
							currentPage: page,
							pageSize,
						});
					},
				}}
			/>

			<CnabFilter
				isOpen={isOpenFilterModal}
				onClose={() => setIsOpenFilterModal(false)}
				onApply={filters => {
					setFilters(filters);
					setIsOpenFilterModal(false);
				}}
			/>
		</div>
	);
}
