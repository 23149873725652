import styled from 'styled-components';
import theme from 'theme/theme';

type DivType = {
	action?: () => void;
};

export const EscrowCreationStepsContainer = styled.div`
	background-color: #f7f7f7;
	display: flex;
	flex-direction: column;
	min-width: 14rem;
	padding: 0.8rem;
	height: 100% !important;
`;

export const Wrapper = styled.div<DivType>`
	cursor: ${props => (props.action ? 'pointer' : 'default')};

	p {
		&:hover {
			color: ${props =>
				props.action ? theme.primary : 'default'} !important;
			text-decoration: ${props => (props.action ? 'underline' : 'none')};
		}
	}
`;
