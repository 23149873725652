import React from 'react';
import { Tabs } from 'components/Tabs';
import { PageHeader } from 'components/PageHeader';
import { Heading1 } from 'components/Heading1';
import { PageWrapper } from 'components/PageWrapper';
import { BreadcrumbContainer } from 'components/Breadcrumb';
import CompanyDetailsUsers from './CompanyDetailsUsers';
import { CompanyRoutes } from 'modules/company/constants/routes';
import { useQuery } from '@tanstack/react-query';
import { Navigate, useParams } from 'react-router-dom';
import { PersonsService } from 'modules/company/services';
import { SplashScreen } from 'components/SplashScreen';
import CompanyDetailsInformation from './CompanyDetailsInformation';
import { BusinessType } from 'types/Company';
import { ManagementIcon } from 'modules/management/constants/routes';
import { CompanyRepresentatives } from 'components/CompanyRepresentatives';

const CompanyDetailsPage = () => {
	const { id } = useParams();

	const { data, isLoading } = useQuery({
		queryKey: ['companyDetails', id],
		queryFn: () => PersonsService.getCompany(id!),
		refetchOnWindowFocus: false,
		enabled: typeof id === 'string',
	});

	const { data: companyFund, isLoading: isCompanyFundLoading } = useQuery({
		queryKey: ['companyFund', id],
		refetchOnWindowFocus: false,
		queryFn: () => PersonsService.getCompanyFund(id!),
		enabled: data && data.business_type === BusinessType.FUND,
	});

	if (isLoading || isCompanyFundLoading) {
		return <SplashScreen />;
	}

	if (!data) {
		return <Navigate to={CompanyRoutes.BASE} />;
	}

	return (
		<>
			<PageHeader isFluid={true}>
				<Heading1>{data?.name}</Heading1>
			</PageHeader>
			<PageWrapper isFluid={true}>
				<Tabs
					prePath={`/${id}`}
					elements={[
						{
							title: 'Informações',
							content: (
								<CompanyDetailsInformation
									company={data}
									companyFund={companyFund}
								/>
							),
							path: 'info',
							breadcrumb: [
								{
									href: '/management/company',
									title: (
										<BreadcrumbContainer>
											<ManagementIcon />
											<span>Gestão</span>
										</BreadcrumbContainer>
									),
								},
								{
									title: data?.name,
								},
							],
						},
						{
							title: 'Representantes',
							content: (
								<CompanyRepresentatives
									isClientEdition
									company={data}
								/>
							),
							path: 'representatives',
							breadcrumb: [
								{
									href: '/management/company',
									title: (
										<BreadcrumbContainer>
											<ManagementIcon />
											<span>Gestão</span>
										</BreadcrumbContainer>
									),
								},
								{
									title: data?.name,
								},
							],
						},
						{
							title: 'Acessos',
							content: <CompanyDetailsUsers company={data} />,
							path: 'access',
							breadcrumb: [
								{
									href: '/management/company',
									title: (
										<BreadcrumbContainer>
											<ManagementIcon />
											<span>Gestão</span>
										</BreadcrumbContainer>
									),
								},
								{
									title: data?.name,
								},
							],
						},
					]}
				/>
			</PageWrapper>
		</>
	);
};

export default CompanyDetailsPage;
