import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { EmptyFiltersResult } from 'components/EmptyFiltersResult';
import { PageHeaderWithButton } from 'components/PageHeader/PageHeader';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { PageWrapper } from 'components/PageWrapper';
import { useBreadcrumb } from 'modules/core/context/AppContext';
import { AccountsService } from 'modules/escrow/services';
import { getManagementBreadcrumb } from 'modules/management/constants/routes';
import { TextL, TextM, TextS } from 'components/Text';
import { InformationModal } from 'components/InformationModal';
import { CheckmarkIcon } from 'components/Icons/Checkmark';
import { ManagementService } from 'modules/management/services/management';
import { ApiError } from 'types/ApiError';
import { Button, Col, Divider, Flex, notification, Row } from 'antd';
import { queryClient } from 'network/query';
import { SplashScreen } from 'components/SplashScreen';
import {
	formatCurrency,
	normalizeBankAccount,
	normalizeCpfCnpj,
} from 'helpers/normalizers';
import { GoTrash } from 'react-icons/go';
import { useTheme } from 'styled-components';
import { BankListCode } from 'constants/bankListCode';
import { BankListDescription } from 'constants/banksList';
import { DestinationTypeDescription } from 'constants/destination';
import { Documents } from 'components/Documents';
import { PersonsService } from 'modules/company/services';
import { DescriptionsRow } from './components/DescriptionsRow';
import { AccountDocumentsList } from 'constants/account';
import { IoMdAdd } from 'react-icons/io';
import { AccountUpdateDto } from 'types/Account';
import { RiPencilLine } from 'react-icons/ri';
import { DestinationCreationNaturalPerson } from 'modules/escrow/components/DestinationCreationNaturalPerson';
import { Destination } from 'types/Destination';
import { useDestinations } from 'modules/escrow/hooks';
import { BackgroundModalData } from 'components/BackgroundModalData';
import CreateCustomFeeModal, {
	CreateCustomFeeFormValues,
} from './CreateCustomFeeModal';
import {
	CustomFeeDTO,
	CustomFeeType,
	FeeType,
} from 'components/FeeSettingsModal/types';
import Confirmation from 'components/Icons/Confirmation';
import { getFormatterFee } from 'modules/escrow/utils/stringFormatter';
import FeeSettingsModalComission from 'components/FeeSettingsModalComission/FeeSettingsModalCommission';
import FeeDescriptions from 'components/FeeDescriptions/FeeDescriptions';

const AccountConfigPage = () => {
	const theme = useTheme();
	const [api, contextHolder] = notification.useNotification();
	const [isDestinationCreationOpen, setIsDestinationCreationOpen] =
		useState(false);
	const [isFeeSettingsModalOpen, setIsFeeSettingsModalOpen] = useState(false);
	const [isUpdateAccountInfoModalOpen, setUpdateAccountInfoModalOpen] =
		useState(false);
	const [editDestination, setEditDestination] = useState<Destination>();
	const [destinationToConfirm, setDestinationToConfirm] =
		useState<Destination>();
	const [isDestinationRemoveOpen, setIsDestinationRemoveOpen] =
		useState(false);
	const [
		isDestinationSuccessfullyRemoved,
		setIsDestinationSuccessfullyRemoved,
	] = useState(false);
	const [createCustomModalOpen, setCreateCustomModalOpen] = useState(false);
	const [
		createDestinationSuccessModalOpen,
		setCreateDestinationSuccessModalOpen,
	] = useState(false);
	const [
		isCreateCustomFeeSuccessModalOpen,
		setCreateCustomFeeSuccessModalOpen,
	] = useState(false);

	const { id } = useParams();
	const { setBreadcrumb } = useBreadcrumb();

	const handleDeleteDestination = useCallback((item: Destination) => {
		setDestinationToConfirm(item);
		setIsDestinationRemoveOpen(true);
	}, []);

	const onCloseDestinationCreation = useCallback(() => {
		setEditDestination(undefined);
		setIsDestinationCreationOpen(false);
	}, []);

	const { createOrUpdateDestination, deleteDestination, loading } =
		useDestinations(id ?? '', {
			onCreateSuccess: () => {
				setCreateDestinationSuccessModalOpen(true);
				setDestinationToConfirm(undefined);

				queryClient.refetchQueries({
					queryKey: ['accountDetails', id],
				});
			},
			onDeleteSuccess: () => {
				setDestinationToConfirm(undefined);
				setIsDestinationRemoveOpen(false);

				setIsDestinationSuccessfullyRemoved(true);

				queryClient.refetchQueries({
					queryKey: ['accountDetails', id],
				});
			},
			onError: error => {
				setEditDestination(undefined);
				api.error({
					description: error.data.message,
					message: 'Ocorreu um problema com conta beneficiária.',
				});
			},
		});

	const onCreateDestination = useCallback(
		(newDestination: Destination) => {
			if (editDestination) {
				createOrUpdateDestination({
					...editDestination,
					...newDestination,
				});
			} else {
				createOrUpdateDestination(newDestination);
			}
		},
		[createOrUpdateDestination, editDestination],
	);

	const handleCreateCustomFee = useCallback(
		(values: CreateCustomFeeFormValues) => {
			createCustomFee({
				...values,
				account_id: id ?? '',
				type: CustomFeeType.CUSTOM,
			});
		},
		[id],
	);

	const { mutate, isPending } = useMutation<void, ApiError, AccountUpdateDto>(
		{
			mutationFn: fees => {
				return AccountsService.updateAccount(id ?? '', fees);
			},
			onSuccess: () => {
				setIsFeeSettingsModalOpen(false);
				setUpdateAccountInfoModalOpen(true);
				queryClient.refetchQueries({
					queryKey: ['accountDetails', id],
				});
			},
			onError: e => {
				setIsFeeSettingsModalOpen(false);
				api.error({
					description: e.data.message,
					message:
						'Ocorreu um problema ao criar a conta beneficiária.',
				});
			},
		},
	);

	const { mutate: createCustomFee, isPending: craeteCustomFeeLoading } =
		useMutation<void, ApiError, CustomFeeDTO>({
			mutationFn: fee => {
				return ManagementService.createCustomFee(fee);
			},
			onSuccess: () => {
				setCreateCustomModalOpen(false);
				setCreateCustomFeeSuccessModalOpen(true);

				queryClient.refetchQueries({
					queryKey: ['customFeeList', id],
				});
			},
			onError: e => {
				setCreateCustomModalOpen(false);
				api.error({
					description: e.data.message,
					message: 'Ocorreu um problema.',
				});
			},
		});

	const { data: account, isLoading } = useQuery({
		queryKey: ['accountDetails', id],
		queryFn: () => AccountsService.getAccount(id!),
		refetchOnWindowFocus: false,
		enabled: typeof id === 'string',
	});

	const { data: person, isLoading: personLoading } = useQuery({
		queryKey: ['personDetails', account?.person_id],
		queryFn: () => PersonsService.getCompany(account?.person_id ?? ''),
		refetchOnWindowFocus: false,
		enabled: !!account,
	});

	const { data, isLoading: getAccountLoading } = useQuery({
		queryKey: ['accountDetails', id],
		queryFn: () => AccountsService.getAccount(id!),
		refetchOnWindowFocus: false,
		enabled: typeof id === 'string',
	});

	const { data: customFeeData, isLoading: getCustomFeeDataLoading } =
		useQuery({
			queryKey: ['customFeeList', id],
			queryFn: () =>
				ManagementService.listFees(id!, { type: FeeType.CUSTOM }),
			refetchOnWindowFocus: false,
			enabled: typeof id === 'string',
		});

	useEffect(() => {
		setBreadcrumb(
			getManagementBreadcrumb(
				'Configurações da Conta',
				data?.account,
				data?.id,
			),
		);
	}, [setBreadcrumb, data?.account]);

	if (
		isLoading ||
		personLoading ||
		getAccountLoading ||
		getCustomFeeDataLoading
	) {
		return <SplashScreen />;
	}

	if (!account || !person) {
		return <EmptyFiltersResult />;
	}

	const accountDestinations = account.destinations.map((destination, idx) => {
		return {
			itemId: destination.id,
			data: [
				{
					key: `${idx}-1`,
					label: 'Beneficiário',
					content: (
						<div>
							<TextS>{destination.name}</TextS>
							<TextS>
								CPF / CNPJ:{' '}
								{normalizeCpfCnpj(destination.taxpayer_id)}
							</TextS>
						</div>
					),
				},
				{
					key: `${idx}-2`,
					label: 'Dados da conta',
					content: (
						<div>
							<TextS>
								{
									BankListDescription[
										(destination?.bank as keyof typeof BankListCode) ??
											''
									]
								}
							</TextS>
							<TextS>
								Ag: {destination.branch} | CC.:{' '}
								{normalizeBankAccount(destination.account)}
							</TextS>
							<TextS>
								{
									DestinationTypeDescription[
										destination.type as keyof typeof DestinationTypeDescription
									]
								}
							</TextS>
						</div>
					),
				},
				{
					key: `${idx}-3`,
					label: '',
					content: (
						<Button
							type="text"
							onClick={() => handleDeleteDestination(destination)}
						>
							<GoTrash size={16} color={theme.danger} />
						</Button>
					),
				},
			],
		};
	});

	const feeItems = [
		{
			key: '1',
			label: 'Tarifa de manutenção',
			children: account.fees?.maintenance?.amount
				? formatCurrency(account.fees.maintenance.amount)
				: '-',
		},
		{
			key: '2',
			label: 'Tarifa Pix',
			children: getFormatterFee(
				account.fees?.withdrawal?.amount,
				account.fees?.withdrawal?.amount_type,
			),
		},
		{
			key: '3',
			label: 'Tarifa de entrada',
			children: getFormatterFee(
				account.fees?.deposit?.amount,
				account.fees?.deposit?.amount_type,
			),
		},
		{
			key: '4',
			label: 'Tarifa de liquidação de boleto',
			children: getFormatterFee(
				account.fees?.charge_settlement?.amount,
				account.fees?.charge_settlement?.amount_type,
			),
		},
		{
			key: '5',
			label: 'Data de cobrança da tarifa',
			children: account.fees?.maintenance?.day
				? `Todo dia ${account.fees.maintenance.day}`
				: '-',
		},
		{
			key: '6',
			label: 'Tarifa TED',
			children: getFormatterFee(
				account.fees?.ted_transfer?.amount,
				account.fees?.ted_transfer?.amount_type,
			),
		},
		{
			key: '7',
			label: 'Método de pagamento padrão',
			children: account.fees?.default_payment_type
				? account.fees.default_payment_type
				: '-',
		},
	];

	const individualFees =
		customFeeData?.content && customFeeData?.content.length > 0
			? customFeeData?.content.map((customFee, idx) => {
					return {
						itemId: customFee.id ?? '',
						data: [
							{
								key: `${idx}-1`,
								label: 'Tarifa',
								content: (
									<div
										style={{
											padding: '10px 0',
										}}
									>
										<TextS>
											{customFee.description ?? '-'}
										</TextS>
									</div>
								),
							},
							{
								key: `${idx}-2`,
								label: 'Valor',
								content: (
									<div
										style={{
											padding: '10px 0',
										}}
									>
										<TextS>
											{formatCurrency(customFee.amount)}
										</TextS>
									</div>
								),
							},
							{
								key: `${idx}-3`,
								label: '',
								content: <></>,
							},
						],
					};
				})
			: undefined;

	return (
		<>
			{contextHolder}
			<PageHeaderWithButton title="Configurações da Conta" hasDivider />
			<PageWrapper isFluid={true}>
				<div
					style={{
						marginTop: '3rem',
						width: '100%',
					}}
				>
					<DescriptionsRow
						title="Contas beneficiárias"
						items={accountDestinations}
						addComponent={
							<div>
								<Button
									type="primary"
									onClick={() => {
										setIsDestinationCreationOpen(true);
									}}
									style={{ padding: '16px' }}
								>
									<Flex gap={10} align="center">
										<TextM style={{ color: theme.white }}>
											Adicionar conta beneficiária
										</TextM>
										<IoMdAdd
											size={18}
											color={theme.white}
										/>
									</Flex>
								</Button>
							</div>
						}
						emptyComponent={
							<div
								style={{
									padding: '8px 20px',
								}}
							>
								<TextS
									style={{
										color: theme.textGray,
									}}
								>
									Esta conta não possui nenhuma conta
									beneficiária. Você pode adicionar uma conta
									nova na opção ”Adicionar conta
									beneficiária".
								</TextS>
							</div>
						}
					/>
				</div>
				<div
					style={{
						marginTop: '2rem',
					}}
				>
					<FeeDescriptions
						title="Tarifas"
						fees={account.fees!}
						handleOnEdit={() => setIsFeeSettingsModalOpen(true)}
						onEditComponent={
							<Button
								type="primary"
								onClick={() => {
									setIsFeeSettingsModalOpen(true);
								}}
								style={{ padding: '16px' }}
							>
								<Flex gap={10} align="center">
									<TextM
										style={{
											color: theme.white,
										}}
									>
										Editar tarifa
									</TextM>
									<RiPencilLine
										size={18}
										color={theme.white}
									/>
								</Flex>
							</Button>
						}
						extraContent={
							<div>
								<Divider
									style={{
										marginTop: '1.5rem',
										padding: 0,
										margin: 0,
									}}
								/>
								<div
									style={{
										marginTop: '1rem',
									}}
								>
									<DescriptionsRow
										title="Tarifas avulsas"
										items={individualFees}
										onEdit={() =>
											setIsFeeSettingsModalOpen(true)
										}
										borderless
										addComponent={
											<div>
												<Button
													type="primary"
													onClick={() =>
														setCreateCustomModalOpen(
															true,
														)
													}
													style={{ padding: '16px' }}
												>
													<Flex
														gap={10}
														align="center"
													>
														<TextM
															style={{
																color: theme.white,
															}}
														>
															Adicionar tarifa
														</TextM>
														<IoMdAdd
															size={18}
															color={theme.white}
														/>
													</Flex>
												</Button>
											</div>
										}
										emptyComponent={
											<div
												style={{
													padding: '8px 20px',
												}}
											>
												<TextS
													style={{
														color: theme.textGray,
													}}
												>
													Esta conta não possui
													nenhuma tarifa avulsa. Você
													pode adicionar mais tarifas
													na opção ”Adicionar tarifa".
												</TextS>
											</div>
										}
									/>
								</div>
							</div>
						}
					/>
				</div>
				<Row gutter={[16, 16]} style={{ marginTop: '2rem' }}>
					<Col span={24}>
						<Documents
							title="Contratos"
							isDisabled={true}
							documentsObject={account.documents}
							documentsList={AccountDocumentsList}
						/>
					</Col>
				</Row>
			</PageWrapper>
			<FeeSettingsModalComission
				accountId={id}
				isOpen={isFeeSettingsModalOpen}
				onClose={() => setIsFeeSettingsModalOpen(false)}
				feesSettings={account.fees}
				loading={isPending}
				handleConfirm={values => {
					mutate({
						fees: values,
					});
				}}
				isEdit
			/>
			<InformationModal
				buttonText="Ok, obrigado"
				isOpen={isUpdateAccountInfoModalOpen}
				message="As tarifas foram atualizadas com sucesso!"
				title="Tarifas"
				icon={<CheckmarkIcon />}
				onClose={() => setUpdateAccountInfoModalOpen(false)}
			/>

			<DestinationCreationNaturalPerson
				isOpen={isDestinationCreationOpen}
				destination={undefined}
				isLoading={false}
				onClose={onCloseDestinationCreation}
				onCreate={destination => {
					onCreateDestination(destination);
					setIsDestinationCreationOpen(false);
				}}
			/>

			<ConfirmationModal
				isOpen={isDestinationRemoveOpen}
				danger={true}
				title="Remover conta beneficiária"
				confirmText="Remover conta beneficiária"
				cancelText="Cancelar"
				isLoading={loading}
				onConfirm={() => {
					if (destinationToConfirm) {
						deleteDestination(destinationToConfirm.id);
					}
				}}
				onCancel={() => setIsDestinationRemoveOpen(false)}
				onClose={() => setIsDestinationRemoveOpen(false)}
			>
				<TextL weight="bold" style={{}}>
					Você tem certeza que deseja remover essa conta beneficiária?
				</TextL>

				<BackgroundModalData
					style={{
						marginTop: '2rem',
					}}
				>
					<Row>
						<Col span={12}>
							<TextM weight="bold">Beneficiário</TextM>
							<TextS color={theme.textSecondary} weight={400}>
								{destinationToConfirm?.name}
							</TextS>
						</Col>
						<Col span={12}>
							<TextM weight="bold">Instituição financeira</TextM>
							<TextS color={theme.textSecondary} weight={400}>
								{
									BankListDescription[
										(destinationToConfirm?.bank as keyof typeof BankListCode) ??
											''
									]
								}
							</TextS>
						</Col>
					</Row>
					<Row
						style={{
							marginTop: '1rem',
						}}
					>
						<Col span={12}>
							<TextM weight="bold">Agência</TextM>
							<TextS color={theme.textSecondary} weight={400}>
								{destinationToConfirm?.branch}
							</TextS>
						</Col>
						<Col span={12}>
							<TextM weight="bold">Conta corrente</TextM>
							<TextS color={theme.textSecondary} weight={400}>
								{normalizeBankAccount(
									destinationToConfirm?.account ?? '',
								)}
							</TextS>
						</Col>
					</Row>
				</BackgroundModalData>
			</ConfirmationModal>

			<CreateCustomFeeModal
				isOpen={createCustomModalOpen}
				onClose={() => {
					setCreateCustomModalOpen(false);
				}}
				onConfirm={values => {
					handleCreateCustomFee(values);
				}}
			/>

			<ConfirmationModal
				title="Conta beneficiária adicionada"
				isOpen={createDestinationSuccessModalOpen}
				onClose={() => setCreateDestinationSuccessModalOpen(false)}
				confirmText="Ok, obrigado"
				onConfirm={() => setCreateDestinationSuccessModalOpen(false)}
			>
				<Row
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Col span={3}>
						<Confirmation />
					</Col>
					<Col span={21}>
						<TextL>Conta beneficiária adicionada com sucesso</TextL>
					</Col>
				</Row>
			</ConfirmationModal>

			<ConfirmationModal
				title="Conta beneficiária removida"
				isOpen={isDestinationSuccessfullyRemoved}
				onClose={() => setIsDestinationSuccessfullyRemoved(false)}
				confirmText="Ok, obrigado"
				onConfirm={() => setIsDestinationSuccessfullyRemoved(false)}
			>
				<Row
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Col span={3}>
						<Confirmation />
					</Col>
					<Col span={21}>
						<TextL>Conta beneficiária removida com sucesso!</TextL>
					</Col>
				</Row>
			</ConfirmationModal>
			<ConfirmationModal
				title="Tarifa adicionada"
				isOpen={isCreateCustomFeeSuccessModalOpen}
				onClose={() => setCreateCustomFeeSuccessModalOpen(false)}
				confirmText="Ok, obrigado"
				onConfirm={() => setCreateCustomFeeSuccessModalOpen(false)}
			>
				<Row
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Col span={3}>
						<Confirmation />
					</Col>
					<Col span={21}>
						<TextL>Tarifa criada com sucesso</TextL>
					</Col>
				</Row>
			</ConfirmationModal>
		</>
	);
};

export default AccountConfigPage;
