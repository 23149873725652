import { useEffect, useState } from 'react';
import { Button, Row } from 'antd';
import { PersonsService } from 'modules/company/services';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Company, BusinessType, PersonType, CompanyDto } from 'types/Company';
import { normalizeCpfCnpj } from 'helpers/normalizers';
import { CompanyTypeDescription } from 'constants/company';
import { useNavigate } from 'react-router-dom';
import { Table } from 'components/Table';
import { ESortingPage, PaginationInfo } from 'types/List';
import dayjs from 'dayjs';
import { FilterButton } from 'components/FilterButton';
import { LuPlusCircle, LuSearch } from 'react-icons/lu';
import { TextM, TextS } from 'components/Text';
import { AiOutlineStop } from 'react-icons/ai';
import CompanyListFilters from './ClientsListFilters';
import { ClientsListFiltersType } from './types';
import { Creator } from 'types/Creator';
import { sortTableHandler } from 'helpers/tables';
import { queryClient } from 'network/query';
import theme from 'theme/theme';
import { useEscrowAccountsContext } from 'modules/escrow/context/EscrowAccounts.context';
import { HiOutlineCog6Tooth } from 'react-icons/hi2';
import { useBreadcrumb } from 'modules/core/context/AppContext';
import CompanyCreationModal from 'modules/company/components/CompanyCreation/CompanyCreation';

const ClientsList = () => {
	/* Hooks */
	const navigate = useNavigate();
	const { resetEscrowContext } = useEscrowAccountsContext();
	const { setBreadcrumb } = useBreadcrumb();

	const [isOpenCreateClientForm, setIsOpenCreateClientForm] =
		useState<boolean>(false);
	const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);
	const [sorting, setSorting] = useState<{
		order: ESortingPage;
		sort: string;
	}>({ order: ESortingPage.DESC, sort: 'created_at' });
	const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>({
		currentPage: 1,
		pageSize: 10,
	});
	const [filters, setFilters] = useState<ClientsListFiltersType>(
		{} as ClientsListFiltersType,
	);

	const handleClearFilters = () => {
		setFilters({} as ClientsListFiltersType);
	};

	useEffect(() => {
		//cleaning breadcrumb
		setBreadcrumb(undefined);
		resetEscrowContext();
	}, [setBreadcrumb]);

	const { data, isLoading } = useQuery({
		queryKey: ['companyList', paginationInfo, filters, sorting],
		queryFn: () =>
			PersonsService.getPersons({
				page: paginationInfo.currentPage,
				size: paginationInfo.pageSize,
				search: filters?.search,
				taxpayer_id: filters?.taxpayer_id,
				business_type: filters?.business_type,
				person_type: filters?.person_type,
				sort: sorting.sort,
				order: sorting.order,
			}),
		placeholderData: keepPreviousData,
		staleTime: 5000,
	});

	const columns: ColumnsType<Company> = [
		{
			title: 'Cliente',
			sorter: true,
			dataIndex: 'name',
			key: 'name',
			width: '20%',
			render: (value: string) => <TextM weight="bold">{value}</TextM>,
		},
		{
			title: 'CPF/CNPJ',
			dataIndex: 'taxpayer_id',
			render: (taxpayer_id: string) => (
				<TextM weight="normal">{`${normalizeCpfCnpj(taxpayer_id)}`}</TextM>
			),
			width: '20%',
		},
		{
			title: 'Tipo',
			sorter: true,
			key: 'business_type',
			dataIndex: 'business_type',
			render: (business_type: BusinessType) => (
				<TextM weight="normal">
					{CompanyTypeDescription[business_type] || '-'}
				</TextM>
			),
			width: '15%',
		},
		{
			title: 'Data da criação',
			dataIndex: 'created_at',
			key: 'created_at',
			sorter: true,
			render: (created_at: string) => {
				return (
					<div>
						<TextM weight="normal">
							{dayjs(created_at).format('DD/MM/YYYY')}
						</TextM>
						<TextS weight="normal">
							às {dayjs(created_at).format('HH:mm:ss')}
						</TextS>
					</div>
				);
			},
			width: '15%',
		},
		{
			title: 'Criador',
			dataIndex: 'creator',
			sorter: true,
			key: 'creator.fullName',
			width: '15%',
			render: (creator: Creator) => (
				<TextM weight="normal">{creator.full_name}</TextM>
			),
		},
		{
			key: 'action',
			width: '10%',
			align: 'center',
			render: (company: Company) => {
				return (
					<div
						style={{
							fontSize: '1.3rem',
						}}
					>
						<HiOutlineCog6Tooth
							onClick={() => {
								navigate(
									company.person_type === PersonType.LEGAL
										? `/management/${company.id}/info`
										: `/management/natural-person/${company.id}/info`,
								);
							}}
							style={{
								color: theme.primary,
							}}
						/>
					</div>
				);
			},
		},
	];

	const onBackHandler = () => {
		setIsOpenCreateClientForm(false);

		queryClient.refetchQueries({
			queryKey: ['companyList'],
		});
	};

	return (
		<>
			<Row style={{ width: '100%', gap: '1rem', marginBottom: '1rem' }}>
				<FilterButton
					icon={<LuSearch size={18} />}
					onClick={() => setIsFiltersOpen(true)}
				>
					<TextS style={{ color: theme.white }}>Busca avançada</TextS>
				</FilterButton>
				<FilterButton
					icon={<AiOutlineStop size={18} color={theme.white} />}
					variation="secondary"
					onClick={handleClearFilters}
					disabled={Object.keys(filters).length === 0}
				>
					<TextS style={{ color: theme.white }}>Limpar filtros</TextS>
				</FilterButton>
				<Button
					type="primary"
					onClick={() => setIsOpenCreateClientForm(true)}
				>
					Criar Cliente
					<LuPlusCircle style={{ marginLeft: '0.6rem' }} size={18} />
				</Button>
			</Row>
			<Table
				columns={columns}
				dataSource={data?.content || []}
				loading={isLoading}
				onChange={(_, __, sorter) =>
					sortTableHandler(sorter, setSorting)
				}
				pagination={{
					total: data?.total_elements || 0,
					showTotal(total) {
						const currentSize =
							paginationInfo.currentPage *
							paginationInfo.pageSize;
						return `${currentSize > total ? total : currentSize} de ${total}`;
					},
					pageSizeOptions: ['10', '30', '60', '90'],
					showSizeChanger: true,
					pageSize: paginationInfo.pageSize,
					current: paginationInfo.currentPage,
					onChange(page, pageSize) {
						setPaginationInfo({
							currentPage: page,
							pageSize,
						});
					},
				}}
			/>
			<CompanyListFilters
				isOpen={isFiltersOpen}
				filters={filters}
				onClose={() => setIsFiltersOpen(false)}
				onApply={filters => {
					setFilters(filters);
					setIsFiltersOpen(false);
				}}
			/>

			{/* Modal de criação de cliente */}
			<CompanyCreationModal
				isOpen={!!isOpenCreateClientForm}
				onFinishHandler={onBackHandler}
				onCloseHandler={() => setIsOpenCreateClientForm(false)}
				isAccountCreation={false}
			/>
		</>
	);
};

export default ClientsList;
