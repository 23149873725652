export enum TEscrowSigners {
	IF = 'IF',
	PARTS = 'PARTS',
	REPRESENTATIVES = 'REPRESENTATIVES',
}

interface IEscrowSignerAccountRepresentative {
	full_name: string;
	taxpayer_id: string;
	sign_contract: boolean;
}

export interface IEscrowSignerAccount {
	sign_contract: boolean;
	type: TEscrowSigners;
	companyDetails: {
		name: string;
		taxpayer_id: string;
	};
	representatives?: IEscrowSignerAccountRepresentative[];
}
