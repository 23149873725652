import { Account } from 'types/Account';
import { useCallback, useEffect, useMemo, useState } from 'react';
import PaymentCreationDateForm from './PaymentCreationDateForm';
import PaymentCreationConfirmation from './PaymentCreationConfirmation';
import PaymentCreationSuccess from './PaymentCreationSuccess';
import { notification } from 'antd';
import { Modal } from 'components/Modal';
import PaymentCreationInputForm from './PaymentCreationInputForm';
import PaymentCreationReview from './PaymentCreationReview';
import { BarCode, PostingDto, PostingType } from 'types/Posting';
import { PostingsService } from 'modules/escrow/services';
import { useMutation } from '@tanstack/react-query';
import { ApiError } from 'types/ApiError';
import { ModalWrapper } from './styles';

enum PaymentStep {
	INPUT = 'INPUT',
	DATE = 'DATE',
	REVIEW = 'REVIEW',
	CONFIRMATION = 'CONFIRMATION',
	SUCCESS = 'SUCCESS',
}

interface IPaymentCreationProps {
	isOpen: boolean;
	account: Account;
	onClose: () => void;
}
const PaymentCreation = ({
	isOpen,
	account,
	onClose,
}: IPaymentCreationProps) => {
	const [step, setStep] = useState<PaymentStep>(PaymentStep.INPUT);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [paymentNumber, setPaymentNumber] = useState<string | null>(null);
	const [paymentAmount, setPaymentAmount] = useState<number | null>(null);
	const [paymentData, setPaymentData] = useState<BarCode | null>(null);
	const [paymentDate, setPaymentDate] = useState<string | null>(null);
	const [transactionId, setTransactionId] = useState<string | null>(null);
	const [api, contextHolder] = notification.useNotification();

	const { mutate, isPending } = useMutation<
		{ id: string },
		ApiError,
		PostingDto
	>({
		mutationFn: postingDto => {
			return PostingsService.createPosting(postingDto);
		},
		onSuccess: ({ id }) => {
			setTransactionId(id);
			setStep(PaymentStep.SUCCESS);
		},
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema ao solicitar o pagamento.',
			});
		},
	});

	const onNew = useCallback(() => {
		setStep(PaymentStep.INPUT);
		setPaymentAmount(null);
		setPaymentData(null);
		setPaymentNumber(null);
		setPaymentDate(null);
		setTransactionId(null);
	}, []);

	const getBarCodeDetailsHandler = useCallback(
		(value: string) => {
			setIsLoading(true);
			setPaymentNumber(value);
			PostingsService.getBarCodeDetails(value)
				.then(response => {
					setIsLoading(false);
					setPaymentData(response);
					setStep(PaymentStep.DATE);
				})
				.catch((e: ApiError) => {
					api.error({
						description: e.data.message,
						message:
							'Ocorreu um erro ao consultar os dados do boleto.',
					});
					setIsLoading(false);
					onNew();
				});
		},
		[step],
	);

	const createPostingHandler = useCallback(() => {
		mutate({
			account_id: account.id,
			amount: paymentAmount ?? 0,
			scheduled_date: paymentDate ?? '',
			type: PostingType.BARCODE,
			billpaymentTxnIdAuth: String(paymentData?.transaction_id),
			billpayment_payer:
				Object.keys(paymentData?.register_data ?? {})?.length > 0
					? `${paymentData?.register_data.payer} - ${paymentData?.register_data.document_payer}`
					: '-',
			billpayment_beneficiary:
				Object.keys(paymentData?.register_data ?? {})?.length > 0
					? `${paymentData?.register_data.recipient} - ${paymentData?.register_data.document_recipient}`
					: (paymentData?.assignor ?? '-'),
			bar_code: paymentNumber ?? '',
		});
	}, [paymentAmount, paymentDate, paymentData, paymentNumber, step]);

	useEffect(() => {
		if (isOpen) {
			onNew();
		}
	}, [isOpen]);

	const title = useMemo(() => {
		if (step === PaymentStep.SUCCESS) return 'Operação realizada';
		if (step === PaymentStep.REVIEW) return 'Confirmar operação?';
		if (step === PaymentStep.DATE) return 'Dados do boleto';
		return 'Solicitar Pagamento de Boleto';
	}, [step]);

	return (
		<Modal title={title} width={560} isOpen={isOpen} onClose={onClose}>
			{contextHolder}
			<ModalWrapper>
				{step === PaymentStep.INPUT && (
					<PaymentCreationInputForm
						isLoading={isLoading}
						onNext={number => {
							getBarCodeDetailsHandler(number);
						}}
					/>
				)}
				{step === PaymentStep.DATE && paymentData && paymentNumber && (
					<PaymentCreationDateForm
						paymentData={paymentData}
						paymentNumber={paymentNumber}
						onNext={(date, amount) => {
							setPaymentDate(date);
							setPaymentAmount(amount);
							setStep(PaymentStep.REVIEW);
						}}
					/>
				)}
				{step === PaymentStep.REVIEW &&
					paymentData &&
					paymentDate &&
					paymentNumber &&
					paymentAmount && (
						<PaymentCreationReview
							paymentData={paymentData}
							paymentDate={paymentDate}
							paymentNumber={paymentNumber}
							paymentAmount={paymentAmount}
							onClose={onClose}
							onNext={() => setStep(PaymentStep.CONFIRMATION)}
						/>
					)}
				{step === PaymentStep.CONFIRMATION &&
					paymentAmount &&
					paymentDate &&
					paymentData &&
					paymentNumber && (
						<PaymentCreationConfirmation
							isLoading={isPending}
							onClose={onClose}
							onConfirm={() => {
								createPostingHandler();
							}}
						/>
					)}
				{step === PaymentStep.SUCCESS &&
					paymentData &&
					paymentDate &&
					paymentNumber &&
					transactionId &&
					paymentAmount && (
						<PaymentCreationSuccess
							paymentData={paymentData}
							paymentDate={paymentDate}
							paymentNumber={paymentNumber}
							paymentAmount={paymentAmount}
							transactionId={transactionId}
							onNew={onNew}
						/>
					)}
			</ModalWrapper>
		</Modal>
	);
};

export default PaymentCreation;
