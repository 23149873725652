import { Form } from 'antd';
import { NaturalPersonForm } from 'modules/escrow/pages/EscrowEdition/CompanyInformation/types';
import styled from 'styled-components';

export const EditForm = styled(Form<NaturalPersonForm>)`
	display: flex;
	align-items: center;
	width: 100%;
	gap: 0.7rem;
	flex-wrap: wrap;
`;

export const EditFormItem = styled(Form.Item)`
	label {
		font-weight: bold;
		color: ${p => p.theme.text};
	}

	select {
		width: 100%;
	}
`;
