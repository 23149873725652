import React, { useMemo, useState } from 'react';
import { LuPlusCircle } from 'react-icons/lu';
import { Tabs } from 'components/Tabs';
import { PageHeader } from 'components/PageHeader';
import { Heading1 } from 'components/Heading1';
import { PageWrapper } from 'components/PageWrapper';
import {
	DebitCollectionsIcon,
	DebitCollectionsRoutes,
} from './constants/routes';
import { BreadcrumbContainer } from 'components/Breadcrumb';
import { PageActions } from 'components/PageActions';
import { Button } from 'antd';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import { AccessPermission, AccessType } from 'types/Access';
import { TabElement } from 'components/Tabs/Tabs';
import { Collections } from './pages/Collections';
import { WalletCreation } from './components/WalletCreation';
import CnabList from './pages/CnabList/CnabList';

const DebitCollectionsPage = () => {
	const [isWalletCreationOpen, setIsWalletCreationOpen] =
		useState<boolean>(false);
	const { type, permissions } = useCurrentProfile();

	const tabElements = useMemo<TabElement[]>(() => {
		const tabs = [
			{
				title: 'Carteira de cobranças',
				content: <Collections />,
				path: 'collections',
				breadcrumb: [
					{
						href: DebitCollectionsRoutes.BASE,
						title: (
							<BreadcrumbContainer>
								<DebitCollectionsIcon />
								<span>Carteiras de cobranças</span>
							</BreadcrumbContainer>
						),
					},
				],
			},
			{
				title: 'Arquivos de retorno',
				content: <CnabList />,
				path: 'cnab-list',
				breadcrumb: [
					{
						href: DebitCollectionsRoutes.BASE,
						title: (
							<BreadcrumbContainer>
								<DebitCollectionsIcon />
								<span>Arquivos de retorno</span>
							</BreadcrumbContainer>
						),
					},
				],
			},
		];
		return tabs;
	}, []);

	return (
		<>
			<PageHeader isFluid={true}>
				<Heading1>Cobranças</Heading1>
				{(type === AccessType.FINANCIAL_INSTITUTION ||
					permissions.includes(AccessPermission.CREATE_WALLET)) && (
					<PageActions>
						<Button
							type="primary"
							onClick={() => setIsWalletCreationOpen(true)}
						>
							Nova carteira de cobrança
							<LuPlusCircle
								style={{ marginLeft: '0.6rem' }}
								size={18}
							/>
						</Button>
					</PageActions>
				)}
			</PageHeader>
			<PageWrapper isFluid={true}>
				<Tabs elements={tabElements} />
			</PageWrapper>
			<WalletCreation
				isOpen={isWalletCreationOpen}
				onClose={() => setIsWalletCreationOpen(false)}
			/>
		</>
	);
};

export default DebitCollectionsPage;
