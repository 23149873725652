import styled from 'styled-components';

export const StyledButtonWrapper = styled.div`
	z-index: 100;
	position: absolute;
	right: 0;
	bottom: 0;
	padding: 1rem 2rem;
	background-color: ${({ theme }) => theme.white};
	left: 0;
	display: flex;
	justify-content: flex-end;
	border-top: 1px solid ${({ theme }) => theme.border};
`;
