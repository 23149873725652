import styled from 'styled-components';

export const PageWrapper = styled.div<{ isFluid?: boolean }>`
	padding: 0.6rem 32px 1rem;
	box-sizing: border-box;
	background-color: ${p => p.theme.white};
	overflow-y: auto;
	width: 100%;
`;

export const PagePanelWrapper = styled.div<{ isFluid?: boolean }>`
	border-radius: var(--radius-radius-lg, 16px);
	border: 1px solid ${p => p.theme.border};
	background: ${p => p.theme.white};
	box-shadow: 0px 6px 8px 0px ${p => p.theme.placeholder};
	margin: 2rem;
	display: flex;
	padding: var(--spacing-spacing-2xl, 24px);
	flex-direction: column;
	align-items: flex-start;
	gap: var(--spacing-spacing-2xl, 24px);
	align-self: stretch;
`;
