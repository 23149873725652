import { Col, Divider, Flex, Row } from 'antd';
import { PersonSecondaryIcon } from 'components/Icons/Person';
import { TextM, TextS } from 'components/Text';
import { normalizeCnpj, normalizeCpf } from 'helpers/normalizers';
import { isPersonOrCompany } from 'helpers/validators';
import { CardContainer } from 'modules/escrow/components/DestinationCardInfo/style';
import { useEscrowAccountsContext } from 'modules/escrow/context/EscrowAccounts.context';
import theme from 'theme/theme';
import { EClientType } from 'types/Company';
import { StyledIcon } from '../../styles';
import { BuildingIcon } from 'components/Icons/Building';
import { formatPhoneFromObject } from 'helpers/formaters';
import { Heading2 } from 'components/Heading2';

const BasicInfoComponent = () => {
	const { company } = useEscrowAccountsContext();

	const isPFContent = (
		<>
			<Flex align="center">
				<StyledIcon>
					<PersonSecondaryIcon color={theme.white} size={18} />
				</StyledIcon>
				<TextM weight="bold" color={theme.text}>
					{company?.name || '*****'}
				</TextM>
			</Flex>

			<Row gutter={[16, 16]} style={{ marginTop: '2rem' }}>
				<Col span={8}>
					<TextS color={theme.textGray}>CPF:</TextS>
					<TextS weight="bold" color={theme.textSecondary}>
						{normalizeCpf(company?.taxpayer_id || '') || '*****'}
					</TextS>
				</Col>
				<Col span={8}>
					<TextS color={theme.textGray}>Nome Completo:</TextS>
					<TextS weight="bold" color={theme.textSecondary}>
						{company?.name || '*****'}
					</TextS>
				</Col>
				<Col span={8}>
					<TextS color={theme.textGray}>E-mail:</TextS>
					<TextS weight="bold" color={theme.textSecondary}>
						{company?.email_address || '*****'}
					</TextS>
				</Col>
			</Row>
		</>
	);

	const isPJContent = (
		<>
			<Flex align="center">
				<StyledIcon>
					<BuildingIcon color={theme.white} size={18} />
				</StyledIcon>
				<TextM weight="bold" color={theme.text}>
					{company?.name || '*****'}
				</TextM>
			</Flex>

			<Row gutter={[16, 16]} style={{ marginTop: '2rem' }}>
				<Col span={8}>
					<TextS color={theme.textGray}>CNPJ:</TextS>
					<TextS weight="bold" color={theme.textSecondary}>
						{normalizeCnpj(company?.taxpayer_id || '') || '*****'}
					</TextS>
				</Col>
				<Col span={8}>
					<TextS color={theme.textGray}>Razão social:</TextS>
					<TextS weight="bold" color={theme.textSecondary}>
						{company?.corporate_name || '*****'}
					</TextS>
				</Col>
				<Col span={8}>
					<TextS color={theme.textGray}>Nome fantasia:</TextS>
					<TextS weight="bold" color={theme.textSecondary}>
						{company?.name || '*****'}
					</TextS>
				</Col>
			</Row>

			<Row gutter={[16, 16]} style={{ marginTop: '2rem' }}>
				<Col span={8}>
					<TextS color={theme.textGray}>Telefone:</TextS>
					<TextS weight="bold" color={theme.textSecondary}>
						{typeof company?.phone === 'string'
							? company?.phone
							: formatPhoneFromObject(company?.phone) || '*****'}
					</TextS>
				</Col>
				<Col span={8}>
					<TextS color={theme.textGray}>E-mail:</TextS>
					<TextS weight="bold" color={theme.textSecondary}>
						{company?.email_address || '*****'}
					</TextS>
				</Col>
			</Row>
		</>
	);

	return (
		<>
			<Divider />

			<Heading2>Cliente:</Heading2>
			<CardContainer>
				{company?.taxpayer_id &&
				isPersonOrCompany(company?.taxpayer_id) === EClientType.PF
					? isPFContent
					: isPJContent}
			</CardContainer>
		</>
	);
};

export default BasicInfoComponent;
