import { Col, Row, notification } from 'antd';

// Components
import { Heading1 } from 'components/Heading1';
import { Heading2 } from 'components/Heading2';
import ConfirmPartsComponent from './ConfirmParts/ConfirmParts';
import EscrowPFDetailsInformation from 'modules/escrow/pages/EscrowDetails/NaturalPerson/EscrowDetailsInformation';
import EscrowPJDetailsInformation from 'modules/escrow/pages/EscrowDetails/EscrowDetailsInformation';
import EscrowSigners from './Signers/Signers';
import { TextS } from 'components/Text';

// Hooks and services
import { useEscrowAccountsContext } from 'modules/escrow/context/EscrowAccounts.context';

// Types and helpers
import { Account, AccountRelatedPartDetailed } from 'types/Account';
import { Address } from 'types/Address';
import { CompanyDocuments, PersonType } from 'types/Company';
import { isPf } from 'modules/escrow/utils/stringFormatter';
import { Phone } from 'types/Phone';

// Styles
import theme from 'theme/theme';
import { Container, ContainerHeader } from './CreatingConfirmation.styles';

const CreatingConfirmationComponent = () => {
	const [api, contextHolder] = notification.useNotification();
	const { company, relatedParts } = useEscrowAccountsContext();

	const parsedParties: AccountRelatedPartDetailed[] = [];

	const parsedCompany: Account = {
		...({} as Account),
		person: {
			address: company?.address || ({} as Address),
			birth_date: company?.birth_date || '',
			cnae: company?.cnae || '',
			corporate_name: company?.corporate_name || '',
			documents: company?.documents || ({} as CompanyDocuments),
			email_address: company?.email_address || '',
			foundation_date: company?.foundation_date || '',
			name: company?.name || '',
			person_type: company?.person_type || PersonType.LEGAL,
			phone: company?.phone || ({} as Phone),
			representatives: company?.representatives || [],
			taxpayer_id: company?.taxpayer_id || '',
		},
		parties: parsedParties,
	};

	return (
		<Container>
			{contextHolder}
			<ContainerHeader>
				<Heading1
					style={{
						marginBottom: '2.5rem',
					}}
				>
					Verifique seus dados
				</Heading1>
			</ContainerHeader>

			{company?.taxpayer_id ? (
				isPf(company?.taxpayer_id) ? (
					<EscrowPFDetailsInformation
						account={parsedCompany}
						hideActions
						viewPage
					/>
				) : (
					<EscrowPJDetailsInformation
						account={parsedCompany}
						hideActions
						viewPage
					/>
				)
			) : (
				<TextS color={theme.danger}>Preencha os dados do titular</TextS>
			)}

			{!!relatedParts.length && <ConfirmPartsComponent />}

			<Row
				style={{
					marginTop: '2rem',
					border: `1px solid ${theme.border}`,
					padding: '16px 20px',
					borderRadius: '6px',
				}}
			>
				<Col span={24}>
					<Heading2 style={{ marginBottom: '1rem' }}>
						Assinantes
					</Heading2>
				</Col>
				<Col span={24}>
					<EscrowSigners hideActions />
				</Col>
			</Row>
		</Container>
	);
};

export default CreatingConfirmationComponent;
