import { SignerCollapseHeader } from './styles';
import { Account } from 'types/Account';
import { normalizeCpfCnpj } from 'helpers/normalizers';
import { useMemo } from 'react';
import { Col, Row } from 'antd';
import { Collapse, CollapseHeader } from 'components/Collapse';
import { TextM } from 'components/Text';
import { CompanyOutlinedIcon } from 'components/Icons/Company';
import { DescriptionsVertical } from 'components/DescriptionsVertical';
import { SignerIcon } from 'components/Icons/Signer';
import { PersonType } from 'types/Company';
import theme from 'theme/theme';

type SignerType = {
	companyName?: string;
	companyTaxpayerId?: string;
	signerName?: string;
	signerTaxpayerId?: string;
	personType?: PersonType;
};

interface IEscrowDetailsSignersProps {
	hideActions?: boolean;
	account: Account;
}
const EscrowDetailsSigners: React.FC<IEscrowDetailsSignersProps> = ({
	account,
	hideActions,
}) => {
	const signers = useMemo(() => {
		const parsedSigners: SignerType[] = [];

		const accountOwner = account.parties.find(
			part => part.person.taxpayer_id === account.person.taxpayer_id,
		);

		parsedSigners.push({
			companyName: accountOwner?.person?.name,
			companyTaxpayerId: normalizeCpfCnpj(
				accountOwner?.person?.taxpayer_id || '',
			),
			signerName: accountOwner?.person?.name,
			signerTaxpayerId: normalizeCpfCnpj(
				accountOwner?.person?.taxpayer_id || '',
			),
			personType: account.person.person_type,
		});

		account.parties
			.filter(part => part.sign_contract)
			.forEach(part => {
				const representatives = part.person.representatives.filter(
					representative => representative.sign_contract,
				);
				representatives.forEach(representative => {
					if (representative.sign_contract) {
						parsedSigners.push({
							companyName: part.person?.name || '-',
							companyTaxpayerId: part.person?.taxpayer_id
								? normalizeCpfCnpj(part.person.taxpayer_id)
								: '-',
							signerName: representative.full_name,
							signerTaxpayerId: representative.taxpayer_id
								? normalizeCpfCnpj(representative.taxpayer_id)
								: '-',
							personType: account.person.person_type,
						});
					}
				});
			});

		return parsedSigners;
	}, [account]);

	return (
		<Row gutter={[16, 16]}>
			<Col span={24}>
				<Collapse
					items={signers.map((signer, i) => {
						return {
							key: `${i + 1}`,
							label: (
								<SignerCollapseHeader>
									<CollapseHeader>
										<SignerIcon />
										<TextM>{signer.signerName}</TextM>
									</CollapseHeader>
									<CollapseHeader>
										{signer.personType ===
											PersonType.LEGAL && (
											<CompanyOutlinedIcon
												color={theme.primary}
											/>
										)}
										<TextM>{signer.companyName}</TextM>
									</CollapseHeader>
								</SignerCollapseHeader>
							),
							children: (
								<Row gutter={[16, 16]}>
									<Col span={12}>
										<DescriptionsVertical
											borderless={true}
											title="Dados da pessoa"
											hideActions={hideActions}
											items={[
												{
													key: '1',
													label: 'Nome da pessoa',
													children:
														signer.companyName,
												},
												{
													key: '2',
													label: 'CPF/CNPJ',
													children:
														signer.companyTaxpayerId,
												},
											]}
										/>
									</Col>
									<Col span={12}>
										<DescriptionsVertical
											borderless={true}
											title="Dados do assinante"
											hideActions={hideActions}
											items={[
												{
													key: '1',
													label: 'Nome do assinante',
													children: signer.signerName,
												},
												{
													key: '2',
													label: 'CPF',
													children:
														signer.signerTaxpayerId,
												},
											]}
										/>
									</Col>
								</Row>
							),
						};
					})}
				/>
			</Col>
		</Row>
	);
};

export default EscrowDetailsSigners;
