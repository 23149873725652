import { Button, Divider, Empty, Flex } from 'antd';
import { TextM, TextS } from 'components/Text';
import { LuPlusCircle } from 'react-icons/lu';
import emptyDocument from 'components/images/emptyDocument.png';
import theme from 'theme/theme';

interface ITaxReturnedInformationProps {
	onClickHandler: () => void;
}
const TaxReturnedInformation = ({
	onClickHandler,
}: ITaxReturnedInformationProps) => {
	return (
		<>
			<Divider />
			<Flex
				justify="center"
				align="center"
				vertical={true}
				style={{ marginTop: '1.5rem' }}
			>
				<TextM color={theme.textSecondary} weight="bold">
					CPF / CNPJ não encontrado.
				</TextM>
				<Empty
					style={{
						marginTop: '1.5rem',
						width: '100%',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
					}}
					description={
						<TextS
							style={{
								color: theme.textGray,
								textAlign: 'center',
							}}
						>
							Para prosseguir, adicione um cliente.
						</TextS>
					}
					image={emptyDocument}
					imageStyle={{
						height: 80,
					}}
				>
					<Button
						type="primary"
						onClick={onClickHandler}
						style={{ marginBottom: '2rem' }}
					>
						Adicionar Cliente
						<LuPlusCircle
							style={{ marginLeft: '0.6rem' }}
							size={18}
						/>
					</Button>
				</Empty>
			</Flex>
		</>
	);
};

export default TaxReturnedInformation;
