import { List, notification } from 'antd';
import { useCallback, useState } from 'react';
import { CustomSwitch } from 'components/CustomSwitch';
import { Heading1 } from 'components/Heading1';
import { TextM, TextS } from 'components/Text';
import { useEscrowAccountsContext } from 'modules/escrow/context/EscrowAccounts.context';
import { ContainerFluid, ContainerHeader } from '../styles';
import theme from 'theme/theme';
import { CELCOIN_CNPJ, CELCOIN_NAME } from 'constants/company';
import { IEscrowSignerAccount, TEscrowSigners } from '../types';
import { normalizeCpfCnpj } from 'helpers/normalizers';
import { Representative } from 'types/Representative';

const EscrowCreationSigners = () => {
	/* Hooks */
	const [api, contextHolder] = notification.useNotification();
	const { relatedParts, setRelatedParts, company } =
		useEscrowAccountsContext();

	/* Variables */
	const financialInstitution: IEscrowSignerAccount = {
		representatives: [
			{
				full_name: CELCOIN_NAME,
				taxpayer_id: CELCOIN_CNPJ,
				sign_contract: true,
			} as Representative,
		],
		sign_contract: true,
		type: TEscrowSigners.IF,
		companyDetails: {
			name: CELCOIN_NAME,
			taxpayer_id: CELCOIN_CNPJ,
		},
	};

	const representatives: IEscrowSignerAccount = {
		representatives: company?.representatives?.map(item => {
			return {
				full_name: item.full_name,
				taxpayer_id: item.taxpayer_id,
				sign_contract: true,
			} as Representative;
		}),
		type: TEscrowSigners.REPRESENTATIVES,
		sign_contract: true,
		companyDetails: {
			name: company?.name || '-',
			taxpayer_id: company?.taxpayer_id || '-',
		},
	};

	const parts: IEscrowSignerAccount[] = relatedParts.map(relatedPart => {
		return {
			representatives: [] as Representative[],
			sign_contract: !!relatedPart?.sign_contract,
			type: TEscrowSigners.PARTS,
			companyDetails: {
				name: relatedPart.person?.name || '-',
				taxpayer_id: relatedPart.person?.taxpayer_id || '-',
			},
		};
	});

	/* States */
	const [signers, setSigners] = useState<IEscrowSignerAccount[]>([
		financialInstitution,
		representatives,
		...parts,
	]);

	/* Handlers */
	const updateContext = useCallback(
		(signer: IEscrowSignerAccount) => {
			const updatedParts = relatedParts.map(relatedPart => {
				if (
					relatedPart.person?.taxpayer_id ===
					signer.companyDetails.taxpayer_id
				) {
					return {
						...relatedPart,
						sign_contract: !relatedPart?.sign_contract,
					};
				}

				return relatedPart;
			});

			setRelatedParts(updatedParts);
		},
		[relatedParts],
	);

	const updateSigners = useCallback(
		(signer: IEscrowSignerAccount) => {
			const updatedSigners = signers.map(relatedPart => {
				if (
					relatedPart.companyDetails.taxpayer_id ===
					signer.companyDetails.taxpayer_id
				) {
					return {
						...relatedPart,
						sign_contract: !relatedPart?.sign_contract,
					};
				}

				return relatedPart;
			});

			setSigners(updatedSigners);
			updateContext(signer);
		},
		[signers],
	);

	return (
		<>
			{contextHolder}
			<ContainerFluid>
				<ContainerHeader>
					<Heading1
						style={{
							marginBottom: '2.5rem',
						}}
					>
						Assinantes
					</Heading1>
				</ContainerHeader>
				<List
					size="large"
					bordered
					dataSource={signers}
					renderItem={(item, idx) => {
						return (
							<List.Item key={idx}>
								<div>
									<div>
										<TextM>
											{item.companyDetails.name}
										</TextM>
									</div>
									{item.representatives?.map(
										(representative, key) => (
											<div
												key={key}
												style={{
													display: 'flex',
													gap: '0.5rem',
													alignItems: 'center',
												}}
											>
												<TextS
													style={{
														color: theme.textGray,
													}}
												>
													{representative.full_name}
												</TextS>
												<TextS
													style={{
														color: theme.textGray,
													}}
												>
													{representative.taxpayer_id?.includes?.(
														'*',
													)
														? representative.taxpayer_id
														: normalizeCpfCnpj(
																representative.taxpayer_id ||
																	'-',
															)}
												</TextS>
											</div>
										),
									)}
								</div>
								<div>
									<CustomSwitch
										checked={item.sign_contract || false}
										handleChange={() => {
											if (
												[
													TEscrowSigners.IF,
													TEscrowSigners.REPRESENTATIVES,
												].includes(item.type)
											) {
												return api.info({
													message:
														'Esse assinante é obrigatório',
												});
											}

											updateSigners(item);
										}}
									/>
								</div>
							</List.Item>
						);
					}}
				/>
			</ContainerFluid>
		</>
	);
};

export default EscrowCreationSigners;
