import { Button, Flex } from 'antd';
import { ButtonProps } from 'antd/lib';
import { TextM } from 'components/Text';
import { ReactNode } from 'react';
import theme from 'theme/theme';
import { StyledSVG } from './ButtonDisableOption.styles';

interface IButtonDisableOptionProps extends ButtonProps {
	onClick: () => void;
	icon?: ReactNode;
	disable: boolean;
	text: string;
	variant: 'primary' | 'secondary';
}

const ButtonDisableOption = ({
	onClick,
	icon,
	disable,
	text,
	variant,
	...props
}: IButtonDisableOptionProps) => {
	return variant === 'primary' ? (
		<Button
			onClick={onClick}
			style={{ padding: '1.2rem' }}
			type="primary"
			disabled={disable}
			{...props}
		>
			<Flex align="center">
				<TextM
					style={{
						marginRight: '10px',
						color: disable ? theme.primary : theme.white,
						opacity: disable ? '0.5' : undefined,
					}}
				>
					{text}
				</TextM>
				{icon && <StyledSVG disabled={disable}>{icon}</StyledSVG>}
			</Flex>
		</Button>
	) : (
		<Button
			style={{ padding: '1.2rem' }}
			disabled={disable}
			onClick={onClick}
		>
			<TextM
				style={{
					marginRight: '10px',
					color: theme.primary,
					opacity: disable ? '0.5' : undefined,
				}}
			>
				{text}
			</TextM>
			{icon && <StyledSVG disabled={disable}>{icon}</StyledSVG>}
		</Button>
	);
};

export default ButtonDisableOption;
