import styled from 'styled-components';

export const Container = styled.div`
	max-width: 1260px;
`;

export const ContainerFluid = styled.div`
	max-width: 100%;
`;

export const RelatedPartsTableWrapper = styled.div`
	.celcoin-row {
		background-color: #eaeaea;

		& .ant-table-cell-row-hover {
			background-color: #eaeaea !important;
		}

		&:hover {
			background-color: #eaeaea;
		}
	}
`;

export const Footer = styled.div`
	display: flex;
	margin: 1rem 0;
	justify-content: flex-end;
`;

export const Steps = styled.div`
	color: ${({ theme }) => theme.primary};
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 27px;
`;

export const DescriptionTitle = styled.p`
	padding: 0;
	margin: 0;
	font-weight: bold;
`;

export const DescriptionItem = styled.p`
	padding: 0;
	margin: 0 0 1rem;
`;

export const RepresentativeTitle = styled.div`
	display: flex;
	align-items: center;
	span {
		margin-left: 0.6rem;
	}
`;

export const ContainerHeader = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1rem;
`;

export const ButtonActions = styled.div`
	display: flex;
	justify-content: center;
	gap: 0rem;
`;

export const StepsWrapper = styled.div`
	display: flex;
	margin-bottom: 1rem;
	border-top: 1px solid ${({ theme }) => theme.border};
	width: 100%;
`;

export const FinancialInstitutionContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #eaeaea;
	padding: 1rem;
	border-radius: 10px;
`;

export const StyledSteps = styled.div`
	height: 100vh;
	position: fixed;
	top: 12.8rem;
`;

export const PageEscrowWrapper = styled.div`
	margin-left: 14rem;
	padding: 1rem 2rem;
	overflow-y: auto;
	width: 100%;
`;

export const StyledButtonWrapper = styled.div`
	z-index: 100;
	position: absolute;
	right: 0;
	bottom: 0;
	padding: 2rem;
	background-color: white;
	left: 31rem;
	display: flex;
	justify-content: flex-end;
`;

export const StyledIcon = styled.div`
	background-color: ${({ theme }) => theme.primary};
	border-radius: 50%;
	width: 2rem;
	height: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 1rem;
`;
