import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import { AccessType } from 'types/Access';
import EscrowProvider from 'modules/escrow/context/EscrowAccounts.context';

const CompanyModule = () => {
	const { type } = useCurrentProfile();

	if (type !== AccessType.FINANCIAL_INSTITUTION) {
		return <Navigate to="/" replace />;
	}

	return (
		<EscrowProvider>
			<Routes>
				<Route index element={<Navigate to="/" replace />} />
			</Routes>
		</EscrowProvider>
	);
};

export default CompanyModule;
