import { Col, Row } from 'antd';
import { Heading2 } from 'components/Heading2';
import { Table } from 'components/Table';
import { TextS } from 'components/Text';
import { CompanyTypeDescription } from 'constants/company';
import { AccountPermissionDescription } from 'constants/account';
import { useEscrowAccountsContext } from 'modules/escrow/context/EscrowAccounts.context';
import theme from 'theme/theme';
import { RelatedPartType } from 'modules/escrow/components/RelatedPartsCreation/RelatedPartsCreation';

const ConfirmPartsComponent = () => {
	const { relatedParts } = useEscrowAccountsContext();

	const columns: ColumnsType<RelatedPartType> = [
		{
			title: 'Empresa',
			key: 'part.person.name',
			render: (part: RelatedPartType) => (
				<div>
					<TextS weight="normal" color={theme.textSecondary}>
						{part.person?.name}
					</TextS>
				</div>
			),
			width: '30%',
		},
		{
			title: 'Tipo da parte',
			key: 'part.relationship',
			render: (part: RelatedPartType) => (
				<div>
					<TextS weight="normal" color={theme.textSecondary}>
						{CompanyTypeDescription[part.relationship]}
					</TextS>
				</div>
			),
			width: '30%',
		},
		{
			title: 'Permissão',
			key: 'part.person.name',
			render: (part: RelatedPartType) => (
				<div>
					{part.permission.map(item => (
						<TextS weight="normal" color={theme.textSecondary}>
							{AccountPermissionDescription[item]}
						</TextS>
					))}
				</div>
			),
			width: '40%',
		},
	];

	return (
		<Row
			style={{
				marginTop: '2rem',
				border: `1px solid ${theme.border}`,
				padding: '16px 20px',
				borderRadius: '6px',
			}}
		>
			<Col span={24}>
				<Heading2 style={{ marginBottom: '1rem' }}>
					Partes relacionadas
				</Heading2>
			</Col>
			<Col span={24}>
				<Table
					columns={columns}
					rowKey={record => record.id}
					dataSource={relatedParts}
				/>
			</Col>
		</Row>
	);
};

export default ConfirmPartsComponent;
