import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
	Button,
	Col,
	Flex,
	Form,
	Input,
	InputRef,
	Row,
	Select,
	notification,
} from 'antd';
import { normalizeCpf, normalizePhone } from 'helpers/normalizers';
import { DatePicker } from 'components/DatePicker';
import { Container, Footer, Spinner, StyledInput } from './styles';
import { Company, CompanyDocuments, CompanyDto } from 'types/Company';
import { naturalPersonDocumentsList } from 'constants/company';
import { NaturalPersonForm } from './types';
import {
	serializeOnlyNumbers,
	serializePhoneObject,
} from 'helpers/serializers';
import FormItem from 'antd/es/form/FormItem';
import dayjs from 'dayjs';
import { formatPhoneFromObject } from 'helpers/formaters';
import { PiSpinnerBold } from 'react-icons/pi';
import {
	cpfRule,
	emailRule,
	fullNameRule,
	maxTodayDateRule,
	minDateRule,
	phoneRule,
} from 'helpers/rules';
import { useCompany } from 'modules/company/hooks';
import { validateCpf } from 'helpers/validators';
import { Heading2 } from 'components/Heading2';
import { Documents } from 'components/Documents';
import { TextS } from 'components/Text';
import { SaveIcon } from 'components/Icons/Save';
import theme from 'theme/theme';
import { Address } from 'types/Address';
import AddressContainer from 'components/Address/Address';

interface INaturalPersonInformationProps {
	person?: CompanyDto | Company;
	onNext: (person: CompanyDto | Company) => void;
	cnpj?: string;
}
const NaturalPersonInformation = ({
	person,
	onNext,
	cnpj,
}: INaturalPersonInformationProps) => {
	const [form] = Form.useForm();
	const [api, contextHolder] = notification.useNotification();

	const legalNameRef = useRef<InputRef>(null);
	const taxpayerIdRef = useRef<InputRef>(null);

	const [address, setAddress] = useState<Address>(
		person?.address || ({} as Address),
	);
	const [isDocumentsInvalid, setIsDocumentsInvalid] =
		useState<boolean>(false);
	const [uploadDocuments, setUploadDocuments] = useState<CompanyDocuments>(
		{} as CompanyDocuments,
	);

	const {
		getCompanyByTaxpayerId,
		company: loadedCompany,
		loading,
	} = useCompany({
		onError: e => {
			api.error({
				description: e.data?.message,
				message: 'Ocorreu um problema ao buscar informações da pessoa.',
			});
		},
		onSuccess: newPerson => {
			if (!cnpj) {
				api.error({
					message: 'Pessoa já cadastrada',
					description: `${newPerson?.name} já está cadastrada no sistema.`,
				});
				form.setFieldValue('taxpayer_id', '');
				setTimeout(() => taxpayerIdRef.current?.focus(), 100);
				return;
			}

			if (cnpj) {
				form.setFieldValue('taxpayer_id', cnpj);
			}

			form.setFieldValue('name', newPerson?.name);

			form.setFieldValue(
				'birth_date',
				dayjs(newPerson?.birth_date, 'YYYY-MM-DD'),
			);
			form.setFieldValue('email_address', newPerson?.email_address);
			form.setFieldValue(
				'phone',
				formatPhoneFromObject(newPerson?.phone),
			);
			form.setFieldValue('mother_name', newPerson?.mother_name);
			form.setFieldValue('pep', newPerson?.pep);

			setAddress(newPerson?.address || ({} as Address));
			setUploadDocuments(
				newPerson?.documents || ({} as CompanyDocuments),
			);
		},
	});

	useEffect(() => {
		if (person && person.documents) {
			setUploadDocuments(person.documents);
		}
	}, [person]);

	useEffect(() => {
		if (cnpj) {
			getCompanyByTaxpayerId(cnpj);
			setIsDocumentsInvalid(false);
		}
	}, [cnpj]);

	const isDocumentsValid = useMemo(() => {
		return (
			(typeof uploadDocuments['proof_of_address'] === 'string' &&
				typeof uploadDocuments['document_identification'] ===
					'string') ||
			(typeof uploadDocuments['proof_of_address_url'] === 'string' &&
				typeof uploadDocuments['identification_url'] === 'string')
		);
	}, [uploadDocuments]);

	const getFormValues = useCallback(
		(values: NaturalPersonForm) => {
			return {
				taxpayer_id: values.taxpayer_id
					? serializeOnlyNumbers(values.taxpayer_id)
					: undefined,
				name: values.name,
				birth_date: values.birth_date
					? values.birth_date.format('YYYY-MM-DD')
					: undefined,
				email_address: values.email_address,
				phone: values.phone
					? serializePhoneObject(values.phone)
					: undefined,
				mother_name: values.mother_name,
				pep: values.pep,
				address: address,
				documents: uploadDocuments,
			};
		},
		[uploadDocuments, address],
	);

	return (
		<Container>
			{contextHolder}

			<Form<NaturalPersonForm>
				form={form}
				layout="vertical"
				initialValues={{
					taxpayer_id:
						person?.taxpayer_id && normalizeCpf(person.taxpayer_id),
					name: person?.name,
					birth_date:
						person?.birth_date &&
						dayjs(person.birth_date, 'YYYY-MM-DD'),
					email_address: person?.email_address,
					mother_name: person?.mother_name,
					pep: person?.pep,
					phone: person?.phone && formatPhoneFromObject(person.phone),
				}}
				onFinish={values => {
					if (isDocumentsValid) {
						onNext(getFormValues(values) as CompanyDto);
					} else {
						if (!cnpj) {
							setIsDocumentsInvalid(true);
						}
					}
				}}
			>
				<Heading2
					style={{
						marginTop: '1rem',
						marginBottom: '21px',
						color: theme.primary,
					}}
				>
					Dados básicos
				</Heading2>
				<Row gutter={[16, 16]}>
					<Col span={5}>
						<FormItem
							shouldUpdate={(prevValues, currentValues) =>
								prevValues.taxpayer_id !==
								currentValues.taxpayer_id
							}
						>
							{({ getFieldValue }) => {
								return (
									<Form.Item
										name="taxpayer_id"
										label="CPF"
										normalize={e => normalizeCpf(e)}
										rules={[
											{
												required: true,
												message: 'Campo obrigatório',
											},
											cpfRule,
										]}
									>
										<Input
											disabled={loading}
											placeholder="000.000.000-00"
											ref={taxpayerIdRef}
											onBlur={() => {
												const taxpayerId =
													getFieldValue(
														'taxpayer_id',
													);
												if (
													validateCpf(taxpayerId) &&
													!loading
												) {
													getCompanyByTaxpayerId(
														serializeOnlyNumbers(
															taxpayerId,
														),
													);
												}
											}}
											suffix={
												loading ? (
													<Spinner>
														<PiSpinnerBold
															style={{
																fontSize: 24,
															}}
														/>
													</Spinner>
												) : null
											}
										/>
									</Form.Item>
								);
							}}
						</FormItem>
					</Col>
					<Col span={9}>
						<Form.Item
							name="name"
							label="Nome"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Input
								ref={legalNameRef}
								placeholder="Ex.: João Silva"
							/>
						</Form.Item>
					</Col>
					<Col span={10}>
						<Form.Item
							name="pep"
							label="Pessoa Politicamente Exposta (PEP)"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
							]}
						>
							<Select
								placeholder="Selecione"
								options={[
									{ label: 'Sim', value: true },
									{ label: 'Não', value: false },
								]}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={5}>
						<Form.Item
							name="birth_date"
							label="Data de Nascimento"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
								maxTodayDateRule('birth_date'),
								minDateRule,
							]}
						>
							<DatePicker
								placeholder="DD/MM/AAAA"
								format="DD/MM/YYYY"
							/>
						</Form.Item>
					</Col>
					<Col span={9}>
						<Form.Item
							name="email_address"
							label="E-mail"
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
								emailRule('email_address'),
							]}
						>
							<Input placeholder="Ex.: exemplo@exemplo.com" />
						</Form.Item>
					</Col>
					<Col span={10}>
						<Form.Item
							name="phone"
							label="Telefone"
							normalize={e => normalizePhone(e)}
							rules={[
								{
									required: true,
									message: 'Campo obrigatório',
								},
								phoneRule('phone'),
							]}
						>
							<Input placeholder="(00) 00000-0000" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col span={14}>
						<StyledInput>
							<Form.Item
								name="mother_name"
								label="Nome da mãe"
								rules={[
									{
										required: true,
										message: 'Campo obrigatório',
									},
									fullNameRule('mother_name'),
								]}
							>
								<Input placeholder="Ex.: Maria Silva" />
							</Form.Item>
						</StyledInput>
					</Col>
				</Row>
				<br />

				<AddressContainer setAddress={setAddress} address={address} />

				<Row
					gutter={[16, 16]}
					justify="space-between"
					style={{ alignItems: 'center', padding: '0 0.7rem' }}
				>
					<Heading2
						style={{ color: theme.primary, marginBottom: '21px' }}
					>
						Documentos
					</Heading2>
					<TextS>* Obrigatórios</TextS>
				</Row>
				<Documents
					hideTitle={true}
					onChangeDocumentsObject={documents => {
						setUploadDocuments({
							...uploadDocuments,
							...documents,
						});
					}}
					documentsObject={uploadDocuments}
					documentsList={naturalPersonDocumentsList}
				/>
				{isDocumentsInvalid && (
					<TextS color={theme.error} style={{ paddingTop: '1rem' }}>
						* Adicione todos os documentos obrigatórios
					</TextS>
				)}
				<Footer>
					<Button type="primary" htmlType="submit">
						<Flex>
							<TextS
								color={theme.white}
								style={{
									marginRight: '0.7rem',
								}}
							>
								Salvar alterações
							</TextS>
							<SaveIcon color={theme.white} size={21} />
						</Flex>
					</Button>
				</Footer>
			</Form>
		</Container>
	);
};

export default NaturalPersonInformation;
