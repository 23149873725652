import styled from 'styled-components';

export const Heading2 = styled.h2`
	color: ${p => p.theme.text};
	margin: 0;

	font-family: 'Inter', sans-serif;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 27px;
`;
