import { useState } from 'react';
import NaturalPersonCreationContainer from '../NaturalPerson/NaturalPersonCreation';
import AddressContainer from 'components/Address/Address';
import { Heading2 } from 'components/Heading2';
import { Documents } from 'components/Documents';
import { CompanyDocuments, EClientType } from 'types/Company';
import { useEscrowAccountsContext } from 'modules/escrow/context/EscrowAccounts.context';
import theme from 'theme/theme';
import { StepHeader } from '../styles';
import { Col, DatePicker, Flex, Form, Input, Row, Select } from 'antd';
import { TextS } from 'components/Text';
import {
	IdDocumentDescription,
	RepresentativeDocumentsList,
	RepresentativeRoleDescription,
} from 'constants/representatives';
import { BaseRepresentative, RepresentativeRole } from 'types/Representative';
import { invalidCaractereRule, maxTodayDateRule } from 'helpers/rules';
import { IdDocumentType } from 'types/IdDocument';
import dayjs from 'dayjs';

interface IRepresentativesContainerProps {
	clientType: EClientType;
	representative: BaseRepresentative;
	setRepresentative: (info: BaseRepresentative) => void;
}

const RepresentativesContainer = ({
	clientType,
	representative,
	setRepresentative,
}: IRepresentativesContainerProps) => {
	const { setHasRepresentative, hasRepresentative } =
		useEscrowAccountsContext();

	const [uploadDocuments, setUploadDocuments] = useState<CompanyDocuments>(
		{} as CompanyDocuments,
	);

	const isPF = clientType === EClientType.PF;

	const changeSelectorHandler = (value: string) => {
		setHasRepresentative(value);

		if (value === 'Não') {
			setRepresentative({} as BaseRepresentative);
		}
	};

	return (
		<>
			<StepHeader>
				<Heading2
					style={{
						color: theme.primary,
					}}
				>
					{isPF ? 'Procurador' : 'Representante'}
				</Heading2>
			</StepHeader>
			{isPF && (
				<Flex vertical>
					<TextS
						color={theme.textSecondary}
						style={{ paddingBottom: '10px' }}
					>
						Cliente possui procurador?
					</TextS>
					<Select
						placeholder="Selecione"
						defaultValue={hasRepresentative}
						onChange={changeSelectorHandler}
						style={{ marginBottom: '10px' }}
						options={[
							{
								label: 'Sim',
								value: 'Sim',
							},
							{
								label: 'Não',
								value: 'Não',
							},
						]}
					/>
				</Flex>
			)}

			{(!isPF || (isPF && hasRepresentative === 'Sim')) && (
				<>
					<NaturalPersonCreationContainer
						company={representative}
						isRepresentativeFormCreation
						setCompany={values =>
							setRepresentative({
								...values,
								...representative,
								full_name: values?.name || '',
							})
						}
					/>
					<Form
						style={{ width: '100%' }}
						initialValues={
							{
								...representative,
								birth_date:
									representative?.birth_date &&
									dayjs(
										representative?.birth_date,
										'YYYY-MM-DD',
									),
								id_document: {
									...representative.id_document,
									issue_date:
										representative?.id_document
											?.issue_date &&
										dayjs(
											representative?.id_document
												.issue_date,
											'YYYY-MM-DD',
										),
								},
							} as BaseRepresentative
						}
					>
						<Row gutter={[16, 16]}>
							<Col span={12}>
								<Flex vertical>
									<TextS
										color={theme.textSecondary}
										style={{ paddingBottom: '10px' }}
									>
										Assina contrato?
									</TextS>
									<Form.Item
										name="sign_contract"
										rules={[
											{
												required: true,
												message: 'Campo obrigatório',
											},
										]}
									>
										<Select
											placeholder="Selecione"
											onChange={e => {
												setRepresentative({
													...representative,
													sign_contract: e,
												});
											}}
											options={[
												{ label: 'Sim', value: true },
												{ label: 'Não', value: false },
											]}
										/>
									</Form.Item>
								</Flex>
							</Col>
							<Col span={12}>
								<Flex vertical>
									<TextS
										color={theme.textSecondary}
										style={{ paddingBottom: '10px' }}
									>
										Cargo
									</TextS>
									<Form.Item
										name="role"
										rules={[
											{
												required: true,
												message: 'Campo obrigatório',
											},
										]}
									>
										<Select
											placeholder="Selecione"
											onChange={e => {
												setRepresentative({
													...representative,
													role: e,
												});
											}}
											options={Object.keys(
												RepresentativeRoleDescription,
											).map(key => ({
												label: RepresentativeRoleDescription[
													key as unknown as RepresentativeRole
												],
												value: key,
											}))}
										/>
									</Form.Item>
								</Flex>
							</Col>
						</Row>
						<Row gutter={[16, 16]}>
							<Col span={12}>
								<Flex vertical>
									<TextS
										color={theme.textSecondary}
										style={{ paddingBottom: '10px' }}
									>
										Tipo de documento
									</TextS>
									<Form.Item
										name={['id_document', 'type']}
										rules={[
											{
												required: true,
												message: 'Campo obrigatório',
											},
										]}
									>
										<Select
											placeholder="Selecione"
											onChange={e => {
												setRepresentative({
													...representative,
													id_document: {
														...representative.id_document,
														type: e,
													},
												});
											}}
											options={Object.keys(
												IdDocumentDescription,
											).map(key => ({
												label: IdDocumentDescription[
													key as unknown as IdDocumentType
												],
												value: key,
											}))}
										/>
									</Form.Item>
								</Flex>
							</Col>
							<Col span={12}>
								<Flex vertical>
									<TextS
										color={theme.textSecondary}
										style={{ paddingBottom: '10px' }}
									>
										Número do documento
									</TextS>
									<Form.Item
										name={['id_document', 'number']}
										rules={[
											{
												required: true,
												message: 'Campo obrigatório',
											},
											invalidCaractereRule(),
										]}
									>
										<Input
											placeholder="Digite aqui"
											onChange={e => {
												setRepresentative({
													...representative,
													id_document: {
														...representative.id_document,
														number: e.target.value,
													},
												});
											}}
										/>
									</Form.Item>
								</Flex>
							</Col>
						</Row>
						<Row gutter={[16, 16]}>
							<Col span={12}>
								<Flex vertical>
									<TextS
										color={theme.textSecondary}
										style={{ paddingBottom: '10px' }}
									>
										Data de emissão
									</TextS>
									<Form.Item
										name={['id_document', 'issue_date']}
										rules={[
											{
												required: true,
												message: 'Campo obrigatório',
											},
											maxTodayDateRule('issue_date'),
										]}
									>
										<DatePicker
											placeholder="DD/MM/AAAA"
											format="DD/MM/YYYY"
											style={{ width: '100%' }}
											onChange={e => {
												setRepresentative({
													...representative,
													id_document: {
														...representative.id_document,
														issue_date:
															dayjs(e).format(
																'YYYY-MM-DD',
															),
													},
												});
											}}
										/>
									</Form.Item>
								</Flex>
							</Col>
							<Col span={12}>
								<Flex vertical>
									<TextS
										color={theme.textSecondary}
										style={{ paddingBottom: '10px' }}
									>
										Emissor
									</TextS>
									<Form.Item
										name={['id_document', 'issuer']}
										rules={[
											{
												required: true,
												message: 'Campo obrigatório',
											},
											invalidCaractereRule(),
										]}
									>
										<Input
											placeholder="Digite aqui"
											onChange={e => {
												setRepresentative({
													...representative,
													id_document: {
														...representative.id_document,
														issuer: e.target.value,
													},
												});
											}}
										/>
									</Form.Item>
								</Flex>
							</Col>
						</Row>
					</Form>
					<br />
					<AddressContainer
						address={representative?.address}
						setAddress={address =>
							setRepresentative({
								...representative,
								address: address,
							})
						}
					/>
					<Documents
						hideTitle
						isCreation
						documentsObject={uploadDocuments}
						documentsList={RepresentativeDocumentsList}
						onChangeDocumentsObject={documents => {
							setUploadDocuments({
								...uploadDocuments,
								...documents,
							});
							setRepresentative({
								...representative,
								documents: documents,
							});
						}}
					/>
					<br />
				</>
			)}
		</>
	);
};

export default RepresentativesContainer;
