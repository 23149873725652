import { Col, DescriptionsProps, Row, notification } from 'antd';
import dayjs from 'dayjs';
import { normalizeCep, normalizeCpf } from 'helpers/normalizers';
import { Company, CompanyDto, CompanyFund } from 'types/Company';
import { naturalPersonDocumentsList } from 'constants/company';
import { StatesListDescription } from 'constants/states';
import { formatPhoneFromObject } from 'helpers/formaters';
import NaturalPersonBasicDataEdit from './NaturalPersonEdit/NaturalPersonBasicDataEdit';
import { useMemo, useState } from 'react';
import NaturalPersonAddressEdit from './NaturalPersonEdit/NaturalPersonAddressEdit';
import { Documents } from 'components/Documents';
import { useMutation } from '@tanstack/react-query';
import { ApiError } from 'types/ApiError';
import { PersonsService } from 'modules/company/services';
import { queryClient } from 'network/query';
import { DescriptionsVertical } from 'components/DescriptionsVertical';

interface ICompanyDetailsInformationProps {
	person: Company;
	companyFund?: CompanyFund;
}
const NaturalPersonDetailsInformation: React.FC<
	ICompanyDetailsInformationProps
> = ({ person }) => {
	const [api, contextHolder] = notification.useNotification();

	const [isBasicDataEditOpen, setIsBasicDataEditOpen] = useState(false);
	const [isAddressEditOpen, setIsAddressEditOpen] = useState(false);

	const { mutate } = useMutation<void, ApiError, Partial<CompanyDto>>({
		mutationFn: companyDto => {
			return PersonsService.patchCompany(person.id, companyDto);
		},
		onSuccess: () => {
			queryClient.refetchQueries({
				queryKey: ['companyDetails', person.id],
			});
			api.success({
				message: 'Pessoa atualizada com sucesso.',
			});
			if (isBasicDataEditOpen) setIsBasicDataEditOpen(false);
			if (isAddressEditOpen) setIsAddressEditOpen(false);
		},
		onError: e => {
			api.error({
				description: e.data.message,
				message: 'Ocorreu um problema ao atualizar a pessoa.',
			});
		},
	});

	const basicData = useMemo(() => {
		const items: DescriptionsProps['items'] = [
			{
				key: '1',
				label: 'Nome',
				children: person.name,
			},
			{
				key: '2',
				label: 'CPF',
				children: person?.taxpayer_id
					? normalizeCpf(person.taxpayer_id)
					: '',
			},
			{
				key: '3',
				label: 'Data de Nascimento',
				children: dayjs(person.birth_date, 'YYYY-MM-DD').format(
					'DD/MM/YYYY',
				),
			},
			{
				key: '4',
				label: 'E-mail',
				children: person.email_address,
			},
			{
				key: '5',
				label: 'Telefone',
				children:
					typeof person.phone === 'string'
						? person.phone
						: formatPhoneFromObject(person.phone),
			},
			{
				key: '6',
				label: 'Nome da mãe',
				children: person.mother_name,
			},
		];

		return items;
	}, [person]);

	return (
		<>
			{contextHolder}
			<Row gutter={[16, 16]}>
				<Col span={12}>
					<DescriptionsVertical
						title="Dados básicos"
						items={basicData}
						onEdit={() => setIsBasicDataEditOpen(true)}
					/>
				</Col>
				<Col span={12}>
					<DescriptionsVertical
						title="Endereço"
						items={[
							{
								key: '1',
								label: 'CEP',
								children: person?.address?.postal_code
									? normalizeCep(person?.address?.postal_code)
									: '',
							},
							{
								key: '2',
								label: 'Logradouro',
								children: person?.address?.street_name || '',
							},
							{
								key: '3',
								label: 'Número',
								children: person?.address?.street_number || '',
							},
							{
								key: '4',
								label: 'Bairro',
								children: person?.address?.district || '',
							},
							{
								key: '5',
								label: 'Cidade',
								children: person?.address?.city || '',
							},
							{
								key: '6',
								label: 'Estado',
								children: person?.address?.state_code
									? StatesListDescription[
											person?.address?.state_code
										]
									: '',
							},
							{
								key: '7',
								label: 'Complemento',
								children: person?.address?.extra_info || '',
							},
						]}
						onEdit={() => setIsAddressEditOpen(true)}
					/>
				</Col>
				<NaturalPersonBasicDataEdit
					isOpen={isBasicDataEditOpen}
					person={person}
					onClose={() => setIsBasicDataEditOpen(false)}
					onSave={company => mutate(company)}
				/>
				<NaturalPersonAddressEdit
					isOpen={isAddressEditOpen}
					person={person}
					onClose={() => setIsAddressEditOpen(false)}
					onSave={company => mutate(company)}
				/>
			</Row>
			<Row gutter={[16, 16]} style={{ marginTop: '2rem' }}>
				<Col span={24}>
					<Documents
						onChangeDocumentsObject={documents => {
							mutate({
								documents: Object.keys(documents).reduce(
									(acc, key) => {
										if (!key.includes('url')) {
											return {
												...acc,
												[key]: documents[key],
											};
										}
										return acc;
									},
									{},
								),
							});
						}}
						documentsObject={person?.documents || {}}
						documentsList={naturalPersonDocumentsList}
					/>
				</Col>
			</Row>
		</>
	);
};

export default NaturalPersonDetailsInformation;
