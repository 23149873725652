import { Navigate, Route, Routes } from 'react-router-dom';
import ManagementPage from './Management';
import AccountConfigPage from './pages/AccountConfig/AccountConfig';
import AccountDetailsPage from './pages/AccountDetails/AccountDetails';
import BalanceManagementPage from './pages/BalanceManagement/BalanceManagement';
import DepositManagementPage from './pages/DepositManagement/DepositManagement';
import { TransferRulePage } from './pages/TransferRulePage';
import { ManagementTemplate } from 'templates/Management';
import EscrowProvider from 'modules/escrow/context/EscrowAccounts.context';
import {
	CompanyDetails,
	NaturalPersonDetails,
} from './pages/ClientsList/CompanyDetails';

const ManagementModule = () => {
	return (
		<EscrowProvider>
			<Routes>
				<Route element={<ManagementTemplate />}>
					<Route path=":tab" element={<ManagementPage />} />
					<Route
						path=":id/details"
						element={<AccountDetailsPage />}
					/>
					<Route
						path=":id/details/account-config"
						element={<AccountConfigPage />}
					/>
					<Route
						path=":id/details/transfer-rule"
						element={<TransferRulePage />}
					/>
					<Route
						path=":id/details/balance-management"
						element={<BalanceManagementPage />}
					/>
					<Route
						path=":id/details/deposit-management"
						element={<DepositManagementPage />}
					/>
					<Route path=":id/:tab" element={<CompanyDetails />} />
					<Route
						path="natural-person/:id/:tab"
						element={<NaturalPersonDetails />}
					/>
					<Route index element={<Navigate to="accounts" replace />} />
				</Route>
			</Routes>
		</EscrowProvider>
	);
};

export default ManagementModule;
