import {
	AccountStatementMovement,
	AccountStatementMovementType,
} from 'types/Account';

export const descriptionMatchers = (movement: AccountStatementMovement) => [
	//TODO: add matchers for fee when its available
	{
		matcher:
			movement.movement_type === AccountStatementMovementType.ENTRYCREDIT,
		description: 'Crédito em conta',
	},
	{
		matcher:
			movement.movement_type === AccountStatementMovementType.ENTRYDEBIT,
		description: 'Débito em conta',
	},
	{
		matcher:
			movement.movement_type ===
			AccountStatementMovementType.TEDTRANSFEROUT,
		description: 'Transferência TED',
	},
	{
		matcher:
			movement.movement_type ===
			AccountStatementMovementType.TEDTRANSFERIN,
		description: 'Recebimento TED',
	},
	{
		matcher:
			movement.movement_type ===
			AccountStatementMovementType.PIXREVERSALIN,
		description: `${movement.description || '-'}`,
	},
	{
		matcher:
			movement.movement_type ===
			AccountStatementMovementType.PIXREVERSALOUT,
		description: 'Estorno PIX',
	},
	{
		matcher:
			movement.movement_type ===
			AccountStatementMovementType.PIXPAYMENTOUT,
		description: 'Transferência Pix',
	},
	{
		matcher:
			movement.movement_type ===
			AccountStatementMovementType.TEFTRANSFEROUT,
		description: 'Cobrança de Tarifa',
	},
	{
		matcher:
			movement.movement_type ===
			AccountStatementMovementType.TEFTRANSFERIN,
		description: 'Recebimento TEF',
	},
	{
		matcher:
			movement.movement_type ===
			AccountStatementMovementType.PIXPAYMENTIN,
		description: 'Recebimento Pix',
	},
	{
		matcher:
			movement.movement_type === AccountStatementMovementType.BILLPAYMENT,
		description: 'Pagamento de boleto',
	},
];

export const getStatementDescriptionMatcher = (
	movement: AccountStatementMovement,
) => [
	{
		matcher:
			movement.balance_type === 'DEBIT' &&
			movement.additional_information?.name_credit,
		description: `Enviado para ${
			movement?.additional_information?.name_credit || ''
		}`,
	},
	{
		matcher:
			movement.balance_type === 'CREDIT' &&
			movement.additional_information?.name_debit,
		description: `Recebido de ${movement?.additional_information?.name_debit} || '`,
	},
	{
		matcher: true,
		description: '-',
	},
];

export const getStatementMovementType = (
	movement: AccountStatementMovement,
): string => {
	const match = descriptionMatchers(movement).find(
		matcher => matcher.matcher,
	);
	return match ? match.description : movement.movement_type || '-';
};
