import { Col, Row } from 'antd/lib';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { TextS } from 'components/Text';
import Confirmation from 'components/Icons/Confirmation';

interface IExportedStatementModalProps {
	emailInfo?: string;
	setIsExported: (value: boolean) => void;
}

export default function ExportedStatementModal({
	emailInfo,
	setIsExported,
}: IExportedStatementModalProps) {
	return (
		<ConfirmationModal
			confirmText="Ok, obrigado"
			isOpen
			onClose={() => setIsExported(false)}
			onConfirm={() => setIsExported(false)}
			title="Extrato exportado!"
		>
			<Row
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Col span={3}>
					<Confirmation />
				</Col>
				<Col span={21}>
					<TextS>
						O seu extrato está sendo processado e será enviado para
						seu e-mail!
					</TextS>
					<p>{`Em breve, verifique a caixa de entrada do seu e-mail ${
						emailInfo ?? ''
					}`}</p>
				</Col>
			</Row>
		</ConfirmationModal>
	);
}
