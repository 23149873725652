import { Account } from 'types/Account';
import { DestinationsContainer, StepsWrapper } from './styles';
import { useMemo, useState } from 'react';
import { Button, Col, Flex, Row, Select } from 'antd';
import { TextM } from 'components/Text';

import { useTheme } from 'styled-components';
import { PostingIcon } from 'components/Icons/Posting';
import { DestinationCardInfo } from '../DestinationCardInfo';
import { ForwardButton } from './components/ForwardButton';
import { Destination } from 'types/Destination';
import { useCurrentProfile } from 'modules/core/context/ProfileContext';
import { AccessPermission } from 'types/Access';

interface IPostingCreationListFormProps {
	account: Account;
	onNext: (account_destination_id: string) => void;
	onCreateBeneficiary: () => void;
}
const PostingCreationListForm = ({
	account,
	onNext,
	onCreateBeneficiary,
}: IPostingCreationListFormProps) => {
	const theme = useTheme();
	const { permissions } = useCurrentProfile();
	const [selectedDestination, setSelectedDestination] =
		useState<Destination>();

	const destinations = useMemo(() => {
		return account.destinations;
	}, [account]);

	const handleNextStep = () => {
		if (selectedDestination) {
			onNext(selectedDestination.id);
		}
	};

	return (
		<StepsWrapper>
			<div>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<TextM
							style={{
								margin: '0.8rem 0',
							}}
						>
							Selecione o beneficiário
						</TextM>
						<Select
							style={{ width: '100%' }}
							showSearch
							placeholder="Selecionar"
							options={destinations.map(destination => ({
								label: `${destination.name} - Conta: ${destination.account}`,
								value: destination.id,
							}))}
							filterOption={(input, option) =>
								option?.label
									?.toLowerCase()
									?.startsWith(input.toLowerCase()) ||
								String(option?.value)
									?.toLowerCase()
									?.startsWith(input.toLowerCase())
							}
							onChange={id => {
								const destination = destinations.find(
									dest => dest.id === id,
								);
								if (destination) {
									setSelectedDestination(destination);
								}
							}}
						/>
					</Col>
				</Row>

				{permissions?.includes(AccessPermission.CREATE_BENEFICIARY) && (
					<>
						<Row
							align="middle"
							style={{
								marginTop: '.8rem',
							}}
						>
							<Button
								style={{
									backgroundColor: theme.primary,
									width: '100%',
									minHeight: 45,
								}}
								onClick={onCreateBeneficiary}
							>
								<Flex
									justify="space-between"
									style={{ width: '100%' }}
								>
									<TextM
										style={{
											textAlign: 'center',
											color: theme.white,
											width: '90%',
										}}
									>
										Transferir para outro beneficiário
									</TextM>
									<Flex justify="center" align="center">
										<PostingIcon color={theme.white} />
									</Flex>
								</Flex>
							</Button>
						</Row>
						<Row style={{ marginTop: '1rem' }}>
							<TextM
								style={{
									margin: '0.8rem 0',
								}}
							>
								Recentes:
							</TextM>
							<Col span={24}>
								<DestinationsContainer>
									{account.destinations
										.map(dest => {
											return (
												<DestinationCardInfo
													data={dest}
													onClick={data =>
														onNext(data.id)
													}
												/>
											);
										})
										.filter((_, index) => index <= 4)}
								</DestinationsContainer>
							</Col>
						</Row>
					</>
				)}
			</div>

			<Row gutter={[16, 16]} style={{ marginTop: '2rem' }}>
				<Col span={24}>
					<ForwardButton
						onClick={handleNextStep}
						disabled={!selectedDestination}
						text="Avançar"
					/>
				</Col>
			</Row>
		</StepsWrapper>
	);
};

export default PostingCreationListForm;
