import { useCallback, useState } from 'react';
import { PersonsService } from '../services';
import { Company } from 'types/Company';
import { ApiError } from 'types/ApiError';
import { AccountsService } from 'modules/escrow/services';
import { AccountContent } from 'types/Account';
import { Representative } from 'types/Representative';

export const useCompany = (options?: {
	onError?: (error: ApiError) => void;
	onSuccess?: (
		company: Company | null,
		hasPrevCompany: boolean,
		accounts: AccountContent[],
	) => void;
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [lastCompany, setLastCompany] = useState<Company | null>(null);

	const getCompanyByTaxpayerId = useCallback(
		async (taxpayerId: string): Promise<Company | null> => {
			try {
				setIsLoading(true);
				const company = await PersonsService.getPersons({
					taxpayer_id: taxpayerId,
				});
				const companyResponse =
					company.content.length > 0 ? company.content[0] : null;
				const fullCompany =
					companyResponse &&
					(await PersonsService.getCompany(companyResponse.id, {
						by_creator: true,
					}));
				const accounts =
					companyResponse &&
					(await AccountsService.getAccounts({
						person_id: companyResponse.id,
					}));

				if (typeof options?.onSuccess === 'function') {
					options.onSuccess(
						fullCompany,
						lastCompany !== null,
						accounts?.content ?? [],
					);
				}

				setLastCompany(fullCompany);

				return fullCompany;
			} catch (error) {
				if (typeof options?.onError === 'function') {
					options.onError(error as ApiError);
				}
				return null;
			} finally {
				setIsLoading(false);
			}
		},
		[lastCompany, options],
	);

	const getCompanyById = useCallback(
		async (companyId: string): Promise<Company | null> => {
			try {
				setIsLoading(true);
				const company = await PersonsService.getCompany(companyId);
				const fullCompany = company ?? null;

				if (typeof options?.onSuccess === 'function') {
					options.onSuccess(fullCompany, lastCompany !== null, []);
				}

				setLastCompany(fullCompany);

				return fullCompany;
			} catch (error) {
				if (typeof options?.onError === 'function') {
					options.onError(error as ApiError);
				}
				return null;
			} finally {
				setIsLoading(false);
			}
		},
		[options, lastCompany],
	);

	const updateRepresentative = useCallback(
		async (companyId: string, representative: Representative) => {
			try {
				setIsLoading(true);
				await PersonsService.updateRepresentative(
					companyId,
					representative,
				);
			} catch (error) {
				if (typeof options?.onError === 'function') {
					options.onError(error as ApiError);
				}
			} finally {
				setIsLoading(false);
			}
		},
		[options],
	);

	const createRepresentative = useCallback(
		async (
			companyId: string,
			representative: Representative,
		): Promise<{ id: string } | null> => {
			try {
				setIsLoading(true);
				const response = await PersonsService.createRepresentative(
					companyId,
					representative,
				);
				return response;
			} catch (error) {
				if (typeof options?.onError === 'function') {
					options.onError(error as ApiError);
				}
				return null;
			} finally {
				setIsLoading(false);
			}
		},
		[options],
	);

	return {
		getCompanyById,
		getCompanyByTaxpayerId,
		updateRepresentative,
		createRepresentative,
		loading: isLoading,
		company: lastCompany,
	};
};
