import styled from 'styled-components';

export const Footer = styled.div`
	display: flex;
	margin: 1rem 0;
	justify-content: flex-end;
`;

export const Spinner = styled.span`
	@-moz-keyframes spin {
		100% {
			-moz-transform: rotate(360deg);
		}
	}
	@-webkit-keyframes spin {
		100% {
			-webkit-transform: rotate(360deg);
		}
	}
	@keyframes spin {
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	animation: spin 1s linear infinite;
	padding: 0;
	margin: 0;
	display: flex;
	align-items: center;
	color: ${p => p.theme.primary};
`;

export const StyledSelect = styled.div`
	div {
		span {
			@media (max-width: 1500px) {
				max-width: 42rem;
			}

			@media (min-width: 1501px) and (max-width: 1750px) {
				max-width: 55rem;
			}
		}
	}
`;

export const StyledInformationContainer = styled.div`
	margin-bottom: 4rem;
`;
