import { queryClient } from 'network/query';
import { useCallback, useMemo, useState } from 'react';
import { GoLaw } from 'react-icons/go';
import dayjs from 'dayjs';
import { IoPeopleOutline } from 'react-icons/io5';
import {
	Button,
	Col,
	CollapseProps,
	Descriptions,
	DescriptionsProps,
	Empty,
	Flex,
	Row,
} from 'antd';

// Components
import { AddCircleIcon } from 'components/Icons/AddCircle';
import { Collapse, CollapseHeader } from 'components/Collapse';
import { Documents } from 'components/Documents';
import { Heading1 } from 'components/Heading1';
import { PageActions } from 'components/PageActions';
import { TextM, TextS } from 'components/Text';
import EmptyRepresentativesList from 'components/images/emptyListA.png';
import { NaturalPersonRepresentativesCreation } from 'components/NaturalPersonRepresentativesCreation';

// Hooks and services
import { useCompany } from 'modules/company/hooks';
import { useEscrowAccountsContext } from 'modules/escrow/context/EscrowAccounts.context';

// Types and helpers
import { formatPhoneFromObject } from 'helpers/formaters';
import { normalizeCpf } from 'helpers/normalizers';
import { Company } from 'types/Company';
import {
	BaseRepresentative,
	Representative,
	RepresentativeDocuments,
} from 'types/Representative';
import {
	RepresentativeDocumentsList,
	RepresentativeRoleDescription,
} from 'constants/representatives';

// Styles
import theme from 'theme/theme';
import { ContainerFluid, ContainerHeader, DescriptionItem } from './styles';

export interface CustomDescriptionItemProps {
	children: React.ReactNode;
}

export const CustomDescriptionItem = ({
	children,
}: CustomDescriptionItemProps) => {
	return (
		<DescriptionItem style={{ margin: '0', padding: '0.5rem 0' }}>
			{children}
		</DescriptionItem>
	);
};

const createRepresentativeDescriptionItems = (
	fullRepresentative: Representative,
): DescriptionsProps['items'] => {
	return [
		{
			key: '1',
			label: 'Nome do assinante',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{fullRepresentative?.full_name || '****'}
				</CustomDescriptionItem>
			),
		},
		{
			key: '3',
			label: 'CPF',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{normalizeCpf(fullRepresentative.taxpayer_id)}
				</CustomDescriptionItem>
			),
		},
		{
			key: '4',
			label: 'Telefone',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{typeof fullRepresentative.phone === 'string'
						? fullRepresentative.phone
						: formatPhoneFromObject(fullRepresentative.phone)}
				</CustomDescriptionItem>
			),
		},
		{
			key: '5',
			label: 'Data de nascimento',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{dayjs(fullRepresentative?.birth_date, 'YYYY-MM-DD').format(
						'DD/MM/YYYY',
					) || '****'}
				</CustomDescriptionItem>
			),
		},
		{
			key: '6',
			label: 'Nome da mãe',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{fullRepresentative?.mother_name || '****'}
				</CustomDescriptionItem>
			),
		},
		{
			key: '7',
			label: 'Função',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{RepresentativeRoleDescription[fullRepresentative?.role] ||
						'****'}
				</CustomDescriptionItem>
			),
		},
		{
			key: '8',
			label: 'CEP',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{fullRepresentative.address.postal_code}
				</CustomDescriptionItem>
			),
		},
		{
			key: '9',
			label: 'Endereço',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			span: 2,
			children: (
				<CustomDescriptionItem>
					{fullRepresentative.address.street_name}
				</CustomDescriptionItem>
			),
		},
		{
			key: '10',
			label: 'Número',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{fullRepresentative.address.street_number}
				</CustomDescriptionItem>
			),
		},
		{
			key: '11',
			label: 'Bairro',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{fullRepresentative.address.district}
				</CustomDescriptionItem>
			),
		},
		{
			key: '12',
			label: 'Cidade',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{fullRepresentative.address.city}
				</CustomDescriptionItem>
			),
		},
		{
			key: '13',
			label: 'Estado',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{fullRepresentative.address.state_code}
				</CustomDescriptionItem>
			),
		},
		{
			key: '14',
			label: 'Complemento',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{fullRepresentative.address.extra_info ?? '-'}
				</CustomDescriptionItem>
			),
		},
		{
			key: '15',
			label: 'Assina contrato',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{fullRepresentative.sign_contract ? 'Sim' : 'Não'}
				</CustomDescriptionItem>
			),
		},
		{
			key: '16',
			label: 'Pessoa publicamente exposta',
			style: {
				borderBottom: '1px solid #f0f0f0',
			},
			children: (
				<CustomDescriptionItem>
					{fullRepresentative.pep ? 'Sim' : 'Não'}
				</CustomDescriptionItem>
			),
		},
	];
};

const createLegalRepresentativeDescriptionItems = (
	representative: BaseRepresentative,
): DescriptionsProps['items'] => {
	return [
		{
			key: '1',
			label: 'Nome do procurador',
			children: (
				<DescriptionItem>{representative.full_name}</DescriptionItem>
			),
		},
		{
			key: '3',
			label: 'CPF',
			children: (
				<DescriptionItem>
					{normalizeCpf(representative.taxpayer_id)}
				</DescriptionItem>
			),
		},
		{
			key: '4',
			label: 'Telefone',
			children: (
				<DescriptionItem>
					{typeof representative.phone === 'string'
						? representative.phone
						: formatPhoneFromObject(representative.phone)}
				</DescriptionItem>
			),
		},
		{
			key: '5',
			label: 'Data de nascimento',
			children: (
				<DescriptionItem>
					{dayjs(representative.birth_date, 'YYYY-MM-DD').format(
						'DD/MM/YYYY',
					)}
				</DescriptionItem>
			),
		},
		{
			key: '6',
			label: 'Nome da mãe',
			children: (
				<DescriptionItem>{representative.mother_name}</DescriptionItem>
			),
		},
		{
			key: '7',
			label: 'Função',
			children: (
				<DescriptionItem>
					{RepresentativeRoleDescription[representative.role]}
				</DescriptionItem>
			),
		},
		{
			key: '8',
			label: 'CEP',
			children: (
				<DescriptionItem>
					{representative.address.postal_code}
				</DescriptionItem>
			),
		},
		{
			key: '9',
			label: 'Endereço',
			span: 2,
			children: (
				<DescriptionItem>
					{representative.address.street_name}
				</DescriptionItem>
			),
		},
		{
			key: '10',
			label: 'Número',
			children: (
				<DescriptionItem>
					{representative.address.street_number}
				</DescriptionItem>
			),
		},
		{
			key: '11',
			label: 'Bairro',
			children: (
				<DescriptionItem>
					{representative.address.district}
				</DescriptionItem>
			),
		},
		{
			key: '12',
			label: 'Cidade',
			children: (
				<DescriptionItem>{representative.address.city}</DescriptionItem>
			),
		},
		{
			key: '13',
			label: 'Estado',
			children: (
				<DescriptionItem>
					{representative.address.state_code}
				</DescriptionItem>
			),
		},
		{
			key: '14',
			label: 'Complemento',
			children: (
				<DescriptionItem>
					{representative.address.extra_info ?? '-'}
				</DescriptionItem>
			),
		},
	];
};

interface ICompanyRepresentativesProps {
	isAccountCreation?: boolean;
	isClientEdition?: boolean;
	company?: Company;
}
const CompanyRepresentatives = ({
	isAccountCreation,
	isClientEdition,
	company,
}: ICompanyRepresentativesProps) => {
	/* Hooks */
	const { updateRepresentative, createRepresentative } = useCompany();
	const { setCompany } = useEscrowAccountsContext();

	/* States */
	const [representativeToEdit, setRepresentativeToEdit] =
		useState<Representative>();
	const [legalRepresentativeToEdit, setLegalRepresentativeToEdit] =
		useState<BaseRepresentative>();
	const [isRepresentativesCreationOpen, setIsRepresentativesCreationOpen] =
		useState(false);
	const [
		isLegalRepresentativeCreationOpen,
		setIsLegalRepresentativeCreationOpen,
	] = useState(false);
	const [isMissingRepresentatives, setIsMissingRepresentatives] =
		useState(false);
	const [isMissingDocuments, setIsMissingDocuments] = useState(false);
	const [isMissingSigners, setIsMissingSigners] = useState(false);

	const isNewCompany = useMemo(() => !company?.id, [company]);

	const onModifyRepresentative = useCallback(
		async (representative: Representative) => {
			const representatives: Representative[] =
				company?.representatives || [];

			if (representatives.length === 0) {
				setIsMissingRepresentatives(true);
				return;
			}

			const missingDocuments = representatives.some(r => {
				if (
					!(
						(typeof r.documents['identification'] === 'string' &&
							typeof r.documents['proof_of_address'] ===
								'string') ||
						(typeof r.documents['identification_url'] ===
							'string' &&
							typeof r.documents['proof_of_address_url'] ===
								'string')
					)
				)
					return true;
				if (
					r.representative &&
					!(
						(typeof r.representative.documents['identification'] ===
							'string' &&
							typeof r.representative.documents[
								'proof_of_address'
							] === 'string') ||
						(typeof r.representative.documents[
							'identification_url'
						] === 'string' &&
							typeof r.representative.documents[
								'proof_of_address_url'
							] === 'string')
					)
				)
					return true;
				return false;
			});
			if (missingDocuments) {
				setIsMissingDocuments(true);
				return;
			}

			const missingSigners = representatives.filter(r => {
				if (r.sign_contract) return true;
				if (r.representative && r.representative.sign_contract)
					return true;
				return false;
			});

			if (missingSigners.length === 0) {
				setIsMissingSigners(true);
				return;
			}

			if (!representativeToEdit) {
				if (company?.id) {
					const response = await createRepresentative(
						company?.id,
						representative,
					);
					if (response) {
						setCompany({
							...company,
							representatives: [
								...representatives,
								{ ...representative, id: response.id },
							],
						});
					}
				} else {
					setCompany({
						...company,
						representatives: [...representatives, representative],
					});
				}
			} else {
				if (company?.id) {
					await updateRepresentative(company?.id, representative);
				}
				setCompany({
					...company,
					representatives: representatives.map(r => {
						if (
							r.taxpayer_id === representativeToEdit.taxpayer_id
						) {
							return {
								...r,
								...representative,
							};
						}
						return r;
					}),
				});
				setRepresentativeToEdit(undefined);
			}

			queryClient.refetchQueries({
				queryKey: ['companyDetails', company?.id],
			});

			setIsRepresentativesCreationOpen(false);
			setIsMissingRepresentatives(false);
			setIsMissingDocuments(false);
		},
		[
			representativeToEdit,
			company,
			createRepresentative,
			updateRepresentative,
		],
	);

	const onModifyLegalRepresentative = useCallback(
		async (legal_representative: BaseRepresentative) => {
			if (company?.id) {
				await updateRepresentative(company?.id, {
					...representativeToEdit!,
					representative: legal_representative,
				});
			}

			const parsedRepresentatives = company?.representatives?.map(r => {
				if (r.taxpayer_id === representativeToEdit!.taxpayer_id) {
					return {
						...r,
						representative: legal_representative,
					};
				}
				return r;
			});

			setCompany({
				...company,
				representatives: parsedRepresentatives,
			});

			queryClient.refetchQueries({
				queryKey: ['companyDetails', company?.id],
			});

			setRepresentativeToEdit(undefined);
			setLegalRepresentativeToEdit(undefined);
			setIsLegalRepresentativeCreationOpen(false);
		},
		[company, representativeToEdit, updateRepresentative],
	);

	const onAddLegalRepresentative = useCallback(
		(representative: BaseRepresentative) => {
			setRepresentativeToEdit(representative);
			setIsLegalRepresentativeCreationOpen(true);
		},
		[],
	);

	const onEditLegalRepresentative = useCallback(
		(
			representative: BaseRepresentative,
			legal_representative: BaseRepresentative,
		) => {
			setRepresentativeToEdit(representative);
			setLegalRepresentativeToEdit(legal_representative);
			setIsLegalRepresentativeCreationOpen(true);
		},
		[],
	);

	const onEditRepresentative = useCallback(
		(representative: BaseRepresentative) => {
			setRepresentativeToEdit(representative);
			setIsRepresentativesCreationOpen(true);
		},
		[],
	);

	const onRemoveRepresentative = useCallback(
		(representative: Representative) => {
			setCompany({
				...company,
				representatives: company?.representatives?.filter(
					r => r.taxpayer_id !== representative.taxpayer_id,
				),
			});
		},
		[company],
	);

	const onRemoveLegalRepresentative = useCallback(
		(representative: Representative) => {
			setCompany({
				...company,
				representatives: company?.representatives?.map(r => {
					if (r.taxpayer_id === representative.taxpayer_id) {
						return {
							...r,
							representative: undefined,
						};
					}
					return r;
				}),
			});
		},
		[company],
	);

	const onUpdateDocument = useCallback(
		(
			representative: Representative,
			documents: RepresentativeDocuments,
			legal_representative?: Representative,
		) => {
			setCompany({
				...company,
				representatives: company?.representatives?.map(r => {
					if (r.taxpayer_id === representative.taxpayer_id) {
						if (typeof legal_representative === 'undefined') {
							return {
								...r,
								documents,
							};
						} else {
							return {
								...r,
								representative: {
									...r.representative!,
									documents,
								},
							};
						}
					}
					return r;
				}),
			});
		},
		[company],
	);

	const representativesItems: CollapseProps['items'] = useMemo(
		() =>
			company?.representatives?.map((representative, i) => {
				const legalRepresentativesItems:
					| CollapseProps['items']
					| undefined =
					representative.representative &&
					[representative.representative].map(
						(legal_representative, j) => {
							return {
								key: `${j + 1}`,
								label: (
									<CollapseHeader>
										<GoLaw
											color={theme.primary}
											size="24"
										/>{' '}
										<TextM>
											{legal_representative.full_name}
										</TextM>
									</CollapseHeader>
								),
								children: (
									<>
										<Row gutter={[16, 16]}>
											<Col>
												<Descriptions
													layout="vertical"
													column={4}
													items={createLegalRepresentativeDescriptionItems(
														legal_representative,
													)}
												/>
											</Col>
										</Row>
										{!!legal_representative?.documents && (
											<>
												<Row
													gutter={[16, 16]}
													style={{
														marginBottom: '1rem',
														marginTop: '2rem',
													}}
												>
													<Col>
														<TextM>
															Documentos
														</TextM>
													</Col>
												</Row>
												<Row
													gutter={[16, 16]}
													style={{
														paddingBottom: '1rem',
													}}
												>
													<Col span={24}>
														<Documents
															hideTitle={true}
															isDisabled={
																!isNewCompany
															}
															onChangeDocumentsObject={documents => {
																onUpdateDocument(
																	representative,
																	documents,
																	legal_representative,
																);
															}}
															documentsObject={
																legal_representative.documents
															}
															documentsList={
																RepresentativeDocumentsList
															}
														/>
													</Col>
												</Row>
											</>
										)}

										{(!isAccountCreation ||
											isNewCompany) && (
											<Row
												gutter={[16, 16]}
												justify="end"
											>
												<Col>
													<Button
														type="link"
														onClick={() =>
															onEditLegalRepresentative(
																representative,
																legal_representative,
															)
														}
													>
														Editar
													</Button>
												</Col>
												<Col>
													<Button
														type="link"
														danger={true}
														onClick={() =>
															onRemoveLegalRepresentative(
																representative,
															)
														}
													>
														Remover
													</Button>
												</Col>
											</Row>
										)}
									</>
								),
							};
						},
					);

				return {
					key: `${i + 1}`,
					label: (
						<CollapseHeader>
							<IoPeopleOutline color={theme.primary} size="24" />{' '}
							<TextM>{representative.full_name}</TextM>
						</CollapseHeader>
					),
					children: (
						<>
							<Row gutter={[16, 16]}>
								<Col>
									<Descriptions
										labelStyle={{
											minWidth: '12rem',
											fontSize: '1rem',
											padding: '0.5rem 0',
										}}
										contentStyle={{
											fontSize: '1rem',
											color: theme.text,
											margin: '0',
										}}
										layout="horizontal"
										column={1}
										items={createRepresentativeDescriptionItems(
											representative,
										)}
									/>
								</Col>
							</Row>
							{!!representative?.documents && (
								<>
									<Row
										gutter={[16, 16]}
										style={{
											marginBottom: '1rem',
											marginTop: '2rem',
										}}
									>
										<Col>
											<TextM>Documentos</TextM>
										</Col>
									</Row>
									<Row
										gutter={[16, 16]}
										style={{ paddingBottom: '1rem' }}
									>
										<Col span={24}>
											<Documents
												hideTitle={true}
												isDisabled={!isNewCompany}
												onChangeDocumentsObject={documents => {
													onUpdateDocument(
														representative,
														documents,
													);
												}}
												documentsObject={
													representative.documents
												}
												documentsList={
													RepresentativeDocumentsList
												}
											/>
										</Col>
									</Row>
								</>
							)}

							{legalRepresentativesItems &&
								legalRepresentativesItems.length > 0 && (
									<>
										<Row
											gutter={[16, 16]}
											style={{ marginBottom: '1rem' }}
										>
											<Col>
												<TextM>Procuradores</TextM>
											</Col>
										</Row>
										<Row
											gutter={[16, 16]}
											style={{ marginBottom: '1rem' }}
										>
											<Col span={24}>
												<Collapse
													expandIconPosition="end"
													items={
														legalRepresentativesItems
													}
												/>
											</Col>
										</Row>
									</>
								)}
							{(!isAccountCreation || isNewCompany) && (
								<Row gutter={[16, 16]} justify="end">
									<Col>
										<Button
											type="link"
											onClick={() =>
												onEditRepresentative(
													representative,
												)
											}
										>
											Editar
										</Button>
									</Col>

									<Col>
										<Button
											type="link"
											onClick={() =>
												onAddLegalRepresentative(
													representative,
												)
											}
										>
											Adicionar Procurador
										</Button>
									</Col>
									{!isClientEdition && (
										<Col>
											<Button
												type="link"
												danger={true}
												onClick={() =>
													onRemoveRepresentative(
														representative,
													)
												}
											>
												Remover
											</Button>
										</Col>
									)}
								</Row>
							)}
						</>
					),
				};
			}),
		[
			isAccountCreation,
			isNewCompany,
			onAddLegalRepresentative,
			onEditLegalRepresentative,
			onEditRepresentative,
			onRemoveLegalRepresentative,
			onRemoveRepresentative,
			onUpdateDocument,
		],
	);

	return (
		<ContainerFluid>
			<ContainerHeader>
				{(isAccountCreation ||
					(isClientEdition &&
						!!company?.representatives?.length)) && (
					<>
						<Heading1
							style={{
								marginBottom: '2.5rem',
							}}
						>
							{/* Esta tela é usada em edição de cliente, criação de conta e para edição de cliente.
							 * Em edição, não deverá apresentar o title, apenas o button */}
							{!isClientEdition && 'Representantes'}
						</Heading1>
						<PageActions>
							<Button
								type="primary"
								onClick={() =>
									setIsRepresentativesCreationOpen(true)
								}
							>
								<Flex
									style={{
										alignItems: 'center',
									}}
								>
									<TextS color={theme.white}>
										Adicionar representante
									</TextS>
									<AddCircleIcon
										color={theme.white}
										size="18"
									/>
								</Flex>
							</Button>
						</PageActions>
					</>
				)}
			</ContainerHeader>
			{!company?.representatives?.length && (
				<>
					<TextM
						style={{
							textAlign: 'center',
							paddingTop: '1rem',
							color: theme.textSecondary,
						}}
					>
						{isAccountCreation
							? 'Você ainda não possui nenhum representante adicionado!'
							: 'Não há representante adicionados'}
					</TextM>
					<Empty
						style={{
							minHeight: '50vh',
							width: '100%',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
						}}
						description={
							<TextS
								style={{
									paddingTop: '1rem',
									color: theme.textGray,
									textAlign: 'center',
								}}
							>
								{isAccountCreation &&
									'Para prosseguir, adicione pelo menos um representante'}
							</TextS>
						}
						image={EmptyRepresentativesList}
						imageStyle={{
							height: 200,
						}}
					/>
				</>
			)}

			{company?.representatives?.length !== 0 && (
				<Collapse
					expandIconPosition="end"
					items={representativesItems}
				/>
			)}

			{!isAccountCreation && !company?.representatives?.length && (
				<Flex align="center" justify="center">
					<Button
						type="primary"
						onClick={() => setIsRepresentativesCreationOpen(true)}
					>
						<Flex
							style={{
								alignItems: 'center',
							}}
						>
							<TextS
								color={theme.white}
								style={{ paddingRight: '1rem' }}
							>
								Adicionar representante
							</TextS>
							<AddCircleIcon color={theme.white} size="18" />
						</Flex>
					</Button>
				</Flex>
			)}

			{isMissingDocuments && (
				<TextS color={theme.error} style={{ paddingTop: '1rem' }}>
					* Verifique todos os documentos obrigatórios para os
					representantes e procuradores.
				</TextS>
			)}
			{isMissingRepresentatives && (
				<TextS color={theme.error} style={{ paddingTop: '1rem' }}>
					* É necessário adicionar um ou mais representantes.
				</TextS>
			)}
			{isMissingSigners && (
				<TextS color={theme.error} style={{ paddingTop: '1rem' }}>
					* É necessário adicionar um ou mais representantes
					assinantes.
				</TextS>
			)}

			{/* Modals  */}
			<NaturalPersonRepresentativesCreation
				isOpen={isRepresentativesCreationOpen}
				representative={representativeToEdit}
				onCreate={onModifyRepresentative}
				onClose={() => {
					setIsRepresentativesCreationOpen(false);
					setRepresentativeToEdit(undefined);
				}}
			/>
			<NaturalPersonRepresentativesCreation
				isOpen={isLegalRepresentativeCreationOpen}
				representative={legalRepresentativeToEdit}
				isLegalRepresentative={true}
				onCreate={onModifyLegalRepresentative}
				onClose={() => {
					setIsLegalRepresentativeCreationOpen(false);
					setRepresentativeToEdit(undefined);
				}}
			/>
		</ContainerFluid>
	);
};

export default CompanyRepresentatives;
